import { Button, Grid, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getOpenBusinessFormDialog,
  handleOpenBusinessFormDialog,
  showAlertSnackbar
} from '../../reducers/sliceReducer'
import Dialog from '../../components/Dialog'

const useStyles = makeStyles(theme => ({
  paramStyle: {
    marginTop: theme.spacing(2),
    textAlign: 'center'
  },
  textFieldStyle: {
    width: 400
  },
  buttonGroupStyle: {
    textAlign: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2)
  },
  buttonStyle: {
    width: 200
  }
}))

function CreateBusinessDialog(props) {
  const dispatch = useDispatch()
  const classes = useStyles()

  const openModalObject = useSelector(getOpenBusinessFormDialog)
  const rowValues = useSelector(state => state.prealertReducer.rowValues)

  const editParams = [
    {
      label: 'reference',
      type: 'text',
      disabled: false
    },
    {
      label: 'trace_no',
      type: 'text',
      disabled: false
    },
    {
      label: 'bag_no',
      type: 'text',
      disabled: false
    },
    {
      label: 'internal_account_number',
      type: 'text',
      disabled: false
    },
    {
      label: 'sender',
      type: 'text',
      disabled: false
    },
    {
      label: 'start_phone',
      type: 'text',
      disabled: false
    },
    {
      label: 'start_postal_code',
      type: 'text',
      disabled: false
    },
    {
      label: 'pickup_address',
      type: 'text',
      disabled: false
    },
    {
      label: 'pickup_warehouse',
      type: 'text',
      disabled: false
    },
    {
      label: 'shipper_country_code',
      type: 'text',
      disabled: false
    },
    {
      label: 'receiver',
      type: 'text',
      disabled: false
    },
    {
      label: 'postal_code',
      type: 'text',
      disabled: false
    },
    {
      label: 'delivery_address',
      type: 'text',
      disabled: false
    },
    {
      label: 'delivery_unit_no',
      type: 'text',
      disabled: false
    },
    {
      label: 'buzz_code',
      type: 'text',
      disabled: false
    },
    {
      label: 'receiver_phone',
      type: 'text',
      disabled: false
    },
    {
      label: 'receiver_email',
      type: 'text',
      disabled: false
    },
    {
      label: 'length',
      type: 'text',
      disabled: false
    },
    {
      label: 'width',
      type: 'text',
      disabled: false
    },
    {
      label: 'height',
      type: 'text',
      disabled: false
    },
    {
      label: 'dimension_uom',
      type: 'text',
      disabled: false
    },
    {
      label: 'weight',
      type: 'text',
      disabled: false
    },
    {
      label: 'weight_uom',
      type: 'text',
      disabled: false
    },
    {
      label: 'total_value',
      type: 'text',
      disabled: false
    },
    {
      label: 'currency',
      type: 'text',
      disabled: false
    },
    {
      label: 'item_description',
      type: 'text',
      disabled: false
    },
    {
      label: 'driver_notes',
      type: 'text',
      disabled: false
    },
    {
      label: 'danger_type',
      type: 'text',
      disabled: false
    },
    {
      label: 'require_signature',
      type: 'text',
      disabled: false
    }
  ]

  const [paramsForm, setParamsForm] = useState({})

  const onClose = () => {
    dispatch(handleOpenBusinessFormDialog({ open: false }))
  }

  const handleParamChange = (key, newValue) => {
    let _newValue = newValue
    setParamsForm(prevParamsForm => ({
      ...prevParamsForm,
      [key]: _newValue
    }))
  }

  useEffect(() => {
    setParamsForm({})
    if (rowValues.data) {
      try {
        let data = JSON.parse(rowValues.data)
        setParamsForm(prev => ({
          ...prev,
          ...data,
          partner_id: rowValues.partner_id
        }))
      } catch (e) {
        if (openModalObject.open) {
          dispatch(
            showAlertSnackbar({
              message: 'Error: Missing order info',
              type: 'error'
            })
          )
        }
      }
    }
  }, [rowValues, openModalObject, dispatch])

  return (
    <Dialog
      open={openModalObject.open}
      onClose={onClose}
      maxWidth='md'
      fullWidth
      disableDefaultButtons
      title={'Edit Order'}>
      <Grid container spacing={1}>
        {editParams.map((param, index) => (
          <Grid item xs={6} className={classes.paramStyle} key={index}>
            <TextField
              label={param.label}
              disabled={param.disabled}
              fullWidth
              variant='outlined'
              className={classes.textFieldStyle}
              onChange={e => handleParamChange(param.label, e.target.value)}
              value={paramsForm[`${param.label}`]}
            />
          </Grid>
        ))}
      </Grid>
      <div className={classes.buttonGroupStyle}>
        <Button
          color='primary'
          variant='contained'
          className={classes.buttonStyle}
          onClick={() => props.onSubmit(paramsForm)}>
          {'submit'}
        </Button>
      </div>
    </Dialog>
  )
}

export default CreateBusinessDialog
