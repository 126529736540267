import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, TextField, Stack } from '@mui/material'
import {
  getMAWBStatistic,
  getBagStatistic
} from '../../actions/prealertStatisticActions'
import { showAlertSnackbar } from '../../reducers/sliceReducer'
import dayjs from 'dayjs'

function PrealertStatisticSearchDate(props) {
  const dispatch = useDispatch()
  const [dateFrom, setDateFrom] = useState(dayjs().format('YYYY-MM-DDThh:mm'))
  const [dateTo, setDateTo] = useState(dayjs().format('YYYY-MM-DDThh:mm'))

  async function handleSearch() {
    props.setLoading(true)
    try {
      let response = []
      if (props.type === 'MAWB') {
        response = await dispatch(
          getMAWBStatistic(dayjs(dateFrom).unix(), dayjs(dateTo).unix())
        ).catch(e => {
          throw e.message
        })
      } else if (props.type === 'BAG') {
        response = await dispatch(
          getBagStatistic(dayjs(dateFrom).unix(), dayjs(dateTo).unix())
        ).catch(e => {
          throw e.message
        })
      }
      props.setData(response)
      dispatch(
        showAlertSnackbar({
          message: `Fetch Data Success (${response.length} records)`,
          type: 'success'
        })
      )
    } catch (e) {
      dispatch(showAlertSnackbar({ message: e, type: 'error' }))
    } finally {
      props.setLoading(false)
    }
  }

  return (
    <Stack direction='row' spacing={2}>
      <TextField
        id='start-time'
        label='Start Time'
        type='datetime-local'
        value={dateFrom}
        onChange={e => setDateFrom(e.target.value)}
        size='small'
      />
      <TextField
        id='end-time'
        label='End Time'
        type='datetime-local'
        value={dateTo}
        onChange={e => setDateTo(e.target.value)}
        size='small'
      />
      <Button
        onClick={handleSearch}
        disabled={props.loading}
        variant='contained'
        color='primary'>
        {props.loading ? 'Fetching' : 'Fetch'}
      </Button>
    </Stack>
  )
}

export default PrealertStatisticSearchDate
