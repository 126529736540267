import { IconButton, TextField, Typography, Card, Stack } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

export default function PostalOutletOrderSearch(props) {
  const {
    trackingNo,
    setTrackingNo,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    handleSearch
  } = props

  return (
    <div>
      <Typography variant='subtitle1' marginBottom={2}>
        Orders
      </Typography>
      <Card variant='outlined' sx={{ marginBottom: 2 }}>
        <Stack
          direction='row'
          alignItems='center'
          spacing={2}
          useFlexGap
          flexWrap='wrap'
          sx={{ padding: 3 }}>
          <TextField
            label='Tracking Number'
            value={trackingNo}
            onChange={e => setTrackingNo(e.target.value)}
            slotProps={{ inputLabel: { shrink: true } }}
            variant='outlined'
            size='small'
          />
          <TextField
            label='Order Created From'
            type='date'
            value={fromDate}
            onChange={e => setFromDate(e.target.value)}
            slotProps={{ inputLabel: { shrink: true } }}
            variant='outlined'
            size='small'
          />
          <TextField
            label='Order Created To'
            type='date'
            value={toDate}
            onChange={e => setToDate(e.target.value)}
            slotProps={{ inputLabel: { shrink: true } }}
            variant='outlined'
            size='small'
          />
          <IconButton color='secondary' onClick={handleSearch}>
            <SearchIcon />
          </IconButton>
        </Stack>
      </Card>
    </div>
  )
}
