import {
  GET_BRANCH_LIST,
  GET_BRANCH_LIST_FAIL,
  GET_BRANCH_LIST_SUCCESS
} from '../actions/types'

const initialState = {
  loading: false,
  error: false,
  branches: [],
  branchError: false
}

function commonReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BRANCH_LIST:
      return {
        ...state,
        loading: true
      }
    case GET_BRANCH_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        branches: action.payload
      }
    case GET_BRANCH_LIST_FAIL:
      return {
        ...state,
        loading: false,
        branchError: action.payload
      }
    default:
      return state
  }
}

export default commonReducer
