import { Card, Stack, IconButton, TextField, MenuItem } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import SearchIcon from '@mui/icons-material/Search'

export default function BillingManagementSettlementSearch(props) {
  const { status, customers, operators, search, params, setParams } = props

  return (
    <Card variant='outlined' sx={{ marginBottom: 2 }}>
      <Stack
        direction='row'
        alignItems='center'
        spacing={2}
        useFlexGap
        flexWrap='wrap'
        sx={{ padding: 3 }}>
        <Autocomplete
          value={params.partner || null}
          onChange={(event, value) => setParams('partner', value)}
          disableClearable={Boolean(params.customer)}
          isOptionEqualToValue={(option, value) =>
            option.id === value.id || value.id === 0
          }
          options={customers || []}
          getOptionLabel={option =>
            option?.id === 0
              ? ''
              : option.partner_id + ':' + option?.partner_name
          }
          renderInput={params => (
            <TextField
              {...params}
              label='Partner'
              variant='outlined'
              slotProps={{ inputLabel: { shrink: true } }}
            />
          )}
          limitTags={1}
          sx={{ width: '180px' }}
          variant='outlined'
          size='small'
        />
        <Autocomplete
          value={params.customer || null}
          onChange={(event, value) => setParams('customer', value)}
          disabled={!params.partner || !params.partner.customer}
          isOptionEqualToValue={(option, value) =>
            option.id === value.id || value.id === 0
          }
          options={params.partner?.customer ? [...params.partner.customer] : []}
          getOptionLabel={option =>
            option?.id === 0
              ? ''
              : option.customer_id + ':' + option?.customer_name
          }
          renderInput={params => (
            <TextField
              {...params}
              label='Customer'
              variant='outlined'
              slotProps={{ inputLabel: { shrink: true } }}
            />
          )}
          limitTags={1}
          sx={{ width: '180px' }}
          variant='outlined'
          size='small'
        />
        <TextField
          label='Billing Status'
          slotProps={{ inputLabel: { shrink: true } }}
          value={params.billingStatus}
          onChange={event => setParams('billingStatus', event.target.value)}
          sx={{ width: '180px' }}
          variant='outlined'
          size='small'
          select>
          {status.map(option => (
            <MenuItem key={option.code} value={option.name}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label='Billing Periods From'
          type='date'
          value={params.billingPeriodFrom}
          onChange={event => setParams('billingPeriodFrom', event.target.value)}
          slotProps={{ inputLabel: { shrink: true } }}
          sx={{ width: '180px' }}
          variant='outlined'
          size='small'
        />
        <TextField
          label='Billing Periods To'
          type='date'
          value={params.billingPeriodTo}
          onChange={(event, value) =>
            setParams('billingPeriodTo', event.target.value)
          }
          slotProps={{ inputLabel: { shrink: true } }}
          sx={{ width: '180px' }}
          variant='outlined'
          size='small'
        />
        <TextField
          label='Invoice Date From'
          type='date'
          value={params.invoiceConfFrom}
          onChange={event => setParams('invoiceConfFrom', event.target.value)}
          slotProps={{ inputLabel: { shrink: true } }}
          sx={{ width: '180px' }}
          variant='outlined'
          size='small'
        />
        <TextField
          label='Invoice Date To'
          type='date'
          value={params.invoiceConfTo}
          onChange={event => setParams('invoiceConfTo', event.target.value)}
          slotProps={{ inputLabel: { shrink: true } }}
          sx={{ width: '180px' }}
          variant='outlined'
          size='small'
        />
        <TextField
          label='Invoice Number'
          value={params.invoiceNumber}
          onChange={event => setParams('invoiceNumber', event.target.value)}
          slotProps={{ inputLabel: { shrink: true } }}
          sx={{ width: '180px' }}
          variant='outlined'
          size='small'
        />
        <TextField
          label='Billing Number'
          value={params.billingNumber}
          onChange={event => setParams('billingNumber', event.target.value)}
          slotProps={{ inputLabel: { shrink: true } }}
          sx={{ width: '180px' }}
          variant='outlined'
          size='small'
        />
        <Autocomplete
          value={params.operator || null}
          onChange={(event, value) => setParams('operator', value)}
          disableClearable={Boolean(params.customer)}
          isOptionEqualToValue={(option, value) =>
            option.username === value.username || ''
          }
          options={[...operators]}
          getOptionLabel={option => (option ? option.username : '')}
          renderInput={params => (
            <TextField
              {...params}
              label='Operator'
              variant='outlined'
              slotProps={{ inputLabel: { shrink: true } }}
            />
          )}
          limitTags={1}
          sx={{ width: '180px' }}
          variant='outlined'
          size='small'
        />
        <IconButton color='secondary' onClick={search}>
          <SearchIcon />
        </IconButton>
      </Stack>
    </Card>
  )
}
