import { useState, useEffect, useCallback, Fragment } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import {
  AppBar,
  Drawer,
  FormControl,
  IconButton,
  Select,
  Box,
  Menu,
  MenuItem,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  Divider
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { changeLanguagePref, getLanguageString } from './utilities/language'
import { getUserManagedCustomers } from './utilities/common'
import { useCustomer, useToken } from './utilities/hooks'
import { userLogout } from './actions/userActions'
import MenuIcon from '@mui/icons-material/Menu'
import AccountIcon from '@mui/icons-material/AccountCircle'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ErrorBoundary from './components/ErrorBoundary'

const appbarHeight = 64
const drawerWidth = 270

const Container = styled('div')(() => ({
  display: 'flex',
  backgroundColor: '#f7f7f7',
  height: `calc(100vh - ${appbarHeight}px)`,
  marginTop: appbarHeight + 'px',
  overflow: 'auto'
}))
const Appbar = styled(AppBar)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  backgroundColor: '#273340',
  zIndex: theme.zIndex.drawer + 1
}))
const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })(
  ({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: `-${drawerWidth}px`,
    width: '100%',
    variants: [
      {
        props: ({ open }) => open,
        style: {
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
          }),
          marginLeft: 0,
          width: `calc(100% - ${drawerWidth}px)`
        }
      }
    ]
  })
)

const Frame = props => {
  const { customerData, updateCustomerData } = useCustomer()
  const [customerList, setCustomerList] = useState([])
  const [anchor, setAnchor] = useState(null)
  const [open, setOpen] = useState(true)
  const [expand, setExpand] = useState('')
  const { i18n } = useTranslation('common')
  const { authorizedMenuItems } = useToken()
  const location = useLocation()
  const history = useHistory()

  const setDefaultCustomer = useCallback(
    data => {
      updateCustomerData(data)
      window.localStorage.setItem('customer_data', JSON.stringify(data))
    },
    [updateCustomerData]
  )

  const changeDefaultCustomer = e => {
    const id = e.target.value
    const customerData = customerList.find(x => x.id === id)
    setDefaultCustomer(customerData)
  }

  const changeLanguage = () => {
    if (i18n.language === 'en') {
      changeLanguagePref(i18n, 'cn')
    } else {
      changeLanguagePref(i18n, 'en')
    }
  }

  useEffect(() => {
    const customerList = getUserManagedCustomers()
    if (customerList.length) {
      setCustomerList(customerList)
      if (!customerData.id) {
        setDefaultCustomer(customerList[0])
      }
    }
  }, [customerData.id, setDefaultCustomer])

  return (
    <Container>
      <Appbar position='fixed'>
        <Box
          height={appbarHeight + 'px'}
          width='100%'
          display='flex'
          alignItems='center'
          justifyContent='space-between'>
          <Box display='flex' alignItems='center'>
            <IconButton onClick={() => setOpen(!open)}>
              <MenuIcon sx={{ color: '#ff9d00', width: 40, height: 40 }} />
            </IconButton>
            <img
              src='/images/icons/logo.svg'
              alt=''
              style={{
                width: 250,
                height: 40,
                marginLeft: 15
              }}
            />
          </Box>
          <Box display='flex' alignItems='center'>
            {customerList.length > 0 ? (
              <FormControl
                variant='outlined'
                sx={{ width: '200px', margin: '5px 40px 5px 0px' }}>
                <Select
                  value={customerData.id}
                  onChange={changeDefaultCustomer}
                  inputProps={{
                    sx: {
                      color: 'white'
                    }
                  }}
                  sx={{
                    '.MuiSelect-icon': {
                      fill: 'white'
                    }
                  }}
                  size='small'>
                  {customerList.map(value => (
                    <MenuItem value={value.id} key={value.id}>
                      {value.alias}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
            <IconButton onClick={e => setAnchor(e.currentTarget)}>
              <AccountIcon
                style={{ color: '#ffffff', width: 40, height: 40 }}
              />
            </IconButton>
            <Menu
              open={Boolean(anchor)}
              anchorEl={anchor}
              onClose={() => setAnchor(null)}>
              <MenuItem onClick={changeLanguage}>
                {getLanguageString(i18n.language)}
              </MenuItem>
              <Divider />
              <MenuItem onClick={userLogout}>Logout</MenuItem>
            </Menu>
          </Box>
        </Box>
      </Appbar>
      <Drawer
        open={open}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth + 'px',
            marginTop: appbarHeight + 'px',
            height: `calc(100vh - ${appbarHeight}px)`,
            boxSizing: 'border-box'
          }
        }}
        variant='persistent'
        anchor='left'>
        <List>
          {authorizedMenuItems.map(item => {
            const pathname = '/' + item.id
            const active = location.pathname.startsWith(pathname)
            const expanded = expand === item.id
            return (
              <Fragment key={item.id}>
                <ListItemButton
                  onClick={() =>
                    item.subitems
                      ? setExpand(expanded ? '' : item.id)
                      : history.push(pathname)
                  }
                  sx={
                    active
                      ? {
                          color: '#55a6ff',
                          backgroundColor: 'rgba(85, 166, 255, 0.2) !important'
                        }
                      : { color: '#2a3848', backgroundColor: '#ffffff' }
                  }>
                  <ListItemText
                    primaryTypographyProps={{
                      fontWeight: active ? 600 : undefined
                    }}>
                    {item.title}
                  </ListItemText>
                  {item.subitems ? (
                    expanded ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )
                  ) : null}
                </ListItemButton>
                {item.subitems && (
                  <Collapse in={expanded}>
                    <List component='div' disablePadding>
                      {item.subitems.map(subitem => {
                        const subitemPathname = '/' + subitem.id
                        const subitemActive =
                          location.pathname === subitemPathname
                        return (
                          <ListItemButton
                            onClick={() => history.push(subitemPathname)}
                            key={subitem.id}
                            sx={
                              subitemActive
                                ? {
                                    color: '#55a6ff',
                                    backgroundColor:
                                      'rgba(85, 166, 255, 0.1) !important'
                                  }
                                : {
                                    color: '#2a3848',
                                    backgroundColor: active
                                      ? 'rgba(85, 166, 255, 0.1) !important'
                                      : undefined
                                  }
                            }>
                            <ListItemText
                              primaryTypographyProps={{
                                fontWeight: subitemActive ? 600 : undefined,
                                paddingLeft: 2,
                                fontSize: 'small'
                              }}>
                              {subitem.title}
                            </ListItemText>
                          </ListItemButton>
                        )
                      })}
                    </List>
                  </Collapse>
                )}
              </Fragment>
            )
          })}
        </List>
      </Drawer>
      <Main open={open}>
        <ErrorBoundary key={location.pathname}>{props.children}</ErrorBoundary>
      </Main>
    </Container>
  )
}

export default Frame
