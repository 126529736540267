import React from 'react'
import dayjs from 'dayjs'
import { Grid2 as Grid, Typography, Paper } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { MonthCalendar } from '@mui/x-date-pickers/MonthCalendar'

const WelcomePage = () => {
  const today = dayjs()

  return (
    <Grid container spacing={0} sx={{ width: '100%' }}>
      <Grid size={{ lg: 6, md: 12 }}>
        <Paper
          elevation={0}
          sx={{
            padding: 3,
            marginBottom: 2,
            backgroundImage: `url('/images/main_hp2.png')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}>
          <Typography variant='subtitle1'>Good Day!</Typography>
          <Typography variant='h5'>
            Start your day feeling calm and in control.
          </Typography>
          <Typography variant={'body1'} style={{ margin: '18px 0' }}>
            Get ready to streamline your shipping processes and gain full
            control over your shipments. Stay informed, stay organized, and
            enjoy the efficiency and convenience of our comprehensive shipping
            services.
          </Typography>
        </Paper>
        <Paper elevation={0} sx={{ pointerEvents: 'none', cursor: 'default' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container spacing={0}>
              <Grid
                size={{ lg: 6, md: 12 }}
                sx={{ paddingTop: 3, margin: '0 auto' }}>
                <MonthCalendar
                  defaultValue={today}
                  sx={{
                    width: '100%',
                    '.MuiPickersMonth-monthButton': {
                      fontSize: '18px',
                      fontWeight: 400
                    }
                  }}
                />
              </Grid>
              <Grid
                size={{ lg: 6, md: 12 }}
                sx={{ paddingTop: 3, margin: '0 auto', minWidth: '280px' }}>
                <DateCalendar
                  slotProps={{ calendarHeader: { sx: { display: 'none' } } }}
                  defaultValue={today}
                  sx={{
                    width: '100%',
                    height: 'auto'
                  }}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Paper>
      </Grid>
      <Grid size={{ lg: 6, md: 12 }}>
        <img width='100%' src='/images/main_hp1.png' alt='Welcome Pic' />
      </Grid>
    </Grid>
  )
}

export default WelcomePage
