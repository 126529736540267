import { Backdrop, CircularProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: 3000,
    color: '#fff'
  }
}))

function FullscreenLoading(props) {
  const classes = useStyles()

  return (
    <Backdrop className={classes.backdrop} open={props.open}>
      <CircularProgress color='secondary' />
    </Backdrop>
  )
}

FullscreenLoading.defaultProps = {
  open: false
}

export default FullscreenLoading
