import {
  GET_SERVICE_POINT_PICKUP_RATIO,
  GET_SERVICE_POINT_PICKUP_RATIO_SUCCESS,
  GET_SERVICE_POINT_PICKUP_RATIO_FAIL,
  BASIC_INFO_LOAD,
  BASIC_INFO_LOAD_SUCCESS,
  BASIC_INFO_LOAD_FAIL,
  SEARCH_SERVICE_POINT,
  SEARCH_SERVICE_POINT_SUCCESS,
  SEARCH_SERVICE_POINT_FAIL,
  OPEN_CLOSE_SERVICE_POINT,
  OPEN_CLOSE_SERVICE_POINT_SUCCESS,
  OPEN_CLOSE_SERVICE_POINT_FAIL,
  EDIT_SERVICE_POINT,
  EDIT_SERVICE_POINT_SUCCESS,
  EDIT_SERVICE_POINT_FAIL,
  ADD_SERVICE_POINT,
  ADD_SERVICE_POINT_SUCCESS,
  ADD_SERVICE_POINT_FAIL,
  GET_DRIVER_DROP_OFF_HISTORY,
  GET_DRIVER_DROP_OFF_HISTORY_SUCCESS,
  GET_DRIVER_DROP_OFF_HISTORY_FAIL,
  HANDLE_SERVICE_POINT
} from '../actions/types'

const initialState = {
  getServicePointPickupRatioLoading: false,
  getServicePointPickupRatio: [],
  getServicePointPickupRatioError: false,
  basicInfoLoadLoading: false,
  basicInfoLoad: [],
  basicInfoLoadError: false,
  searchServicePointLoading: false,
  searchServicePoint: [],
  searchServicePointError: false,
  openCloseServicePointLoading: false,
  openCloseServicePoint: [],
  openCloseServicePointError: false,
  editServicePointLoading: false,
  editServicePoint: [],
  editServicePointError: false,
  addServicePointLoading: false,
  addServicePoint: [],
  addServicePointError: false,
  getDriverDropOffHistoryLoading: false,
  getDriverDropOffHistory: [],
  getDriverDropOffHistoryError: false,
  servicePoint: ''
}

export default function servicePointReducer(state = initialState, action) {
  switch (action.type) {
    case HANDLE_SERVICE_POINT:
      return {
        ...state,
        servicePoint: action.payload
      }
    case GET_DRIVER_DROP_OFF_HISTORY:
      return {
        ...state,
        getDriverDropOffHistoryLoading: true
      }
    case GET_DRIVER_DROP_OFF_HISTORY_SUCCESS:
      return {
        ...state,
        getDriverDropOffHistory: action.payload,
        getDriverDropOffHistoryLoading: false
      }
    case GET_DRIVER_DROP_OFF_HISTORY_FAIL:
      return {
        ...state,
        getDriverDropOffHistoryLoading: false,
        getDriverDropOffHistoryError: action.payload
      }
    case ADD_SERVICE_POINT:
      return {
        ...state,
        addServicePointLoading: true
      }
    case ADD_SERVICE_POINT_SUCCESS:
      return {
        ...state,
        addServicePoint: action.payload,
        addServicePointLoading: false
      }
    case ADD_SERVICE_POINT_FAIL:
      return {
        ...state,
        addServicePointLoading: false,
        addServicePointError: action.payload
      }
    case EDIT_SERVICE_POINT:
      return {
        ...state,
        editServicePointLoading: true
      }
    case EDIT_SERVICE_POINT_SUCCESS:
      return {
        ...state,
        editServicePoint: action.payload,
        editServicePointLoading: false
      }
    case EDIT_SERVICE_POINT_FAIL:
      return {
        ...state,
        editServicePointLoading: false,
        editServicePointError: action.payload
      }
    case OPEN_CLOSE_SERVICE_POINT:
      return {
        ...state,
        openCloseServicePointLoading: true
      }
    case OPEN_CLOSE_SERVICE_POINT_SUCCESS:
      return {
        ...state,
        openCloseServicePoint: action.payload,
        openCloseServicePointLoading: false
      }
    case OPEN_CLOSE_SERVICE_POINT_FAIL:
      return {
        ...state,
        openCloseServicePointLoading: false,
        openCloseServicePointError: action.payload
      }
    case SEARCH_SERVICE_POINT:
      return {
        ...state,
        searchServicePointLoading: true
      }
    case SEARCH_SERVICE_POINT_SUCCESS:
      return {
        ...state,
        searchServicePoint: action.payload,
        searchServicePointLoading: false
      }
    case SEARCH_SERVICE_POINT_FAIL:
      return {
        ...state,
        searchServicePointLoading: false,
        searchServicePointError: action.payload
      }
    case BASIC_INFO_LOAD:
      return {
        ...state,
        basicInfoLoadLoading: true
      }
    case BASIC_INFO_LOAD_SUCCESS:
      return {
        ...state,
        basicInfoLoad: action.payload,
        basicInfoLoadLoading: false,
        cityList: action.payload.data.city
      }
    case BASIC_INFO_LOAD_FAIL:
      return {
        ...state,
        basicInfoLoadLoading: false,
        basicInfoLoadError: action.payload
      }
    case GET_SERVICE_POINT_PICKUP_RATIO:
      return {
        ...state,
        getServicePointPickupRatioLoading: true
      }
    case GET_SERVICE_POINT_PICKUP_RATIO_SUCCESS:
      return {
        ...state,
        getServicePointPickupRatio: action.payload,
        getServicePointPickupRatioLoading: false
      }
    case GET_SERVICE_POINT_PICKUP_RATIO_FAIL:
      return {
        ...state,
        getServicePointPickupRatioLoading: false,
        getServicePointPickupRatioError: action.payload
      }
    default:
      return state
  }
}
