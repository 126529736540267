import { execute } from '../utilities/executor'
import { shangjiaServerInstance } from '../utilities/instances'
import { DELIVERY_CAPACITIES as types } from './types'

export const getWarehouseCapacities =
  (year = '', week = '') =>
  d =>
    execute(d)(
      shangjiaServerInstance.get(
        `business/warehousecapacity?year=${year}&week=${week}`
      ),
      {
        type: types.FETCH_WAREHOUSE_CAPACITIES,
        success: types.FETCH_WAREHOUSE_CAPACITIES_SUCCESS,
        fail: types.FETCH_WAREHOUSE_CAPACITIES_FAIL
      }
    )

export const getPartnerCapacities =
  (id, year = '', week = '') =>
  d =>
    execute(d)(
      shangjiaServerInstance.get(
        `business/partnercapacity?year=${year}&week=${week}&id=${id}`
      ),
      {
        type: types.FETCH_PARTNER_CAPACITIES,
        success: types.FETCH_PARTNER_CAPACITIES_SUCCESS,
        fail: types.FETCH_PARTNER_CAPACITIES_FAIL
      }
    )

export const updateCapacities = params => d =>
  execute(d)(shangjiaServerInstance.post(`/business/updatecapacity`, params), {
    type: types.UPDATE_WAREHOUSE_CAPACITIES,
    success: types.UPDATE_WAREHOUSE_CAPACITIES_SUCCESS,
    fail: types.UPDATE_WAREHOUSE_CAPACITIES_FAIL
  })
export const getPartnerThresholdByWarehouse =
  (year = '', week = '') =>
  d =>
    execute(d)(
      shangjiaServerInstance.get(
        `/business/groupedpatnerthresholds?year=${year}&week=${week}`
      ),
      {
        type: types.FETCH_PARTNER_WAREHOUSE_THRESHOLD,
        success: types.FETCH_PARTNER_WAREHOUSE_THRESHOLD_SUCCESS,
        fail: types.FETCH_PARTNER_WAREHOUSE_THRESHOLD_FAIL
      }
    )

export const updatePartnerThresholdByWarehouse = params => d =>
  execute(d)(
    shangjiaServerInstance.post(`/business/groupedpatnerthresholds`, params),
    {
      type: types.UPDATE_PARTNER_WAREHOUSE_THRESHOLD,
      success: types.UPDATE_PARTNER_WAREHOUSE_THRESHOLD_SUCCESS,
      fail: types.UPDATE_PARTNER_WAREHOUSE_THRESHOLD_FAIL
    }
  )
