import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Typography
} from '@mui/material'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { showAlertSnackbar } from '../../reducers/sliceReducer'
import {
  downloadInvoiceFiles,
  downloadQCPostAuditFile,
  downloadCreditMemoTemplate,
  uploadCreditMemoFile
} from '../../actions/billingManangementActions'
import { DataGrid } from '@mui/x-data-grid'
import DragDropFileUpload from '../../components/DragDropFileUpload'
import DocumentDetailDisplay from '../../components/DocumentDetailDisplay'
import FullscreenLoading from '../../components/FullscreenLoading'
import DownloadButton from '../../components/DownloadButton'

function GridItem({ title, value }) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  function getAction(fileType, filename, invoiceNum) {
    if (fileType === 'invoice') {
      return downloadInvoiceFiles({
        file_name: filename,
        invoice_num: invoiceNum
      })
    }
  }

  async function handleDownload(filename, invoiceNum, fileType) {
    try {
      setLoading(true)
      const action = getAction(fileType, filename, invoiceNum)
      await dispatch(action)
    } catch (error) {
      dispatch(showAlertSnackbar({ message: error.message, type: 'error' }))
    } finally {
      setLoading(false)
    }
  }

  return (
    <Grid container item xs={12} spacing={0} marginBottom={2}>
      <Grid item xs={5}>
        <Typography variant='body2' color='text.secondary'>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={7}>
        {typeof value === 'string' ? (
          value
        ) : value.text ? (
          <DownloadButton
            onClick={() =>
              handleDownload(value.text, value.invoiceNum, value.type)
            }
            disabled={loading}>
            {value.text}
          </DownloadButton>
        ) : null}
      </Grid>
    </Grid>
  )
}

function DownloadBill(data) {
  const dispatch = useDispatch()

  return (
    <DownloadButton
      onClick={() =>
        dispatch(
          downloadQCPostAuditFile({
            file_name: data.value,
            billing_num: data.id
          })
        )
      }>
      {data.value}
    </DownloadButton>
  )
}

const billingInfoColumns = [
  {
    field: 'billing_num',
    headerName: 'Billing ID',
    width: 150,
    sortable: false
  },
  {
    field: 'billing_start',
    headerName: 'Billing Date',
    width: 150,
    sortable: false
  },
  {
    field: 'operator_username',
    headerName: 'Operator',
    width: 180,
    sortable: false
  },
  {
    field: 'qc_postaudit_file',
    headerName: 'Bill Download',
    width: 400,
    sortable: false,
    renderCell: params => DownloadBill(params)
  }
]

const creditMemoDetailColumns = [
  {
    field: 'description',
    headerName: 'Category',
    width: 180,
    sortable: false
  },
  {
    field: 'parcel_no',
    headerName: 'Tracking No.',
    width: 280,
    sortable: false
  },
  {
    field: 'fee_adjustment',
    headerName: 'Adjusted Amount',
    width: 180,
    sortable: false,
    valueFormatter: value => ' $ ' + value
  },
  {
    field: 'comments',
    headerName: 'Comments',
    width: 300,
    sortable: false
  }
]

export default function InvoiceDetail(props) {
  const { setDialogOpen, data, refreshDialog, setDetails, refresh } = props
  const dispatch = useDispatch()
  const [uploadDialog, openUploadDialog] = useState(false)
  const [creditMemoFile, setCreditMemoFile] = useState('')
  const [loading, setLoading] = useState(false)
  const [failedMsg, setFailedMsg] = useState(null)
  const [fileDetails, setFileDetails] = useState(null)
  const [detailDialog, openDetailDialog] = useState(false)
  const [cancelDialog, openCancelDialog] = useState(false)

  let invoiceInfo = [
    {
      title: 'Invoice Number:',
      value: data['invoiceInfo']['invoice_num'] ?? ''
    },
    {
      title: 'Invoice Creation Date:',
      value: data['invoiceInfo']['creation_time'] ?? ''
    },
    {
      title: 'Amount',
      value: '$ ' + data['invoiceInfo']['total_amount'] ?? ''
    },
    {
      title: 'Invoice',
      value: {
        text: data['invoiceInfo']['invoice_file'],
        type: 'invoice',
        invoiceNum: data['invoiceInfo']['invoice_num']
      }
    }
  ]

  async function downloadTemplate() {
    try {
      setLoading(true)
      await dispatch(downloadCreditMemoTemplate())
    } catch (error) {
      dispatch(showAlertSnackbar({ message: error.message, type: 'error' }))
    } finally {
      setLoading(false)
    }
  }

  async function handleValidateCreditMemoFile() {
    try {
      setLoading(true)
      let formData = new FormData()
      formData.append('file', creditMemoFile)
      formData.append('invoice_num', data['invoiceInfo']['invoice_num'])
      formData.append('write', 0)
      await dispatch(uploadCreditMemoFile(formData))
        .then(res => {
          setLoading(false)
          setFileDetails(res)
          openUploadDialog(false)
          openDetailDialog(true)
        })
        .catch(err => {
          setLoading(false)
          dispatch(showAlertSnackbar({ message: err.message, type: 'error' }))
          if (err.additional) {
            setFailedMsg(err.additional)
          } else {
            setFailedMsg(err.message)
          }
        })
    } catch (error) {
      setLoading(false)
      setFailedMsg(error.additional)
    }
  }

  async function handleUploadCreditMemoFile() {
    try {
      setLoading(true)
      let formData = new FormData()
      formData.append('file', creditMemoFile)
      formData.append('invoice_num', data['invoiceInfo']['invoice_num'])
      formData.append('write', 1)
      await dispatch(uploadCreditMemoFile(formData)).then(res => {
        setLoading(false)
        refresh()
        setDetails([])
        refreshDialog({ cr_num: res, slip_type_description: 'Credit Memo' })
        openDetailDialog(false)
      })
    } catch (error) {
      setLoading(false)
      setFailedMsg(error.additional)
    }
  }

  return (
    <>
      <DialogTitle>Invoice Detail</DialogTitle>
      <DialogContent dividers sx={{ padding: 0 }}>
        <Box padding={3}>
          <Typography marginBottom={2}>Invoice Information</Typography>
          <Grid container spacing={0}>
            {invoiceInfo.map((item, index) => (
              <GridItem key={index} title={item.title} value={item.value} />
            ))}
          </Grid>
        </Box>
        <Divider />
        <Box padding={3}>
          <Typography marginBottom={2}>Billing Information</Typography>
          <DataGrid
            rows={data['billingInfo'] ?? []}
            columns={billingInfoColumns}
            checkboxSelection={false}
            getRowId={row => row.billing_num}
            disableColumnMenu
            className='bordered'
            hideFooter
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: 3 }}>
        <Button
          autoFocus
          onClick={() => {
            setDialogOpen(false)
          }}>
          CLOSE
        </Button>
        <Button
          variant='contained'
          onClick={() => {
            openUploadDialog(true)
          }}>
          ADD CREDIT MEMO
        </Button>
      </DialogActions>

      <Dialog maxWidth={'sm'} fullWidth open={uploadDialog}>
        <DialogTitle>Upload Credit Memo</DialogTitle>
        {failedMsg ? (
          <DialogContent dividers>
            <Typography>
              Please correct the rows listed below and upload the credit memo
              again
            </Typography>
            <Box marginY={2}>
              <DocumentDetailDisplay file={creditMemoFile} state={'Complete'} />
            </Box>
            {Array.isArray(failedMsg) ? (
              <Typography component='ul' color='error'>
                {failedMsg.map(message => {
                  return (
                    <li key={message.row}>
                      Row {message.row}:
                      <ul>
                        {message.errors.map((error, index) => (
                          <li key={index}>{error}</li>
                        ))}
                      </ul>
                    </li>
                  )
                })}
              </Typography>
            ) : (
              <Typography color='error'>{failedMsg}</Typography>
            )}
          </DialogContent>
        ) : (
          <DialogContent dividers>
            <Typography>
              Please utilize the provided template for adding a new credit memo
            </Typography>
            <DownloadButton onClick={() => downloadTemplate()}>
              {'template.xlsx'}
            </DownloadButton>
            <Box marginY={1}>
              <DragDropFileUpload
                fileType={'.xlsx'}
                onFileUpload={setCreditMemoFile}
                file={creditMemoFile}
              />
            </Box>
          </DialogContent>
        )}
        {failedMsg ? (
          <DialogActions sx={{ padding: 3 }}>
            <Button
              autoFocus
              onClick={() => {
                setFailedMsg(null)
                setCreditMemoFile('')
              }}>
              Back
            </Button>
          </DialogActions>
        ) : (
          <DialogActions sx={{ padding: 3 }}>
            <Button
              autoFocus
              onClick={() => {
                setCreditMemoFile('')
                openUploadDialog(false)
              }}>
              Back
            </Button>
            <Button variant='contained' onClick={handleValidateCreditMemoFile}>
              Upload
            </Button>
          </DialogActions>
        )}
        <FullscreenLoading open={loading} />
      </Dialog>

      <Dialog maxWidth={'lg'} fullWidth open={detailDialog}>
        <DialogTitle>Upload Credit Memo</DialogTitle>
        <DialogContent dividers>
          <Box marginBottom={2}>
            <DocumentDetailDisplay file={creditMemoFile} state={'Complete'} />
          </Box>
          <Box>
            <Typography marginBottom={1}>
              <b>Credit Memo Information</b>
            </Typography>
            <Typography display='inline-block' marginRight={4}>
              <Typography color='text.secondary' component='span'>
                Invoice Number:{' '}
              </Typography>
              {fileDetails?.invoice_num}
            </Typography>
            <Typography display='inline-block'>
              <Typography color='text.secondary' component='span'>
                Total Adjusted Amount:{' '}
              </Typography>
              {'$ ' + fileDetails?.total}
            </Typography>
          </Box>
          <Box>
            <Typography marginBottom={2}>Credit Memo Details</Typography>
            <DataGrid
              rows={fileDetails?.details ?? []}
              columns={creditMemoDetailColumns}
              checkboxSelection={false}
              getRowId={row => row.id}
              disableColumnMenu={true}
              className='bordered'
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button
            autoFocus
            onClick={() => {
              openCancelDialog(true)
            }}>
            CANCEL
          </Button>
          <Button variant='contained' onClick={handleUploadCreditMemoFile}>
            CONFIRM AND SUBMIT
          </Button>
        </DialogActions>
        <FullscreenLoading open={loading} />
      </Dialog>

      <Dialog maxWidth={'sm'} fullWidth open={cancelDialog}>
        <DialogTitle>Cancel this credit memo?</DialogTitle>
        <DialogContent dividers>
          Are you sure you want to cancel this credit memo?
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button
            autoFocus
            onClick={() => {
              openCancelDialog(false)
            }}>
            NO
          </Button>
          <Button
            autoFocus
            onClick={() => {
              openCancelDialog(false)
              openDetailDialog(false)
              setCreditMemoFile('')
              setFileDetails(null)
              setDialogOpen(false)
            }}>
            YES
          </Button>
        </DialogActions>
        <FullscreenLoading open={loading} />
      </Dialog>
    </>
  )
}
