import { Button, Grid, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getOpenPrealertFormDialog,
  handleOpenPrealertFormDialog,
  showAlertSnackbar
} from '../../reducers/sliceReducer'
import Dialog from '../../components/Dialog'

const useStyles = makeStyles(theme => ({
  paramStyle: {
    marginTop: theme.spacing(2),
    textAlign: 'center'
  },
  textFieldStyle: {
    width: 400
  },
  buttonGroupStyle: {
    textAlign: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2)
  },
  buttonStyle: {
    width: 200
  }
}))

function CreateEditDialog(props) {
  const dispatch = useDispatch()
  const classes = useStyles()

  const openModalObject = useSelector(getOpenPrealertFormDialog)
  const rowValues = useSelector(state => state.prealertReducer.rowValues)

  const editParams = [
    {
      label: 'MAWB',
      type: 'text',
      disabled: false
    },
    {
      label: 'Tracking Number',
      type: 'text',
      disabled: false
    },
    {
      label: 'Reference',
      type: 'text',
      disabled: false
    },
    {
      label: 'bagNumber',
      type: 'text',
      disabled: false
    },
    {
      label: 'Shipper',
      type: 'text',
      disabled: false
    },
    {
      label: 'Shipper Address 1',
      type: 'text',
      disabled: false
    },
    {
      label: 'Shipper Address 2',
      type: 'text',
      disabled: false
    },
    {
      label: 'Shipper Address 3',
      type: 'text',
      disabled: false
    },
    {
      label: 'portArea',
      type: 'text',
      disabled: false
    },
    {
      label: 'Shipper City',
      type: 'text',
      disabled: false
    },
    {
      label: 'Shipper Country/state',
      type: 'text',
      disabled: false
    },
    {
      label: 'Shipper Zip',
      type: 'text',
      disabled: false
    },
    {
      label: 'Shipper Country Code',
      type: 'text',
      disabled: false
    },
    {
      label: 'Consignee',
      type: 'text',
      disabled: false
    },
    {
      label: 'Address 1',
      type: 'text',
      disabled: false
    },
    {
      label: 'Address 2',
      type: 'text',
      disabled: false
    },
    {
      label: 'Address 3',
      type: 'text',
      disabled: false
    },
    {
      label: 'City',
      type: 'text',
      disabled: false
    },
    {
      label: 'Province',
      type: 'text',
      disabled: false
    },
    {
      label: 'Province Code',
      type: 'text',
      disabled: false
    },
    {
      label: 'Zip',
      type: 'text',
      disabled: false
    },
    {
      label: 'Counrty Code',
      type: 'text',
      disabled: false
    },
    {
      label: 'Email',
      type: 'text',
      disabled: false
    },
    {
      label: 'Phone',
      type: 'text',
      disabled: false
    },
    {
      label: 'Pieces',
      type: 'text',
      disabled: false
    },
    {
      label: 'Total Weight',
      type: 'text',
      disabled: false
    },
    {
      label: 'Weight UOM',
      type: 'text',
      disabled: false
    },
    {
      label: 'Total Value',
      type: 'text',
      disabled: false
    },
    {
      label: 'Currency',
      type: 'text',
      disabled: false
    },
    {
      label: 'Incoterms',
      type: 'text',
      disabled: false
    },
    {
      label: 'Item Description',
      type: 'text',
      disabled: false
    },
    {
      label: 'Item Value',
      type: 'text',
      disabled: false
    },
    {
      label: 'Master Order Number',
      type: 'text',
      disabled: false
    },
    {
      label: 'Length',
      type: 'text',
      disabled: false
    },
    {
      label: 'Width',
      type: 'text',
      disabled: false
    },
    {
      label: 'Height',
      type: 'text',
      disabled: false
    },
    {
      label: 'Country Of Origin',
      type: 'text',
      disabled: false
    },
    {
      label: 'express_type',
      type: 'text',
      disabled: false
    }
  ]

  const [paramsForm, setParamsForm] = useState({})

  const onClose = () => {
    dispatch(handleOpenPrealertFormDialog({ open: false }))
  }

  const handleParamChange = (key, newValue) => {
    let _newValue = newValue
    setParamsForm(prevParamsForm => ({
      ...prevParamsForm,
      [key]: _newValue
    }))
  }

  useEffect(() => {
    setParamsForm({})
    if (rowValues.data) {
      try {
        let data = JSON.parse(rowValues.data).parcel_info[0]
        setParamsForm(prev => ({
          ...prev,
          ...data
        }))
      } catch (e) {
        if (openModalObject.open) {
          dispatch(
            showAlertSnackbar({
              message: 'Error: Missing parcel info',
              type: 'error'
            })
          )
        }
      }
    }
  }, [rowValues, openModalObject, dispatch])

  return (
    <Dialog
      open={openModalObject.open}
      onClose={onClose}
      maxWidth='md'
      fullWidth
      disableDefaultButtons
      title={'Edit Order'}>
      <Grid container spacing={1}>
        {editParams.map((param, index) => (
          <Grid item xs={6} className={classes.paramStyle} key={index}>
            <TextField
              label={param.label}
              disabled={param.disabled}
              fullWidth
              variant='outlined'
              className={classes.textFieldStyle}
              onChange={e => handleParamChange(param.label, e.target.value)}
              value={paramsForm[`${param.label}`]}
            />
          </Grid>
        ))}
      </Grid>
      <div className={classes.buttonGroupStyle}>
        <Button
          color='primary'
          variant='contained'
          className={classes.buttonStyle}
          onClick={() => props.onSubmit(paramsForm)}>
          {'submit'}
        </Button>
      </div>
    </Dialog>
  )
}

export default CreateEditDialog
