import { useContext } from 'react'
import { AlertContext } from '../contexts/AlertContext'
import { TokenContext } from '../contexts/TokenContext'
import { CustomerContext } from '../contexts/CustomerContext'

export function useAlert() {
  return useContext(AlertContext)
}

export function useToken() {
  return useContext(TokenContext)
}

export function useCustomer() {
  return useContext(CustomerContext)
}
