import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { lighten } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Card,
  Checkbox,
  IconButton,
  Tooltip,
  FormControlLabel,
  Switch
} from '@mui/material'
import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  getBasicInfo,
  openCloseServicePoint,
  searchServicePoint
} from '../../actions/servicePointActions'
import ServicePointSearchKeyword from './ServicePointSearchKeyword'
import ServicePointWarehouse from './ServicePointWarehouse'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

function EnhancedTableHead(props) {
  const { t } = useTranslation('servicePointManagement')
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
  } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  const headCells = [
    { id: 'name', numeric: false, disablePadding: true, label: t('serial_no') },
    {
      id: 'company',
      numeric: true,
      disablePadding: false,
      label: t('company')
    },
    {
      id: 'address',
      numeric: true,
      disablePadding: false,
      label: t('address')
    },
    { id: 'phone', numeric: true, disablePadding: false, label: t('phone') },
    {
      id: 'is_active',
      numeric: true,
      disablePadding: false,
      label: t('is_active')
    }
    // { id: 'operation', disablePadding: false, label: t('operation') },
  ]

  return (
    <TableHead>
      <TableRow>
        <TableCell padding='checkbox'>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align='left'
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  activeStyle: {
    color: theme.palette.success.main
  },
  inactiveStyle: {
    color: theme.palette.error.main
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: '1 1 100%'
  }
}))

export default function ServicePointManagement(props) {
  const classes = useStyles()
  const { t } = useTranslation('servicePointManagement')
  const dispatch = useDispatch()

  const searchServicePointRes = useSelector(
    state => state.servicePoint.searchServicePoint
  )

  const [keyword, setKeyword] = useState()
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('calories')
  const [selected, setSelected] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [dense, setDense] = React.useState(false)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [rowsDisplay, setRowsDisplay] = useState([])
  const [emptyRows, setEmptyRows] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [rows, setRows] = useState([])
  const [filterChecked, setFilterChecked] = useState(true)

  const [successOpen, setSuccessOpen] = useState(false)
  const [failOpen, setFailOpen] = useState(false)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rowsDisplay.map(n => n.id)
      setSelected(newSelecteds)
    } else {
      setSelected([])
    }
  }
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    setSelected(newSelected)
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  const handleChangeDense = event => {
    setDense(event.target.checked)
  }
  const handleSuccessClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSuccessOpen(false)
  }
  const handleFailClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setFailOpen(false)
  }

  const search = useCallback(() => {
    const warehouse = window.localStorage.getItem('warehouse')
    if (filterChecked) {
      dispatch(searchServicePoint(warehouse, 0))
    } else {
      dispatch(searchServicePoint(warehouse, 1))
    }
  }, [filterChecked, dispatch])

  useEffect(() => {
    dispatch(getBasicInfo())
  }, [dispatch])

  useEffect(() => {
    search()
  }, [search])

  useEffect(() => {
    if (searchServicePointRes) {
      if (searchServicePointRes.length > 0) {
        setRows(searchServicePointRes)
      } else {
        setRows([])
      }
    }
  }, [searchServicePointRes])

  useEffect(() => {
    if (keyword) {
      let rowsClone = JSON.parse(JSON.stringify(rows))
      let rowsCurr = []
      rowsClone.forEach(row => {
        if (row.name.indexOf(keyword) >= 0) {
          rowsCurr.push(row)
        }
      })
      setRowsDisplay(rowsCurr)
    } else {
      setRowsDisplay(rows)
    }
  }, [keyword, rows])

  useEffect(() => {
    if (rowsDisplay) {
      setEmptyRows(
        rowsPerPage -
          Math.min(rowsPerPage, rowsDisplay.length - page * rowsPerPage)
      )
      setRowCount(rowsDisplay.length)
    }
  }, [rowsDisplay, rowsPerPage, page])

  useEffect(() => {
    if (rows) {
      setRowsDisplay(rows)
    }
  }, [rows])

  function isSelected(id) {
    return selected.indexOf(id) !== -1
  }
  function handleClose(checked) {
    const filter = checked ? 0 : 1
    dispatch(openCloseServicePoint(selected.toString(), 1))
      .then(() => {
        setSuccessOpen(true)
        dispatch(
          searchServicePoint(window.localStorage.getItem('warehouse'), filter)
        ).then(() => {
          setSelected([])
        })
      })
      .catch(() => {
        setFailOpen(true)
      })
  }
  function handleOpen(checked) {
    const filter = checked ? 0 : 1
    dispatch(openCloseServicePoint(selected.toString(), 0))
      .then(() => {
        setSuccessOpen(true)
        dispatch(
          searchServicePoint(window.localStorage.getItem('warehouse'), filter)
        ).then(() => {
          setSelected([])
        })
      })
      .catch(() => {
        setFailOpen(true)
      })
  }

  return (
    <div className={classes.root}>
      <Snackbar
        open={successOpen}
        autoHideDuration={6000}
        onClose={handleSuccessClose}>
        <Alert onClose={handleSuccessClose} severity='success'>
          {t('update_success_message')}
        </Alert>
      </Snackbar>
      <Snackbar
        open={failOpen}
        autoHideDuration={6000}
        onClose={handleFailClose}>
        <Alert onClose={handleFailClose} severity='error'>
          {t('update_fail_message')}
        </Alert>
      </Snackbar>

      <Typography variant='subtitle1' marginBottom={2}>
        Service Point Management
      </Typography>
      <Box display='flex' justifyContent='space-between' paddingBottom={2}>
        <ServicePointSearchKeyword
          handleKeyword={setKeyword}
          type={'uiSearch'}
        />
        <ServicePointWarehouse onChangeWarehouse={() => search()} />
      </Box>
      <Card variant='outlined' sx={{ marginBottom: 2 }}>
        <Toolbar
          className={clsx(classes.toolbar, {
            [classes.highlight]: selected.length > 0
          })}>
          {selected.length > 0 ? (
            <Typography
              className={classes.title}
              color='inherit'
              variant='subtitle2'
              component='div'>
              {selected.length} selected
            </Typography>
          ) : (
            <Typography
              className={classes.title}
              variant='h6'
              id='tableTitle'
              component='div'>
              {t('service_point')}
              <span style={{ marginLeft: 20 }}>
                <span style={{ fontSize: '0.9rem' }}>{t('filter_close')}</span>
                <Checkbox
                  color='secondary'
                  checked={filterChecked}
                  onChange={e => {
                    setFilterChecked(e.target.checked)
                  }}
                />
              </span>
            </Typography>
          )}
          <Tooltip title='Close Service Point(s)'>
            <span>
              <IconButton
                disabled={selected.length <= 0}
                onClick={() => handleClose(filterChecked)}>
                <LockIcon />
              </IconButton>
            </span>
          </Tooltip>
          {!filterChecked && (
            <Tooltip title='Open Service Point(s)'>
              <span>
                <IconButton
                  disabled={selected.length <= 0}
                  onClick={() => handleOpen(filterChecked)}>
                  <LockOpenIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </Toolbar>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby='tableTitle'
            size={dense ? 'small' : 'medium'}
            aria-label='enhanced table'>
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rowCount}
            />
            <TableBody>
              {rowsDisplay &&
                rowsDisplay.length > 0 &&
                stableSort(rowsDisplay, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id)
                    const labelId = `enhanced-table-checkbox-${index}`

                    return (
                      <TableRow
                        hover
                        onClick={event => handleClick(event, row.id)}
                        role='checkbox'
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}>
                        <TableCell padding='checkbox'>
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell>
                        <TableCell
                          component='th'
                          id={labelId}
                          scope='row'
                          padding='none'>
                          {row.name}
                        </TableCell>
                        <TableCell align='left'>{row.premise_type}</TableCell>
                        <TableCell align='left'>{row.address}</TableCell>
                        <TableCell align='left'>{row.phone}</TableCell>
                        <TableCell
                          align='left'
                          className={
                            row.is_active
                              ? classes.inactiveStyle
                              : classes.activeStyle
                          }>
                          {row.is_active ? t('close') : t('open')}
                        </TableCell>
                        {/* <TableCell>
                        <Button
                          variant='contained'
                          color='secondary'
                          onClick={() => setEditServicePointOpen(true)}
                        >
                          {t('edit')}
                        </Button>
                        </TableCell> */}
                      </TableRow>
                    )
                  })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {rowsDisplay && rowsDisplay.length > 0 && (
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component='div'
            count={rowsDisplay.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Card>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label='Dense padding'
      />
    </div>
  )
}
