import { useDispatch } from 'react-redux'
import { Button, Box, Card, TextField, Typography } from '@mui/material'
import { useEffect, useState, useCallback } from 'react'
import { showAlertSnackbar } from '../../reducers/sliceReducer'
import {
  updateCapacities,
  getWarehouseCapacities
} from '../../actions/deliveryCapacityActions'
import FullscreenLoading from '../../components/FullscreenLoading'
import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'

const ALERT = {
  SEARCH_SUCCESS: 'FETCH DATA SUCCESS',
  SEARCH_FAILED: 'FETCH DATA FAILED',
  UPDATE_SUCCESS: 'UPDATE CAPACITY SUCCESS',
  UPDATE_FAILED: 'UPDATE CAPACITY FAILED'
}

export default function DeliveryCapacityUpdate() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [week, setWeek] = useState(0)

  const mergeLists = (listA, listB) => {
    return listA.map(itemA => {
      const matchingItemB = listB.find(
        itemB => itemB.warehouse_id === itemA.warehouse_id
      )
      if (matchingItemB) {
        return {
          warehouse_id: itemA.warehouse_id,
          name: itemA.name,
          curr_capacity: itemA.capacity,
          next_capacity: matchingItemB.capacity
        }
      } else {
        return {
          warehouse_id: itemA.warehouse_id,
          name: itemA.name,
          curr_capacity: itemA.capacity,
          next_capacity: 0
        }
      }
    })
  }

  const updateListById = (id, updatedData) => {
    setList(prevList =>
      prevList.map(obj =>
        obj.warehouse_id === id ? { ...obj, ...updatedData } : obj
      )
    )
  }

  const handleChangeCapacity = (e, id, propName) => {
    const value = e.target.value
    updateListById(id, { [propName]: +value })
  }

  const handleUpdateCapacities = id => {
    const { warehouse_id, curr_capacity, next_capacity } = list.find(
      warehouse => warehouse.warehouse_id === id
    )

    const updateParams = async (week, capacity) => {
      const params = {
        id: warehouse_id,
        week: week,
        capacity: capacity
      }
      await dispatch(updateCapacities(params))
    }

    setLoading(true)
    try {
      updateParams(week, curr_capacity)
      updateParams(week + 1, next_capacity)
      dispatch(
        showAlertSnackbar({ message: ALERT.UPDATE_SUCCESS, type: 'success' })
      )
    } catch (e) {
      dispatch(
        showAlertSnackbar({ message: ALERT.UPDATE_FAILED, type: 'error' })
      )
    } finally {
      setLoading(false)
    }
  }

  const handleSearch = useCallback(
    async week => {
      setLoading(true)
      try {
        const curr = await dispatch(getWarehouseCapacities())
        const next = await dispatch(getWarehouseCapacities('', week + 1))
        setList(mergeLists(curr, next))
        dispatch(
          showAlertSnackbar({ message: ALERT.SEARCH_SUCCESS, type: 'success' })
        )
      } catch (e) {
        dispatch(
          showAlertSnackbar({ message: ALERT.SEARCH_FAILED, type: 'error' })
        )
      } finally {
        setLoading(false)
      }
    },
    [dispatch]
  )

  useEffect(() => {
    dayjs.extend(isoWeek)
    const date = dayjs()
    setWeek(date.isoWeek())
    handleSearch(date.isoWeek())
  }, [handleSearch])

  return (
    <div>
      <Typography variant='subtitle1' marginBottom={2}>
        Update Delivery Maximum Capacity
      </Typography>

      <Box display='flex' flexWrap='wrap'>
        {list &&
          list.length > 0 &&
          list.map(warehouse => {
            const { warehouse_id, name, curr_capacity, next_capacity } =
              warehouse

            return (
              <Card
                variant='outlined'
                sx={{ padding: 2, marginRight: 2, marginBottom: 2 }}>
                <Typography variant='h5'>{name}</Typography>
                <Typography variant='body2' color='textSecondary'>
                  Please enter max capacity for the current and next week
                </Typography>

                <div style={{ display: 'flex', marginTop: '24px' }}>
                  <TextField
                    id={`${name}-current-week`}
                    label={'Current Week'}
                    variant='outlined'
                    value={curr_capacity}
                    onChange={e =>
                      handleChangeCapacity(e, warehouse_id, 'curr_capacity')
                    }
                  />
                  <TextField
                    style={{ marginLeft: '24px' }}
                    id={`${name}-next-week`}
                    label={'Next Week'}
                    variant='outlined'
                    value={next_capacity}
                    onChange={e =>
                      handleChangeCapacity(e, warehouse_id, 'next_capacity')
                    }
                  />
                </div>
                <div style={{ marginTop: '24px' }}>
                  <Button
                    onClick={e => handleUpdateCapacities(warehouse_id)}
                    variant='contained'
                    color='secondary'>
                    Update
                  </Button>
                </div>
              </Card>
            )
          })}
      </Box>

      <FullscreenLoading open={loading} />
    </div>
  )
}
