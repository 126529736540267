import { useDispatch } from 'react-redux'
import {
  TextField,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Switch,
  Grid,
  Typography,
  Card,
  Stack,
  Box
} from '@mui/material'
import { useEffect, useState, useCallback } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/Add'
import { showAlertSnackbar } from '../../reducers/sliceReducer'
import {
  createPartner,
  getPartners,
  updatePartner
} from '../../actions/storeActions'
import { useAlert } from '../../utilities/hooks'
import DataGridContainer from '../../components/DataGridContainer'

const INIT_PARTNER = {
  id: '',
  name: '',
  client_id: '',
  client_secret: '',
  need_pickup: '',
  is_active: ''
}

const ALERT = {
  SEARCH_SUCCESS: 'FETCH DATA SUCCESS',
  SEARCH_FAILED: 'FETCH DATA FAILED',
  MODIFY_SUCCESS: 'MODIFY PARTNER SUCCESS',
  MODIFY_FAILED: 'MODIFY PARTNER FAILED',
  CREATE_SUCCESS: 'CREATE PARTNER SUCCESS',
  CREATE_FAILED: 'CREATE PARTNER FAILED'
}

const ERR_MSG = 'Please fill in Partner Name'

export default function PartnerManagement() {
  const dispatch = useDispatch()
  const alertDialog = useAlert()
  const [loading, setLoading] = useState(false)
  const [partner, setPartner] = useState(INIT_PARTNER)
  const [partnerID, setPartnerID] = useState('')
  const [partnerName, setPartnerName] = useState('')
  const [partnerErr, setPartnerErr] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [createPartnerOpen, setCreatePartnerOpen] = useState(false)
  const [updatePartnerOpen, setUpdatePartnerOpen] = useState(false)
  const [rows, setRows] = useState([])

  const columns = [
    {
      field: 'partner_id',
      headerName: 'Partner ID',
      flex: 1
    },
    {
      field: 'partner_name',
      headerName: 'Partner Name',
      flex: 1
    },
    {
      field: 'client_id',
      headerName: 'Client Id',
      flex: 1
    },
    {
      field: 'client_secret',
      headerName: 'Client Secret',
      flex: 1
    },
    {
      field: 'need_pickup',
      headerName: 'Need Pickup',
      flex: 1,
      cellClassName: params => {
        if (params.value === 'No') {
          return 'red-text'
        }
        return 'green-text'
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      cellClassName: params => {
        if (params.value === 'Pending') {
          return 'red-text'
        }
        return 'green-text'
      }
    }
  ]

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleSearch(partnerID, partnerName)
    }
  }

  const handleSetPartnerId = e => {
    let id = e.target.value
    if (!isNaN(id)) {
      setPartnerID(id)
    }
  }

  const handleRowClick = async selectedRow => {
    setLoading(true)
    try {
      let res = await dispatch(getPartners(selectedRow.row['partner_id']))
      setPartner(res[0])
      setUpdatePartnerOpen(true)
    } catch (e) {
      alertDialog.addAlertDialog(ALERT.CREATE_FAILED, e.message)
      alertDialog.showAlert()
    } finally {
      setLoading(false)
    }
  }

  const handlePickupStatus = e => {
    let status = partner['need_pickup'] ? 0 : 1
    setPartner(e => setPartner(prev => ({ ...prev, need_pickup: status })))
  }

  const handleActiveStatus = e => {
    let status = partner['is_active'] ? 0 : 1
    setPartner(e => setPartner(prev => ({ ...prev, is_active: status })))
  }

  const handleCreatePartner = async event => {
    event.preventDefault()
    if (partner['name'].length === 0) {
      dispatch(showAlertSnackbar({ title: ERR_MSG }))
      setPartnerErr(true)
      setErrorMessage(ERR_MSG)
      return
    }
    try {
      await dispatch(createPartner({ name: partner['name'] }))
      dispatch(
        showAlertSnackbar({ title: ALERT.CREATE_SUCCESS, type: 'success' })
      )
    } catch (e) {
      alertDialog.addAlertDialog(ALERT.CREATE_FAILED, e.message)
      alertDialog.showAlert()
    }
    setPartnerErr(false)
    setErrorMessage('')
  }

  const handleUpdatePartner = async event => {
    event.preventDefault()
    try {
      await dispatch(
        updatePartner({
          id: partner['id'],
          name: partner['name'],
          need_pickup: partner['need_pickup'],
          is_active: partner['is_active']
        })
      )
      dispatch(
        showAlertSnackbar({ title: ALERT.MODIFY_SUCCESS, type: 'success' })
      )
    } catch (e) {
      alertDialog.addAlertDialog(ALERT.MODIFY_FAILED, e.message)
      alertDialog.showAlert()
    }
  }

  const handleSearch = useCallback(
    async (partnerID, partnerName) => {
      setLoading(true)
      try {
        let res = await dispatch(getPartners(partnerID, partnerName))

        const updatedRows = res.map(row => ({
          id: row.id,
          partner_id: row.id,
          partner_name: row.name,
          client_id: row.client_id,
          client_secret: row.client_secret,
          need_pickup: row.need_pickup ? 'Yes' : 'No',
          status: row.is_active ? 'Active' : 'Pending'
        }))

        setRows(updatedRows)
        dispatch(
          showAlertSnackbar({ message: ALERT.SEARCH_SUCCESS, type: 'success' })
        )
      } catch (e) {
        dispatch(
          showAlertSnackbar({ message: ALERT.SEARCH_FAILED, type: 'error' })
        )
      } finally {
        setLoading(false)
        setPartnerID('')
        setPartnerName('')
      }
    },
    [dispatch]
  )

  useEffect(() => {
    handleSearch('', '')
  }, [handleSearch])

  useEffect(() => {
    if (createPartnerOpen) {
      setPartnerErr(false)
      setErrorMessage('')
    }
  }, [createPartnerOpen])

  useEffect(() => {
    if (!createPartnerOpen && !updatePartnerOpen) {
      setPartner(INIT_PARTNER)
    }
  }, [createPartnerOpen, updatePartnerOpen])

  return (
    <div>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        marginBottom={2}>
        <Typography variant='subtitle1'>Partner Management</Typography>
        <Button
          variant='contained'
          startIcon={<AddIcon />}
          onClick={() => setCreatePartnerOpen(true)}>
          New Partner
        </Button>
        <Dialog
          maxWidth='md'
          open={createPartnerOpen}
          onClose={() => setCreatePartnerOpen(false)}>
          <DialogTitle>New Partner</DialogTitle>
          <DialogContent dividers>
            <TextField
              required
              label='Name'
              slotProps={{ inputLabel: { shrink: true } }}
              variant='outlined'
              error={partnerErr}
              helperText={errorMessage}
              onChange={e =>
                setPartner(prev => ({ ...prev, name: e.target.value }))
              }
            />
          </DialogContent>
          <DialogActions sx={{ padding: 3 }}>
            <Button autoFocus onClick={() => setCreatePartnerOpen(false)}>
              Cancel
            </Button>
            <Button variant='contained' onClick={handleCreatePartner}>
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </Box>

      <Card variant='outlined' sx={{ marginBottom: 2 }}>
        <Stack
          direction='row'
          alignItems='center'
          spacing={2}
          useFlexGap
          flexWrap='wrap'
          sx={{ padding: 3 }}>
          <TextField
            label={'Partner Id'}
            onChange={handleSetPartnerId}
            onKeyDown={e => handleKeyPress(e)}
            value={partnerID}
            slotProps={{ inputLabel: { shrink: true } }}
            variant='outlined'
            size='small'
          />
          <TextField
            label={'Partner Name'}
            onChange={e => setPartnerName(e.target.value)}
            onKeyDown={e => handleKeyPress(e)}
            value={partnerName}
            slotProps={{ inputLabel: { shrink: true } }}
            variant='outlined'
            size='small'
          />
          <IconButton
            color={'secondary'}
            onClick={() => handleSearch(partnerID, partnerName)}>
            <SearchIcon />
          </IconButton>
        </Stack>
      </Card>

      <Card variant='outlined'>
        <Box display='flex' padding={2}>
          <Typography variant='subtitle2' flex={1}>
            Partner List
          </Typography>
        </Box>
        <DataGridContainer>
          <DataGrid
            rows={rows}
            columns={columns}
            loading={loading}
            disableColumnFilter
            disableColumnMenu
            disableSelectionOnClick
            onRowClick={handleRowClick}
            pageSizeOptions={[10, 25, { value: -1, label: 'All' }]}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10
                }
              }
            }}
            sx={{
              '& .green-text': {
                color: 'green'
              },
              '& .red-text': {
                color: 'red'
              }
            }}
          />
        </DataGridContainer>
      </Card>

      <Dialog
        maxWidth='xs'
        fullWidth
        open={updatePartnerOpen}
        onClose={() => setUpdatePartnerOpen(false)}>
        <DialogTitle>Edit Partner</DialogTitle>
        <DialogContent dividers>
          <Stack spacing={2}>
            <TextField
              id={'update-partner-name'}
              label='Name'
              slotProps={{ inputLabel: { shrink: true } }}
              variant='outlined'
              value={partner['name']}
              onChange={e =>
                setPartner(prev => ({ ...prev, name: e.target.value }))
              }
            />
            <TextField
              disabled
              id={'update-client-id'}
              label='Client Id'
              slotProps={{ inputLabel: { shrink: true } }}
              variant='outlined'
              value={partner['client_id']}
            />
            <TextField
              disabled
              id={'update-client-secret'}
              label='Client Secret'
              slotProps={{ inputLabel: { shrink: true } }}
              variant='outlined'
              value={partner['client_secret']}
            />
          </Stack>
          <Grid container alignItems='center' spacing={1} marginTop={2}>
            <Grid item style={{ color: 'rgba(0, 0, 0, 0.4)' }}>
              Need Pickup
            </Grid>
            <Grid item>
              <Switch
                checked={Boolean(partner['need_pickup'])}
                onChange={handlePickupStatus}
              />
            </Grid>
            <Grid item>Pickup</Grid>
          </Grid>
          <Grid container alignItems='center' spacing={1}>
            <Grid item style={{ color: 'rgba(0, 0, 0, 0.4)' }}>
              Partner Status
            </Grid>
            <Grid item>
              <Switch
                checked={Boolean(partner['is_active'])}
                onChange={handleActiveStatus}
              />
            </Grid>
            <Grid item>Active</Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button autoFocus onClick={() => setUpdatePartnerOpen(false)}>
            Cancel
          </Button>
          <Button variant='contained' onClick={handleUpdatePartner}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
