import { setUserPreference } from '../utilities/common.js'
import { USER_LOGIN, USER_LOGIN_SUCCESS, USER_LOGIN_FAIL } from './types.js'
import {
  shangjiaServerInstance,
  shangjiaServerInstanceDev,
  createOrderServerInstance,
  createOrderServerInstanceDev,
  UniDWServerInstance
} from '../utilities/instances.js'
import { execute } from '../utilities/executor.js'

export const prmLogin = (username, password) => dispatch =>
  execute(dispatch)(
    shangjiaServerInstance.post(`/storeauth/prmlogin`, {
      username: username,
      password: password
    }),
    {
      type: USER_LOGIN,
      success: USER_LOGIN_SUCCESS,
      fail: USER_LOGIN_FAIL
    },
    {
      doIfSuccess: data => {
        setUserPreference(data.data.token)
        window.localStorage.setItem('client_id', data.data.client_id)
        window.localStorage.setItem('client_secret', data.data.client_secret)
        window.localStorage.setItem('warehouse', '1') // TODO
        window.localStorage.setItem(
          'customer_info',
          JSON.stringify(data.data.managed_customers_data)
        )
        shangjiaServerInstance.defaults.headers.common['Authorization'] =
          `Bearer ${data.data.token}`
        shangjiaServerInstanceDev.defaults.headers.common['Authorization'] =
          `Bearer ${data.data.token}`
        createOrderServerInstanceDev.defaults.headers.common['Authorization'] =
          `Bearer ${data.data.token}`
        createOrderServerInstance.defaults.headers.common['Authorization'] =
          `Bearer ${data.data.token}`
        UniDWServerInstance.defaults.headers.common['Authorization'] =
          `Bearer ${data.data.token}`
      }
    }
  )

export function userLogout() {
  window.localStorage.clear()
  window.location.reload()
}
