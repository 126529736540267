import {
  Card,
  Box,
  Stack,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  CircularProgress
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useState, useRef, memo } from 'react'
import { useDispatch } from 'react-redux'
import { getOrderPrintLabel } from '../../actions/storeActions'
import DataGridContainer from '../../components/DataGridContainer'
import ReactToPrint from 'react-to-print'
import * as XLSX from 'xlsx'

const localDeliveryColumns = [
  {
    field: 'extra_order_sn',
    headerName: 'Tracking Number',
    width: 200
  },
  {
    field: 'referer',
    headerName: 'Batch Number',
    width: 200
  },
  {
    field: 'internal_account_number',
    headerName: 'Reference #',
    width: 200
  },
  {
    field: 'pathInfo',
    headerName: 'Status',
    width: 250
  },
  {
    field: 'consignee',
    headerName: 'Consignee',
    width: 200
  },
  {
    field: 'address',
    headerName: 'Address',
    width: 400
  },
  {
    field: 'city',
    headerName: 'City',
    width: 100
  },
  {
    field: 'mobile',
    headerName: 'Phone #',
    width: 143
  },
  {
    field: 'goods_type',
    headerName: 'Parcel Category',
    width: 100
  },
  {
    field: 'add_time',
    headerName: 'Upload Time',
    width: 192
  }
]

const LocalDeliveryDisplay = props => {
  const componentRef = useRef()
  const dispatch = useDispatch()
  const { ordersRes, page, rows, setPage, setRows, handleSearch } = props
  const [selectedTnos, setSelectedTnos] = useState([])
  const [orderLabels, setOrderLabels] = useState([])
  const [labelPopup, setLabelsOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadedCount, setLoadedCount] = useState(0)
  const [errorMessage, setErrorMessage] = useState('')
  const [failOpen, setFailOpen] = useState(false)

  function createDeliveryOrders() {
    const orders = []
    if (ordersRes.data.length > 0) {
      ordersRes.data.forEach(row => {
        const { extra_order_sn, ...rest } = row
        orders.push({ id: extra_order_sn, extra_order_sn, ...rest })
      })
    }
    return orders
  }

  function downloadOrders(data) {
    const orders = data.map(
      ({
        internal_account_number,
        extra_order_sn,
        pathInfo,
        referer,
        add_time,
        consignee,
        address,
        mobile,
        city,
        province,
        goods_type,
        ...rest
      }) => ({
        'Internal Reference Number': internal_account_number,
        'Tracking Number': extra_order_sn,
        'Order Status': pathInfo,
        'Batch Number': referer,
        'Create Time': add_time,
        Consignee: consignee,
        Address: address,
        Mobile: mobile,
        City: city,
        Province: province,
        'Parcel Category': goods_type
      })
    )
    orders.reverse()

    let workbook = XLSX.utils.book_new()
    let workSheet = XLSX.utils.json_to_sheet(orders)
    workbook.SheetNames.push('orders')
    workbook.Sheets['orders'] = workSheet
    XLSX.writeFile(workbook, 'Orders.xlsx')
  }

  async function produceLabels(orderTnos) {
    setLoading(true)

    // batch tnos into groups of 10
    const tnos = Array.from(orderTnos)
    const batches = []
    while (tnos.length > 0) {
      batches.push(tnos.splice(0, 10))
    }

    // send requests in batches
    try {
      const orderLabels = []
      for (const batch of batches) {
        const labels = await Promise.all(
          batch.map(tno => dispatch(getOrderPrintLabel(tno)))
        )
        const images = labels.map(res => {
          const base64 = res.data[0].labelContent
          const url = 'data:image/png;base64,' + base64
          return url
        })
        orderLabels.push(...images)
        setLoadedCount(count => count + batch.length)
      }
      setOrderLabels(orderLabels)
      setLabelsOpen(true)
    } catch (e) {
      setErrorMessage(e.message)
      setFailOpen(true)
    } finally {
      setLoading(false)
      setLoadedCount(0)
    }
  }

  const ProgressIndicator = ({ totalCount }) => (
    <>
      {loadedCount > 0 && (
        <Typography variant='button' color='secondary' marginLeft={1}>
          {Math.ceil((loadedCount / totalCount) * 100)}%
        </Typography>
      )}
      <CircularProgress size={18} color='secondary' sx={{ marginLeft: 1 }} />
    </>
  )

  return (
    <Card variant='outlined'>
      <Box display='flex' padding={2}>
        <Typography variant='subtitle2' flex={1}>
          Results
        </Typography>
        <Stack direction='row' spacing={2}>
          <Button
            onClick={() =>
              downloadOrders(
                ordersRes.data.filter(row =>
                  selectedTnos.includes(row.extra_order_sn)
                )
              )
            }
            disabled={selectedTnos.length === 0}
            variant='outlined'
            color='secondary'
            size='small'>
            Download Orders{' '}
            {selectedTnos.length ? `(${selectedTnos.length})` : ''}
          </Button>
          <Button
            onClick={() => produceLabels(selectedTnos)}
            disabled={selectedTnos.length === 0 || loading}
            variant='contained'
            color='secondary'
            size='small'>
            {loading ? 'Producing' : 'Produce'} Labels{' '}
            {selectedTnos.length ? `(${selectedTnos.length})` : ''}
            {loading && <ProgressIndicator totalCount={selectedTnos.length} />}
          </Button>
        </Stack>
      </Box>

      <DataGridContainer>
        <DataGrid
          rows={createDeliveryOrders() || []}
          columns={localDeliveryColumns}
          checkboxSelection
          onRowSelectionModelChange={setSelectedTnos}
          selectionModel={selectedTnos}
          disableSelectionOnClick
          disableRowSelectionOnClick
          rowCount={ordersRes.total}
          loading={loading}
          pageSizeOptions={[10, 25, { value: -1, label: 'All' }]}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10
              }
            }
          }}
          pagination
          paginationMode='server'
          paginationModel={{
            page: page - 1,
            pageSize: rows
          }}
          onPaginationModelChange={model => {
            const page = model.page + 1
            const rows = model.pageSize
            setPage(page)
            setRows(rows)
            handleSearch(rows, page)
          }}
          getCellClassName={params => {
            return params.field
          }}
        />
      </DataGridContainer>

      <Dialog
        maxWidth='sm'
        fullWidth={true}
        open={labelPopup}
        onClose={() => setLabelsOpen(false)}>
        <DialogTitle>
          <span>Labels</span>
          <Stack direction='row' spacing={1} sx={{ float: 'right' }}>
            <Button autoFocus onClick={() => setLabelsOpen(false)}>
              Cancel
            </Button>
            <ReactToPrint
              pageStyle='@page { size: 4in 6in }'
              trigger={() => (
                <Button
                  variant='contained'
                  disabled={!(orderLabels.length > 0)}>
                  Print
                </Button>
              )}
              content={() => componentRef.current}
            />
          </Stack>
        </DialogTitle>
        <DialogContent dividers>
          <div ref={componentRef}>
            {orderLabels.length > 0 &&
              orderLabels.map((label, index) => {
                return (
                  <img
                    width='384px'
                    height='568px'
                    // this ratio is important for browser print to fit image in one page (tested in chrome and edge)
                    src={label}
                    alt=''
                    key={index}
                  />
                )
              })}
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        maxWidth='sm'
        fullWidth={true}
        open={failOpen}
        onClose={() => setFailOpen(false)}>
        <DialogTitle>Failed</DialogTitle>
        <DialogContent dividers>
          <br />
          <Typography>{errorMessage}</Typography>
          <br />
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button autoFocus onClick={() => setFailOpen(false)}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
}

export default memo(LocalDeliveryDisplay)
