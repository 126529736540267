import { createContext, useMemo } from 'react'
import { parseJwt } from '../utilities/common'
import { menuItems } from '../menu'

export const TokenContext = createContext()

export const TokenContextProvider = ({ children }) => {
  const token = window.localStorage.getItem('auth_token')

  const contextValue = useMemo(() => {
    const contents = token ? parseJwt(token) : {}
    const roles = contents.roles || []
    const customers = contents.customer || []
    const authorizedAbilities = contents.ui_abilities || []
    const authorizedMenuItems = menuItems.filter(item =>
      authorizedAbilities.includes(item.access_ability_id)
    )
    const authorizedRoutes = authorizedMenuItems.reduce((accumulator, item) => {
      if (item.subitems)
        accumulator.push(...item.subitems.map(subitem => '/' + subitem.id))
      else accumulator.push('/' + item.id)
      return accumulator
    }, [])

    // add home item and route
    authorizedMenuItems.unshift(menuItems[0])
    authorizedRoutes.unshift('/' + menuItems[0].id)

    // remove partner management page if user is not system admin
    if (!roles.includes('System Administrator')) {
      const organizationIndex = authorizedMenuItems.findIndex(
        item => item.id === 'organization'
      )
      if (organizationIndex > -1) {
        const subitems = authorizedMenuItems[organizationIndex].subitems
        const subitemIndex = subitems.findIndex(
          item => item.id === 'organization-partner'
        )
        if (subitemIndex > -1) subitems.splice(subitemIndex, 1)
        const routeIndex = authorizedRoutes.findIndex(
          route => route === '/organization-partner'
        )
        if (routeIndex > -1) authorizedRoutes.splice(routeIndex, 1)
      }
    }

    return {
      token,
      roles,
      customers,
      authorizedMenuItems,
      authorizedRoutes
    }
  }, [token])

  return (
    <TokenContext.Provider value={contextValue}>
      {children}
    </TokenContext.Provider>
  )
}
