import { HANDLE_ROW_VALUES } from '../actions/types'

const initialState = {
  rowValues: {}
}

export default function deliveryAreaReducer(state = initialState, action) {
  switch (action.type) {
    case HANDLE_ROW_VALUES:
      return {
        ...state,
        rowValues: action.payload
      }
    default:
      return state
  }
}
