import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Link,
  Typography
} from '@mui/material'
import { prmLogin } from '../../actions/userActions'
import { showAlertSnackbar } from '../../reducers/sliceReducer'
import { useAlert } from '../../utilities/hooks'
import PersonIcon from '@mui/icons-material/Person'
import LockIcon from '@mui/icons-material/Lock'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import FullscreenLoading from '../../components/FullscreenLoading'

export default function Login() {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  // const [checked, setChecked] = useState(false);
  // const [language, setLanguage] = useState('en');
  const [showPassword, setShowPassword] = useState(false)

  const dispatch = useDispatch()
  const history = useHistory()
  const alertDialog = useAlert()

  const user = useSelector(state => state.user)
  const common = useSelector(state => state.common)

  // const languageList = [
  //   {
  //     name: '中文',
  //     type: 'cn'
  //   },
  //   {
  //     name: 'English',
  //     type: 'en'
  //   }
  // ]

  const handleLogin = async event => {
    event.preventDefault()
    if (username.length === 0 || password.length === 0) {
      dispatch(
        showAlertSnackbar({ title: 'Username or password cannot be empty' })
      )
      return
    }
    try {
      await dispatch(prmLogin(username, password))
      window.localStorage.setItem('username', username)
      history.push('/main')
    } catch (e) {
      alertDialog.addAlertDialog('LOGIN', e.message)
      alertDialog.showAlert()
    }
  }

  return (
    <Box display='flex' height='100vh'>
      <Box
        component='img'
        src={'./images/login_img.png'}
        alt=''
        sx={{
          display: { xs: 'none', sm: 'none', md: 'block' },
          height: '100vh',
          width: '42.7%',
          backgroundPosition: 'left',
          backgroundSize: 'auto',
          backgroundRepeat: 'no-repeat'
        }}
      />
      <Box
        component='img'
        src={'./images/icons/icon_white.png'}
        alt=''
        sx={{
          display: { xs: 'none', sm: 'none', md: 'block' },
          position: 'absolute',
          width: '170px',
          height: '40px',
          top: '2.3vh',
          left: '2vh'
        }}
      />
      <Box
        flex={1}
        overflow='auto'
        padding='24px'
        display='flex'
        flexDirection='column'
        alignItems='center'>
        <Box
          maxWidth='sm'
          display='flex'
          alignItems='center'
          justifyContent='center'>
          <Box onSubmit={handleLogin} component='form' flexDirection='column'>
            <Box
              component='img'
              src={'./images/icons/icon.png'}
              alt=''
              sx={{
                display: { xs: 'block', sm: 'block', md: 'none' },
                width: '170px',
                height: '40px',
                marginBottom: '24px'
              }}
            />
            <Link
              href='https://docs.uniuni.com/'
              target='_blank'
              sx={{
                color: '#ff9800',
                textDecorationColor: '#ff9800',
                fontWeight: 600,
                display: 'flex',
                alignItems: 'center'
              }}>
              UniUni Integration API Docs
              <ArrowForwardIcon
                fontSize='small'
                sx={{
                  marginLeft: '6px'
                }}
              />
            </Link>
            <Typography variant='h4' sx={{ marginTop: 5 }}>
              {'Welcome to'}
            </Typography>
            <Typography
              variant='h3'
              sx={{ color: '#577699', fontWeight: 600, marginY: 1 }}>
              {'UniUni PRM System'}
            </Typography>
            <Typography
              variant='h6'
              sx={{ color: '#577699', fontWeight: 300, marginBottom: 5 }}>
              {'Collaboration · Management · Analysis'}
            </Typography>
            <Box
              style={{
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
                marginBottom: 25,
                fontSize: 32,
                fontWeight: 600
              }}>
              <div>{'Sign In'}</div>
            </Box>
            <TextField
              value={username}
              onChange={e => setUsername(e.target.value)}
              label={'Account Name'}
              variant='outlined'
              fullWidth
              margin='normal'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <PersonIcon />
                  </InputAdornment>
                )
              }}
            />
            <TextField
              value={password}
              onChange={e => setPassword(e.target.value)}
              type={showPassword ? 'text' : 'password'}
              label={'Password'}
              variant='outlined'
              fullWidth
              margin='normal'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <LockIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword(!showPassword)}
                      edge='end'>
                      {showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            {/* <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: 14 }}>
                <FormGroup>
                  <FormControlLabel control={<Checkbox onChange={e => setChecked(e.target.checked)} />} label='Remember me' />
                </FormGroup>
                <Button style={{textTransform: 'none', color: '#1890ff'}}>{'Forget Password?'}</Button>
              </Box> */}
            <Button
              type='submit'
              variant='contained'
              sx={{ marginTop: 5 }}
              size='large'
              fullWidth>
              {'Sign In'}
            </Button>
            <Typography
              sx={{
                color: '#333',
                textAlign: 'center',
                fontSize: '16px',
                marginTop: 4
              }}>
              © UniUni {new Date().getFullYear()}. All rights reserved.
            </Typography>
          </Box>
        </Box>
      </Box>
      <FullscreenLoading open={user.loading || common.loading} />
    </Box>
  )
}
