import { useDispatch } from 'react-redux'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Card,
  Typography,
  Stack
} from '@mui/material'
import { useEffect, useState, useCallback } from 'react'
import {
  downloadWrongAreaOrders,
  getWrongAreaStatistic
} from '../../actions/prealertStatisticActions'
import { DataGrid } from '@mui/x-data-grid'
import { showAlertSnackbar } from '../../reducers/sliceReducer'
import dayjs from 'dayjs'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import DownloadIcon from '@mui/icons-material/FileDownloadOutlined'
import FullscreenLoading from '../../components/FullscreenLoading'
import DataGridContainer from '../../components/DataGridContainer'

const initialDate = dayjs().subtract(1, 'days').format('YYYY-MM-DD')
const initialPartner = '75'

function PrealertWrongAreaKeyword() {
  const dispatch = useDispatch()
  const [date, setDate] = useState(initialDate)
  const [partner, setPartner] = useState(initialPartner)
  const [time, setTime] = useState('import')
  const [keyword, setKeyword] = useState()
  const [type, setType] = useState('mawb')
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)

  const partnerList = [
    {
      name: 'GV',
      type: '75'
    },
    {
      name: 'BroadReach',
      type: '158'
    }
  ]

  const timeList = [
    {
      name: 'Import Time',
      type: 'import'
    }
  ]

  const typeList = [
    {
      name: 'MAWB',
      type: 'mawb'
    }
  ]

  const columns = [
    {
      field: 'batch',
      headerName: 'Batch',
      width: 200
    },
    {
      field: 'wrong_orders_count',
      headerName: 'Count',
      width: 100
    },
    {
      field: 'wrong_orders',
      headerName: 'Wrong Orders',
      width: 600
    },
    {
      field: 'err_msg',
      headerName: 'Error Message',
      width: 300
    }
  ]

  const handleKeyword = event => {
    setKeyword(event.target.value)
  }

  const handleUpdateRow = res => {
    const updatedRows = res.map((row, i) => ({
      id: i,
      batch: row.batch,
      wrong_orders: JSON.stringify(row.wrong_orders),
      err_msg: row.err_msg
    }))
    setRows(updatedRows)
  }

  const handleSearch = useCallback(
    async (date, partner) => {
      setLoading(true)
      try {
        const res = await dispatch(getWrongAreaStatistic(date, partner)).catch(
          e => {
            throw e.message
          }
        )
        handleUpdateRow(res)
        dispatch(
          showAlertSnackbar({ message: 'Fetch Data Success', type: 'success' })
        )
      } catch (e) {
        dispatch(showAlertSnackbar({ message: e, type: 'error' }))
      } finally {
        setLoading(false)
      }
    },
    [dispatch, setLoading]
  )

  async function handleDownload() {
    try {
      setLoading(true)
      let response = await downloadWrongAreaOrders(keyword)
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `Wrong-Area.csv`)
      document.body.appendChild(link)
      link.click()
      link.remove()
    } catch (e) {
      dispatch(showAlertSnackbar({ message: 'Download Failed', type: 'error' }))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleSearch(initialDate, initialPartner)
  }, [handleSearch])

  return (
    <div>
      <Typography variant='subtitle1' marginBottom={2}>
        Wrong Dest. Airport
      </Typography>
      <Card variant='outlined' sx={{ marginBottom: 2 }}>
        <Stack
          direction='row'
          alignItems='center'
          spacing={2}
          useFlexGap
          flexWrap='wrap'
          sx={{ padding: 3 }}>
          <FormControl variant='outlined' size='small'>
            <InputLabel>Partner</InputLabel>
            <Select
              value={partner}
              label={'Partner'}
              onChange={e => {
                setPartner(e.target.value)
              }}>
              {partnerList.map(partner => (
                <MenuItem value={partner.type} key={partner.type}>
                  {partner.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant='outlined' size='small'>
            <Select value={time} onChange={e => setTime(e.target.value)}>
              {timeList.map(time => (
                <MenuItem value={time.type} key={time.type}>
                  {time.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            type='date'
            value={date}
            onChange={e => setDate(e.target.value)}
            variant='outlined'
            size='small'
          />
          <IconButton
            color={'secondary'}
            onClick={() => handleSearch(date, partner)}>
            <SearchIcon />
          </IconButton>
          <FormControl variant='outlined' size='small'>
            <Select value={type} onChange={e => setType(e.target.value)}>
              {typeList.map(type => (
                <MenuItem value={type.type} key={type.type}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            onChange={handleKeyword}
            value={keyword}
            variant='outlined'
            size='small'
          />
          <IconButton color={'primary'} onClick={handleDownload}>
            <DownloadIcon />
          </IconButton>
        </Stack>
      </Card>
      <Card variant='outlined'>
        <DataGridContainer>
          <DataGrid
            rows={rows}
            columns={columns}
            disableColumnFilter
            disableColumnMenu
            disableSelectionOnClick
          />
        </DataGridContainer>
      </Card>
      <FullscreenLoading open={loading} />
    </div>
  )
}

export default PrealertWrongAreaKeyword
