import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PostalOutletOrderSearch from './PostalOutletOrderSearch'
import PostalOutletOrderDisplay from './PostalOutletOrderDisplay'
import { getOrdersByCustomer } from '../../actions/storeActions'
import { showAlertSnackbar } from '../../reducers/sliceReducer'
import { useCustomer } from '../../utilities/hooks'

function PostalOutletOrders() {
  const dispatch = useDispatch()

  const { customerData } = useCustomer()
  const [loading, setLoading] = useState(false)
  const [orderList, setOrderList] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [paging, setPaging] = useState({ totalCount: 0 })

  const [trackingNo, setTrackingNo] = useState('')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')

  function fetchPostalOutletOrders() {
    setLoading(true)
    dispatch(
      getOrdersByCustomer(
        customerData.id,
        fromDate,
        toDate,
        trackingNo,
        currentPage + 1,
        perPage
      )
    )
      .then(([data, paging]) => {
        setLoading(true)
        setOrderList(data)
        setPaging(paging)
        setLoading(false)
      })
      .catch(e => {
        dispatch(
          showAlertSnackbar({ message: 'Fetch orders failed', type: 'error' })
        )
        setLoading(false)
      })
  }

  return (
    <div>
      <PostalOutletOrderSearch
        trackingNo={trackingNo}
        setTrackingNo={setTrackingNo}
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        setToDate={setToDate}
        handleSearch={fetchPostalOutletOrders}
      />
      <PostalOutletOrderDisplay
        orderList={orderList}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPage={perPage}
        setPerPage={setPerPage}
        paging={paging}
        loading={loading}
        handleSearch={fetchPostalOutletOrders}
      />
    </div>
  )
}

export default PostalOutletOrders
