export function setUserPreference(authToken) {
  window.localStorage.setItem('auth_token', authToken)
}

export function getUserPreference() {
  return {
    authToken: window.localStorage.getItem('auth_token')
  }
}

export function getUserManagedCustomers() {
  const customers = window.localStorage.getItem('customer_info')

  return JSON.parse(customers)
}

export function parseJwt(token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .toString()
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

export function exportHelper(response, fileName) {
  const url = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  link.remove()
}
