import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2 as Grid,
  Switch,
  TextField,
  FormLabel,
  FormControlLabel
} from '@mui/material'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { showAlertSnackbar } from '../../reducers/sliceReducer'
import { useAlert } from '../../utilities/hooks'
import { createUser } from '../../actions/userManagementActions'

const INIT_USER = {
  username: '',
  password: '',
  email: '',
  partner_id: '',
  customer: '',
  is_active: 1
}

const ALERT = {
  SEARCH_SUCCESS: 'Fetch Data Success',
  CREATE_SUCCESS: 'CREATE USER SUCCESS',
  CREATE_FAILED: 'CREATE USER FAILED'
}

function CreateUser(props) {
  const { open, setOpen } = props

  const dispatch = useDispatch()
  const alertDialog = useAlert()

  const [user, setUser] = useState(INIT_USER)

  const handleActiveStatus = e => {
    let status = user['is_active'] ? 0 : 1
    setUser(e => setUser(prev => ({ ...prev, is_active: status })))
  }

  const handleCreateUser = async event => {
    event.preventDefault()
    try {
      const params = Object.fromEntries(
        Object.entries(user).map(([k, v]) => [k, v === null ? '' : v])
      )
      await dispatch(createUser(params))
      dispatch(
        showAlertSnackbar({ title: ALERT.CREATE_SUCCESS, type: 'success' })
      )
    } catch (e) {
      alertDialog.addAlertDialog(ALERT.CREATE_FAILED, e.message)
      alertDialog.showAlert()
    }
  }

  return (
    <Dialog maxWidth='sm' open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Create User Account</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid size={6}>
            <TextField
              label='User Name'
              slotProps={{ inputLabel: { shrink: true } }}
              variant='outlined'
              onChange={e =>
                setUser(prev => ({ ...prev, username: e.target.value }))
              }
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <TextField
              label='Password'
              slotProps={{ inputLabel: { shrink: true } }}
              variant='outlined'
              onChange={e =>
                setUser(prev => ({ ...prev, password: e.target.value }))
              }
              fullWidth
            />
          </Grid>
          <Grid size={12}>
            <TextField
              id={'dialog_email'}
              label='Email'
              slotProps={{ inputLabel: { shrink: true } }}
              variant='outlined'
              onChange={e =>
                setUser(prev => ({ ...prev, email: e.target.value }))
              }
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <TextField
              id={'dialog_customer'}
              label='Customer'
              slotProps={{ inputLabel: { shrink: true } }}
              variant='outlined'
              onChange={e =>
                setUser(prev => ({ ...prev, customer: e.target.value }))
              }
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <TextField
              id={'dialog_partner_id'}
              label='Partner Id'
              slotProps={{ inputLabel: { shrink: true } }}
              variant='outlined'
              onChange={e =>
                setUser(prev => ({ ...prev, partner_id: e.target.value }))
              }
              fullWidth
            />
          </Grid>
          <Grid size={12}>
            <FormLabel>Customer Status</FormLabel>
            <FormControlLabel
              control={
                <Switch
                  checked={Boolean(user['is_active'])}
                  onChange={handleActiveStatus}
                />
              }
              label='Active'
              sx={{ marginLeft: 1 }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ padding: 3 }}>
        <Button autoFocus onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button variant='contained' onClick={handleCreateUser}>
          create
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateUser
