import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { getLanguagePref } from './utilities/language'
import commonEN from './i18n/en/common.json'
import commonCN from './i18n/cn/common.json'
import localDeliveryEN from './i18n/en/localDelivery.json'
import localDeliveryCN from './i18n/cn/localDelivery.json'
import servicePointEN from './i18n/en/servicePoint.json'
import servicePointCN from './i18n/cn/servicePoint.json'
import servicePointManagementEN from './i18n/en/servicePointManagement.json'
import servicePointManagementCN from './i18n/cn/servicePointManagement.json'
import servicePointStatisticsEN from './i18n/en/servicePointStatistics.json'
import servicePointStatisticsCN from './i18n/cn/servicePointStatistics.json'
import sandboxEN from './i18n/en/sandbox.json'
import sandboxCN from './i18n/cn/sandbox.json'
import postalOutletEN from './i18n/en/postalOutlet.json'
import userManagementCN from './i18n/cn/userManagement.json'
import userManagementEN from './i18n/en/userManagement.json'
import orderReplenishment from './i18n/en/orderReplenishment.json'
import billingManagement from './i18n/en/billingManagement.json'

const resources = {
  en: {
    common: commonEN,
    localDelivery: localDeliveryEN,
    servicePoint: servicePointEN,
    servicePointManagement: servicePointManagementEN,
    servicePointStatistics: servicePointStatisticsEN,
    sandbox: sandboxEN,
    postalOutlet: postalOutletEN,
    userManagement: userManagementEN,
    orderReplenishment: orderReplenishment,
    billingManagement: billingManagement
  },
  cn: {
    common: commonCN,
    localDelivery: localDeliveryCN,
    servicePoint: servicePointCN,
    servicePointManagement: servicePointManagementCN,
    servicePointStatistics: servicePointStatisticsCN,
    sandbox: sandboxCN,
    userManagement: userManagementCN,
    orderReplenishment: orderReplenishment,
    billingManagement: billingManagement
  }
}

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'en',
  lng: getLanguagePref(),
  interpolation: {
    escapeValue: false // react already safes from xss
  }
})

export default i18n
