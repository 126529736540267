import { Paper } from '@mui/material'
import UploadFileIcon from '@mui/icons-material/UploadFile'

function DocumentDetailDisplay({ file, state }) {
  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }
  return (
    <Paper
      variant='outlined'
      style={{
        padding: 20,
        cursor: 'pointer',
        background: '#fafafa',
        display: 'flex',
        flexDirection: 'row'
      }}>
      <UploadFileIcon
        style={{ fontSize: 30, color: 'rgba(0, 0, 0, 0.6)', margin: 10 }}
      />
      <div style={{ margin: 10 }}>
        {file.name}
        <div style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
          {formatBytes(file.size)} &bull;{' ' + state}
        </div>
      </div>
    </Paper>
  )
}

export default DocumentDetailDisplay
