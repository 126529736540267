import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Divider,
  List,
  ListItemButton,
  ListItem,
  ListItemText,
  Checkbox,
  ListItemIcon,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material'
import {
  getUserListByRole,
  createNewRole,
  deleteRole,
  updateRole,
  updateRoleAbilities
} from '../../actions/userManagementActions'

export default function Roles(props) {
  const {
    rolesList,
    abilitiesList,
    usersList,
    fetchData,
    setLoading,
    showSnack
  } = props
  const dispatch = useDispatch()
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [selectedRoleName, setSelectedRoleName] = useState('')
  const [selectedRoleAbilities, setSelectedRoleAbilities] = useState([])
  const [selectedRoleUsers, setSelectedRoleUsers] = useState([])
  const [deleteOpen, setDeleteOpen] = useState(false)
  const { t } = useTranslation('userManagement')

  const handleDeleteOpen = () => {
    setDeleteOpen(true)
  }
  const handleDeleteClose = () => {
    setDeleteOpen(false)
  }

  const handleRoleListClick = async (role, index) => {
    setSelectedIndex(index)
    setSelectedRoleName(role.name || '')
    setSelectedRoleAbilities(role.abilities.map(ability => ability.ability_id))

    try {
      setLoading(true)
      const res = await dispatch(getUserListByRole(role.id))
      setSelectedRoleUsers(Object.entries(res).map(user => user[1].id))
    } catch (error) {
      showSnack('error')('Network Error')
    } finally {
      setLoading(false)
    }
  }

  const handleAbilityListClick = id => {
    if (selectedRoleAbilities.indexOf(id) === -1) {
      setSelectedRoleAbilities([...selectedRoleAbilities, id])
    } else {
      setSelectedRoleAbilities(
        selectedRoleAbilities.filter(ability => ability !== id)
      )
    }
  }

  const handleCreateRoleClick = async name => {
    try {
      setLoading(true)

      await Promise.all([dispatch(createNewRole(name))])

      showSnack('success')('Create new role success!')
    } catch (error) {
      showSnack('error')('Create new role failed.')
    } finally {
      await fetchData()
      setLoading(false)
    }
  }

  const handleSaveRoleClick = async (id, name) => {
    try {
      setLoading(true)

      await Promise.all([
        dispatch(updateRole(id, name)),
        dispatch(updateRoleAbilities(id, selectedRoleAbilities.toString()))
      ])

      showSnack('success')(t('save_role_setting_success'))
    } catch (error) {
      showSnack('error')(t('save_role_setting_failed'))
    } finally {
      await fetchData()
      setLoading(false)
    }
  }

  const handleDeleteRole = async roleId => {
    try {
      setLoading(true)

      await Promise.all([dispatch(deleteRole(roleId))])

      showSnack('success')(t('delete_role_success'))
    } catch (error) {
      showSnack('error')(t('delete_role_failed'))
    } finally {
      await fetchData()
      setDeleteOpen(false)
      setLoading(false)
    }
  }

  return (
    <>
      <Dialog open={deleteOpen} onClose={handleDeleteClose}>
        <DialogContent>
          <DialogContentText>{t('sure_to_delete_role')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            id={'cancel-role-delete'}
            onClick={handleDeleteClose}
            color='primary'>
            {t('cancel')}
          </Button>
          <Button
            id={'confirm-role-delete'}
            onClick={() => handleDeleteRole(rolesList[selectedIndex].id)}
            color='primary'
            autoFocus>
            {t('ok')}
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        display='flex'
        flexDirection='row'
        flex='1 0 auto'
        height='calc(100vh - 190px)'>
        <Box width='20%' overflow='auto'>
          <List>
            {rolesList &&
              rolesList.map((role, index) => (
                <ListItemButton
                  key={role.id}
                  selected={selectedIndex === index}
                  sx={{ paddingY: 0 }}
                  onClick={() => {
                    handleRoleListClick(role, index)
                  }}>
                  <ListItemText
                    id={'role-select-' + role.id}
                    primary={role.name}
                  />
                </ListItemButton>
              ))}
          </List>
        </Box>
        <Divider orientation='vertical' flexItem />
        <Box width='60%' display='flex' flexDirection='column'>
          <Box padding={2}>
            <TextField
              id={'role-name-text'}
              variant='outlined'
              size='small'
              value={selectedRoleName}
              label={t('name')}
              onChange={e => {
                setSelectedRoleName(e.target.value)
              }}
              fullWidth
            />
          </Box>
          <Divider />
          <List sx={{ flex: 1, overflow: 'auto' }}>
            {abilitiesList
              .filter(ability => ability.type === 'ui')
              .map(ability => (
                <ListItem key={ability.id} sx={{ paddingY: 0 }}>
                  <ListItemIcon>
                    <Checkbox
                      id={'ability-checkbox-' + ability.id}
                      checked={selectedRoleAbilities.indexOf(ability.id) !== -1}
                      onClick={() => {
                        handleAbilityListClick(ability.id)
                      }}
                      tabIndex={-1}
                    />
                  </ListItemIcon>
                  <ListItemText primary={`${ability.id} - ${ability.title}`} />
                </ListItem>
              ))}
          </List>
          <Divider />
          <Box display='flex' flexDirection='row' padding={2}>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => handleCreateRoleClick(selectedRoleName)}
              disabled={!selectedRoleName}>
              {t('create_role')}
            </Button>
            <Button
              variant='contained'
              color='error'
              sx={{ marginLeft: 'auto', marginRight: 1 }}
              onClick={handleDeleteOpen}>
              {t('delete_role')}
            </Button>
            <Button
              variant='contained'
              onClick={() => {
                handleSaveRoleClick(
                  rolesList[selectedIndex].id,
                  selectedRoleName
                )
              }}>
              {t('save_change')}
            </Button>
          </Box>
        </Box>
        <Divider orientation='vertical' flexItem />
        <Box width='20%' overflow='auto'>
          <List>
            {usersList
              .filter(user => selectedRoleUsers.indexOf(user.id) !== -1)
              .map(user => (
                <ListItem key={user.id} sx={{ paddingY: 0 }}>
                  <ListItemText primary={user.username} />
                </ListItem>
              ))}
          </List>
        </Box>
      </Box>
    </>
  )
}
