import {
  Button,
  IconButton,
  TextField,
  Typography,
  Card,
  Box,
  Stack
} from '@mui/material'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { InputAdornment, Link } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { showAlertSnackbar } from '../../reducers/sliceReducer'
import { addMissingGVOrders } from '../../actions/gvOrderManagementActions'
import ClearIcon from '@mui/icons-material/Clear'
import DataGridContainer from '../../components/DataGridContainer'

export default function OrderReplenishment() {
  const dispatch = useDispatch()
  const { t } = useTranslation('orderReplenishment')
  const [trackingNos, setTrackingNos] = useState('')
  const [mawb, setmawb] = useState('')
  const [loading, setLoading] = useState(false)
  const [addOrderResult, setAddOrderResult] = useState([])

  const ordersAddedColumns = [
    {
      field: 'tno',
      headerName: 'Tracking No.',
      width: 240
    },
    {
      field: 'mawb',
      headerName: 'Batch No.',
      width: 200
    },
    {
      field: 'port',
      headerName: 'Port',
      width: 120
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120
    },
    {
      field: 'failed_reason',
      headerName: 'Failed Reason',
      width: 320
    }
  ]

  const orderReplenishmentFooter = () => {
    return addOrderResult.length > 0 ? (
      <Box margin={2}>
        <span display='inline'>{t('orders_added_prompt_part1')}</span>
        <Link href='#' display='inline'>
          {t('orders_added_failed_import')}
        </Link>
        <span display='inline'>{t('orders_added_prompt_part2')}</span>
      </Box>
    ) : null
  }

  const noOrdersAddedPrompt = () => {
    return (
      <Box margin={2} textAlign='center'>
        <Typography>{t('no_orders_added_prompt')}</Typography>
      </Box>
    )
  }

  async function addMissingOrders() {
    setLoading(true)

    // validate number of tno is less than 10;
    let tempArr = []
    if (trackingNos.includes(',')) {
      tempArr = trackingNos
        .trim()
        .split(',')
        .map(i => i.trim())
    } else {
      tempArr = trackingNos
        .trim()
        .split(/\s+/)
        .map(i => i.trim())
    }
    if (!trackingNos || tempArr.length === 0) {
      dispatch(
        showAlertSnackbar({
          message: 'Please provide a tracking number',
          type: 'error'
        })
      )
    } else if (tempArr.length > 10) {
      dispatch(
        showAlertSnackbar({
          message: 'More than 10 tracking numbers provided',
          type: 'error'
        })
      )
    } else {
      await dispatch(addMissingGVOrders({ tno: tempArr.join(), mawb: mawb }))
        .then(res => {
          setAddOrderResult(res)
        })
        .catch(err => {
          dispatch(showAlertSnackbar({ message: err.message, type: 'error' }))
        })
    }

    setLoading(false)
  }

  return (
    <div>
      <Typography variant='subtitle1' marginBottom={2}>
        {t('order_replenishment')}
      </Typography>
      <Box display='flex'>
        <Card variant='outlined' sx={{ width: '30%', marginRight: 2 }}>
          <Stack spacing={2} padding={2}>
            <Typography variant='subtitle2'>
              {t('enter_tracking_header')}
            </Typography>
            <TextField
              multiline
              required
              minRows={12}
              placeholder={t('enter_tracking_prompt')}
              label={t('enter_tracking_textbox')}
              value={trackingNos}
              onChange={e => setTrackingNos(e.target.value)}
              variant='outlined'
              slotProps={{ inputLabel: { shrink: true } }}
            />
            <TextField
              label={mawb ? t('batch_no') : t('enter_batch_textbox')}
              value={mawb}
              onChange={e => setmawb(e.target.value)}
              variant='outlined'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={() => setmawb('')}>
                      {mawb && <ClearIcon />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Button
              variant='contained'
              color='secondary'
              onClick={() => addMissingOrders()}>
              {t('add_order_btn')}
            </Button>
          </Stack>
        </Card>
        <Card variant='outlined' sx={{ width: '70%' }}>
          <Box padding={2}>
            <Typography variant='subtitle2'>
              {t('orders_add_header')}
            </Typography>
          </Box>
          <DataGridContainer>
            <DataGrid
              loading={loading}
              rows={addOrderResult}
              getRowId={row => row.tno}
              columns={ordersAddedColumns}
              getRowClassName={params =>
                params.row.status === 'FAILED' ? 'failed' : ''
              }
              getCellClassName={params => {
                if (params.field === 'status') {
                  if (params.value === 'FAILED') {
                    return 'failed-cell'
                  }
                  return 'success-cell'
                }
              }}
              components={{
                Footer: orderReplenishmentFooter,
                NoRowsOverlay: noOrdersAddedPrompt
              }}
              sx={{ minHeight: '450px' }}
            />
          </DataGridContainer>
        </Card>
      </Box>
    </div>
  )
}
