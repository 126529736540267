import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { currentTheme } from './reducers/sliceReducer'
import { lightTheme, darkTheme } from './utilities/themes'
import { BrowserRouter as Router } from 'react-router-dom'
import { renderRoutes } from 'react-router-config'
import { routes } from './routes'
import { AlertContextProvider } from './contexts/AlertContext'
import { CustomerContextProvider } from './contexts/CustomerContext'
import { userLogout } from './actions/userActions'
import { parseJwt } from './utilities/common'
import Snackbar from './components/Snackbar'
import Alert from './components/Alert'

function App() {
  const isDarkTheme = useSelector(currentTheme)
  const theme = isDarkTheme ? darkTheme : lightTheme
  const token = window.localStorage.getItem('auth_token')
  const lastActivityTime = useRef(new Date())

  useEffect(() => {
    const checkUserActivity = () => {
      const currentTime = new Date()
      const inactiveTime = currentTime - lastActivityTime.current

      // Check token expiration
      if (token) {
        const exp = parseJwt(token).exp
        const timeLeft = exp - Math.floor(currentTime / 1000)
        if (timeLeft < 0) {
          userLogout()
        }
      }

      // Check if 30 minutes have passed
      if (inactiveTime >= 30 * 60 * 1000) {
        userLogout()
      }
    }
    checkUserActivity()

    const activityTimer = setInterval(checkUserActivity, 60000)
    return () => clearInterval(activityTimer)
  }, [token])

  useEffect(() => {
    function handleUserActivity() {
      lastActivityTime.current = new Date()
    }
    window.addEventListener('mousemove', handleUserActivity)
    window.addEventListener('keydown', handleUserActivity)

    return () => {
      window.removeEventListener('mousemove', handleUserActivity)
      window.removeEventListener('keydown', handleUserActivity)
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <AlertContextProvider>
        <CustomerContextProvider>
          <Router>{renderRoutes(routes)}</Router>
          <CssBaseline />
          <Snackbar />
          <Alert />
        </CustomerContextProvider>
      </AlertContextProvider>
    </ThemeProvider>
  )
}

export default App
