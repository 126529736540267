import { useDispatch } from 'react-redux'
import {
  Card,
  Box,
  Button,
  Grid2 as Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
  Link
} from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { DataGrid } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import {
  FAULTY_PACKAGE_RECORD_INFO,
  FAULTY_PACKAGE_STATUS,
  FaultyPackageActionTaken,
  FaultyPackageClientDecision,
  FaultyPackageIssueType,
  FaultyPackagePackageStatus,
  FaultyPackageRecordStatus
} from '../../utilities/constants'
import {
  getFileById,
  getImagesById,
  saveFileById,
  updateRecordInformation
} from '../../actions/faultyPackageActions'
import { useAlert } from '../../utilities/hooks'
import { showAlertSnackbar } from '../../reducers/sliceReducer'
import * as XLSX from 'xlsx'
import DataGridContainer from '../../components/DataGridContainer'

const ALERT = {
  SEARCH_SUCCESS: 'FETCH DATA SUCCESS',
  SEARCH_FAILED: 'FETCH DATA FAILED',
  UPDATE_SUCCESS: 'UPDATE SUCCESS',
  UPDATE_FAILED: 'UPDATE FAILED',
  FILE_SUCCESS: 'SUBMIT FILE SUCCESS',
  FILE_FAILED: 'SUBMIT FILE FAILED'
}

export default function GVFaultyPackageDisplay(props) {
  const { records, stats, warehouseDropDown, refresh, setloading } = props
  const dispatch = useDispatch()
  const alertDialog = useAlert()
  const [editRecordOpen, setEditRecordOpen] = useState(false)
  const [recordInfo, setRecordInfo] = useState(FAULTY_PACKAGE_RECORD_INFO)
  const [images, setImages] = useState([])
  const [file, setFile] = useState(null)
  const [imageDetail, setImageDetail] = useState(null)

  const faultyPackageColumns = [
    {
      field: 'created_at',
      headerName: 'Creation Time',
      width: 200
    },
    {
      field: 'warehouse',
      headerName: 'Warehouse',
      width: 200,
      valueGetter: value => {
        return warehouseDropDown[value]
      }
    },
    {
      field: 'tracking_number',
      headerName: 'Tracking No.',
      width: 200
    },
    {
      field: 'sender_reference',
      headerName: 'Sender Ref',
      width: 200
    },
    {
      field: 'item_number',
      headerName: 'Item No.',
      width: 200
    },
    {
      field: 'issue_type',
      headerName: 'Issue Type',
      width: 200,
      valueGetter: value => {
        return FaultyPackageIssueType[value]
      }
    },
    {
      field: 'package_status',
      headerName: 'Package Status',
      width: 200,
      valueGetter: value => {
        return FaultyPackagePackageStatus[value]
      }
    },
    {
      field: 'record_status',
      headerName: 'Record Status',
      width: 200,
      valueGetter: value => {
        if (value === FAULTY_PACKAGE_STATUS.NEW_RECORD_STATUS) {
          return 'Action Required'
        } else {
          return FaultyPackageRecordStatus[value]
        }
      }
    }
  ]

  const handleRecordChange = key => e => {
    const newValue = e.target.value
    setRecordInfo(prevInfo => ({
      ...prevInfo,
      [key]: newValue
    }))
  }

  const handleFileChange = e => {
    const file = e.target.files[0]
    if (file) {
      setFile(file)
    }
  }

  const handleGetImages = async row => {
    try {
      const res = await dispatch(getImagesById(row.id))
      setImages(res)
    } catch (e) {
      console.error(e)
    }
  }

  const handleDownload = () => {
    if (file) {
      const downloadLink = document.createElement('a')
      const url = URL.createObjectURL(file)
      downloadLink.href = url
      downloadLink.download = 'faulty_package_record.xlsx'
      downloadLink.click()
      URL.revokeObjectURL(url)
    }
  }

  const handleGetFile = async row => {
    try {
      const res = await dispatch(getFileById(row.id))
      // Decode the Base64 string to binary data
      const binaryData = atob(res)

      // Create a Uint8Array from the binary data
      const byteArray = new Uint8Array(binaryData.length)
      for (let i = 0; i < binaryData.length; i++) {
        byteArray[i] = binaryData.charCodeAt(i)
      }

      // Create a Blob object with the correct MIME type
      const blob = new Blob([byteArray], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })

      // Create a File object (optional, but useful if you want to set a file name)
      const file = new File([blob], 'faulty_package_record.xlsx', {
        type: blob.type
      })

      setFile(file)
    } catch (e) {
      console.error(e)
    }
  }

  const handleOpenRecord = async e => {
    setloading(true)
    setRecordInfo(e.row)
    await handleGetImages(e.row)
    await handleGetFile(e.row)
    setloading(false)
    setEditRecordOpen(true)
  }

  const getAirportCode = warehouseId => {
    return warehouseDropDown[warehouseId] || ''
  }

  const handleRecordInfo = async event => {
    event.preventDefault()

    // Check if recordInfo.client_decision is null
    if (recordInfo.client_decision === null) {
      alertDialog.addAlertDialog(
        ALERT.UPDATE_FAILED,
        'Please enter a decision.'
      )
      alertDialog.showAlert()
      return
    }

    try {
      if (recordInfo.record_status === FAULTY_PACKAGE_STATUS.CLIENT_REVIEWED) {
        await dispatch(
          updateRecordInformation(recordInfo.id, { ...recordInfo })
        )
      } else {
        await dispatch(
          updateRecordInformation(recordInfo.id, {
            ...recordInfo,
            update_values_only: false
          })
        )
      }
      dispatch(
        showAlertSnackbar({ title: ALERT.UPDATE_SUCCESS, type: 'success' })
      )
    } catch (e) {
      alertDialog.addAlertDialog(ALERT.UPDATE_FAILED, e.message)
      alertDialog.showAlert()
    }
  }

  const handleFileSubmit = async event => {
    event.preventDefault()
    try {
      let formData = new FormData()
      formData.append('file', file)
      await dispatch(saveFileById(recordInfo.id, formData))
    } catch (e) {
      alertDialog.addAlertDialog(ALERT.FILE_FAILED, e.message)
      alertDialog.showAlert()
    } finally {
      setFile(null)
    }
  }

  const handleSubmit = async event => {
    setEditRecordOpen(false)
    event.preventDefault()
    try {
      await handleRecordInfo(event)
      if (file) {
        await handleFileSubmit(event)
      }
    } catch (e) {
      console.error(e)
    } finally {
      refresh()
    }
  }

  useEffect(() => {
    if (!editRecordOpen) {
      setFile(null)
      setImages([])
    }
  }, [editRecordOpen])

  function downloadAllRecords() {
    var filtered = records
    filtered = filtered.map(
      ({
        created_at,
        warehouse,
        tracking_number,
        sender_reference,
        item_number,
        issue_type,
        package_status,
        record_status,
        ...rest
      }) => ({
        'Created At': created_at,
        Warehouse: warehouseDropDown[warehouse],
        ' Tracking Number': tracking_number,
        'Sender Reference': sender_reference,
        'Item Number': item_number,
        'Issue Type': FaultyPackageIssueType[issue_type],
        'Package Status': FaultyPackagePackageStatus[package_status],
        'Record Status': FaultyPackageRecordStatus[record_status]
      })
    )

    filtered.reverse()
    var workbook = XLSX.utils.book_new()
    var workSheet = XLSX.utils.json_to_sheet(filtered)
    workbook.SheetNames.push('Faulty_Package_Records')
    workbook.Sheets['Faulty_Package_Records'] = workSheet
    XLSX.writeFile(workbook, 'Faulty_Package_Records.xlsx')
  }

  return (
    <Card variant='outlined'>
      <Box display='flex' alignItems='center' padding={2}>
        <Typography variant='subtitle2' flex={1}>
          Faulty Package List
        </Typography>
        <Typography marginRight={1}>
          {stats && stats.length > 0 && (
            <>
              <span style={{ color: 'red' }}>
                {stats.reduce(
                  (count, obj) =>
                    obj.record_status ===
                    FAULTY_PACKAGE_STATUS.NEW_RECORD_STATUS
                      ? obj.count
                      : count,
                  0
                ) + ' Action Required'}
              </span>
              <span>
                {' / ' +
                  stats.reduce(
                    (count, obj) =>
                      obj.record_status === FAULTY_PACKAGE_STATUS.UNI_CONFIRMED
                        ? obj.count
                        : count,
                    0
                  ) +
                  ' UniUni Confirmed'}
              </span>
            </>
          )}
        </Typography>
        <Button
          onClick={downloadAllRecords}
          variant='outlined'
          color='secondary'
          size='small'>
          Download All
        </Button>
      </Box>

      <DataGridContainer>
        <DataGrid
          rows={records}
          columns={faultyPackageColumns}
          onRowClick={handleOpenRecord}
          getCellClassName={params => {
            if (
              params.field === 'record_status' &&
              params.value === 'Action Required'
            ) {
              return 'ActionRequired'
            }
          }}
          sx={{
            '& .ActionRequired': {
              color: '#D32F2FDE'
            }
          }}
        />
      </DataGridContainer>

      <Dialog
        fullWidth
        maxWidth='sm'
        open={editRecordOpen}
        onClose={() => setEditRecordOpen(false)}>
        <DialogTitle>Faulty Package Record</DialogTitle>
        <DialogContent dividers sx={{ padding: 0 }}>
          {/* step 1 */}
          <Accordion elevation={0} sx={{ paddingX: 1 }} expanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Basic Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid size={6}>
                  <TextField
                    label='Record Date'
                    type='date'
                    slotProps={{ inputLabel: { shrink: true } }}
                    variant='outlined'
                    name='created_date'
                    value={recordInfo.created_at}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid size={6}>
                  <TextField
                    name='warehouse'
                    label='Warehouse'
                    slotProps={{ inputLabel: { shrink: true } }}
                    variant='outlined'
                    value={
                      warehouseDropDown
                        ? getAirportCode(recordInfo.warehouse)
                        : ''
                    }
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid size={12}>
                  <TextField
                    label='Tracking No.'
                    name='tracking_number'
                    variant='outlined'
                    value={recordInfo.tracking_number}
                    slotProps={{ inputLabel: { shrink: true } }}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid size={12}>
                  <TextField
                    label='Sender Ref'
                    name='sender_reference'
                    slotProps={{ inputLabel: { shrink: true } }}
                    variant='outlined'
                    value={recordInfo.sender_reference}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid size={12}>
                  <TextField
                    label='Item No.'
                    name='item_number'
                    slotProps={{ inputLabel: { shrink: true } }}
                    variant='outlined'
                    value={recordInfo.item_number}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid size={12}>
                  <TextField
                    label='Issue Type'
                    name='issue_type'
                    slotProps={{ inputLabel: { shrink: true } }}
                    variant='outlined'
                    value={FaultyPackageIssueType[recordInfo.issue_type] || ''}
                    fullWidth
                    disabled
                  />
                </Grid>
                {images && (
                  <Grid size={12}>
                    {images.map(image => (
                      <img
                        src={`data:image/jpeg;base64,${image}`}
                        alt=''
                        style={{
                          width: 100,
                          height: 100,
                          zIndex: 1000,
                          borderRadius: 4,
                          cursor: 'pointer',
                          margin: '5px'
                        }}
                        onClick={() => setImageDetail(image)}
                      />
                    ))}
                  </Grid>
                )}
                <Grid size={12}>
                  <TextField
                    label='Notes'
                    multiline
                    minRows={4}
                    defaultValue='Add notes here'
                    slotProps={{ inputLabel: { shrink: true } }}
                    variant='outlined'
                    value={recordInfo.uni_notes}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid size={12}>
                  <Typography>
                    {`Current Package Status: ${FaultyPackagePackageStatus[recordInfo.package_status]}`}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* step 2 */}
          <Accordion elevation={0} sx={{ paddingX: 1 }} expanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Client Review</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid size={12}>
                  <TextField
                    label='Verify Tracking No.'
                    name='verified_tracking_number'
                    variant='outlined'
                    fullWidth
                    value={recordInfo.verified_tracking_number}
                    slotProps={{ inputLabel: { shrink: true } }}
                    onChange={handleRecordChange('verified_tracking_number')}
                  />
                </Grid>
                <Grid size={12}>
                  <TextField
                    select
                    label='Decision*'
                    name='client_decision'
                    slotProps={{ inputLabel: { shrink: true } }}
                    variant='outlined'
                    fullWidth
                    value={recordInfo.client_decision}
                    onChange={handleRecordChange('client_decision')}>
                    {Object.entries(FaultyPackageClientDecision).map(
                      ([k, v]) => (
                        <MenuItem key={k} value={k}>
                          {v}
                        </MenuItem>
                      )
                    )}
                  </TextField>
                </Grid>
                <Grid size={12}>
                  <TextField
                    label='Notes'
                    name='client_notes'
                    multiline
                    minRows={4}
                    placeholder={'Add notes here'}
                    slotProps={{ inputLabel: { shrink: true } }}
                    variant='outlined'
                    fullWidth
                    value={recordInfo.client_notes}
                    onChange={handleRecordChange('client_notes')}
                  />
                </Grid>
                <Grid size={12}>
                  <Button
                    component='label'
                    for='file-upload'
                    variant='outlined'
                    color='secondary'>
                    Upload File
                  </Button>
                  <Typography
                    variant='caption'
                    color='secondary'
                    marginLeft={1}>
                    *Accept .xlsx, .xls files only
                  </Typography>
                  <input
                    hidden
                    accept='.xlsx, .xls'
                    type='file'
                    id='file-upload'
                    onChange={handleFileChange}
                  />
                  {file && file.size > 0 && (
                    <Link
                      variant='caption'
                      color='secondary'
                      sx={{ cursor: 'pointer', marginLeft: 1 }}
                      onClick={handleDownload}>
                      Download File
                    </Link>
                  )}
                </Grid>
              </Grid>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {/* row 1 */}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'stretch'
                  }}></div>
                {/* row 2 */}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'stretch'
                  }}></div>
                {/* row 3 */}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'stretch'
                  }}></div>
                {/* row 4  file */}
                <div>
                  {/* File Upload Input */}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'stretch',
                      marginLeft: '11px'
                    }}></div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          {/* step 3: disabled */}
          <Accordion elevation={0} sx={{ paddingX: 1 }} disabled>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Confirmation</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid size={12}>
                  <TextField
                    label='Close Date*'
                    type='closed_at'
                    slotProps={{ inputLabel: { shrink: true } }}
                    variant='outlined'
                    fullWidth
                    value={recordInfo.closed_at}
                  />
                </Grid>
                <Grid size={12}>
                  <TextField
                    select
                    label='Action*'
                    name='action_taken'
                    slotProps={{ inputLabel: { shrink: true } }}
                    variant='outlined'
                    fullWidth
                    value={recordInfo.action_taken}>
                    {Object.entries(FaultyPackageActionTaken).map(([k, v]) => (
                      <MenuItem key={k} value={k}>
                        {v}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid size={12}>
                  <TextField
                    label='Notes'
                    name='closing_notes'
                    multiline
                    minRows={4}
                    defaultValue='Add notes here'
                    slotProps={{ inputLabel: { shrink: true } }}
                    variant='outlined'
                    fullWidth
                    value={recordInfo.closing_notes}
                  />
                </Grid>
                <Grid size={12}>
                  <Typography>
                    {'Current Package Status: ' +
                      FaultyPackagePackageStatus[recordInfo.package_status]}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          {recordInfo.record_status === 3 || recordInfo.record_status === 4 ? (
            <Button onClick={() => setEditRecordOpen(false)}>Close</Button>
          ) : (
            <>
              <Button autoFocus onClick={() => setEditRecordOpen(false)}>
                Cancel
              </Button>
              <Button variant='contained' onClick={handleSubmit}>
                Submit
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth='md'
        fullWidth={true}
        open={imageDetail !== null}
        onClose={() => setImageDetail(null)}>
        <DialogTitle>Image</DialogTitle>
        <DialogContent dividers>
          <img
            src={`data:image/jpeg;base64,${imageDetail}`}
            alt=''
            style={{
              zIndex: 1000,
              borderRadius: 4,
              cursor: 'pointer',
              margin: '5px'
            }}
          />
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button autoFocus onClick={() => setImageDetail(null)}>
            Back
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
}
