import {
  FETCH_DRIVER_LIST,
  FETCH_DRIVER_LIST_SUCCESS,
  FETCH_DRIVER_LIST_FAIL,
  SEARCH_DRIVER,
  SEARCH_DRIVER_SUCCESS,
  SEARCH_DRIVER_FAIL,
  GET_T4A_TOTAL,
  GET_T4A_TOTAL_SUCCESS,
  GET_T4A_TOTAL_FAIL
} from '../actions/types'

const initialState = {
  fetchDriverListLoading: false,
  fetchDriverList: [],
  fetchDriverListError: false,
  searchDriverLoading: false,
  searchDriver: [],
  searchDriverError: false,
  getT4aTotalLoading: false,
  getT4aTotal: [],
  getT4aTotalError: false
}

export default function t4aReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DRIVER_LIST:
      return {
        ...state,
        fetchDriverListLoading: true
      }
    case FETCH_DRIVER_LIST_SUCCESS:
      return {
        ...state,
        fetchDriverList: action.payload,
        fetchDriverListLoading: false
      }
    case FETCH_DRIVER_LIST_FAIL:
      return {
        ...state,
        fetchDriverListLoading: false,
        fetchDriverListError: action.payload
      }
    case SEARCH_DRIVER:
      return {
        ...state,
        searchDriverLoading: true
      }
    case SEARCH_DRIVER_SUCCESS:
      return {
        ...state,
        searchDriver: action.payload,
        searchDriverLoading: false
      }
    case SEARCH_DRIVER_FAIL:
      return {
        ...state,
        searchDriverLoading: false,
        searchDriverError: action.payload
      }
    case GET_T4A_TOTAL:
      return {
        ...state,
        getT4aTotalLoading: true
      }
    case GET_T4A_TOTAL_SUCCESS:
      return {
        ...state,
        getT4aTotal: action.payload,
        getT4aTotalLoading: false
      }
    case GET_T4A_TOTAL_FAIL:
      return {
        ...state,
        getT4aTotalLoading: false,
        getT4aTotalError: action.payload
      }
    default:
      return state
  }
}
