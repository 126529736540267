import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Autocomplete,
  TextField,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Grid2 as Grid,
  createFilterOptions
} from '@mui/material'
import { showAlertSnackbar } from '../../reducers/sliceReducer'
import { createUserAccount, editUserAccount } from '../../actions/storeActions'

const ALERT = {
  SEARCH_SUCCESS: 'FETCH DATA SUCCESS',
  CREATE_SUCCESS: 'CREATE CUSTOMER SUCCESS',
  CREATE_FAILED: 'CREATE CUSTOMER FAILED',
  MODIFY_SUCCESS: 'MODIFY CUSTOMER SUCCESS',
  MODIFY_FAILED: 'MODIFY CUSTOMER FAILED',
  SAVE_FAILED: 'SAVE CUSTOMER FAILED',
  GET_ECOM_MERCHANT_NAMES_FAILED: 'GET ECOM MERCHANT NAMES FAILED'
}

const PARTNER_ADD_OPTION = { id: 'add', name: 'Add a new Partner' }
const partnerFilterOptions = (options, state) => {
  const results = createFilterOptions()(options, state)
  results.unshift(PARTNER_ADD_OPTION)
  return results
}

export default function EditCustomer(props) {
  const {
    openDialog,
    customerInfo,
    closeDialog,
    setLoading,
    partners,
    ecom,
    merchant,
    setReloadPage
  } = props

  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [editMode, setEditMode] = useState(false)

  const [newEcom, setNewEcom] = useState(false)
  const [newMerchant, setNewMerchant] = useState(false)
  const [newPartner, setNewPartner] = useState(false)

  const [title, setTitle] = useState('Edit Customer')
  const [customer, setCustomer] = useState({})

  const handleCreateUser = async () => {
    setLoading(true)
    try {
      const params = {
        name: customer.alias,
        email: customer.email,
        address: customer.address,
        // locality_type: Number(customer.locality_type) || null,
        contact: customer.receipt_name || null,
        phone: customer.phone || null,
        partner_id: Number(customer.partner_id) || null,
        partner_name: customer.partner_name || null,
        merchant: customer.merchant || null,
        ecom_name: customer.ecom_name || null
      }

      await dispatch(createUserAccount(params))
      dispatch(
        showAlertSnackbar({ title: ALERT.CREATE_SUCCESS, type: 'success' })
      )
    } catch (e) {
      dispatch(showAlertSnackbar({ title: ALERT.CREATE_FAILED, type: 'error' }))
    } finally {
      setLoading(false)
    }
  }

  const handleUpdateUser = async () => {
    setLoading(true)
    try {
      const params = {
        id: customer.id,
        address: customer.address,
        receipt_name: customer.receipt_name || null,
        phone: customer.phone || null,
        merchant: customer.merchant || null,
        ecom_name: customer.ecom_name || null
      }
      await dispatch(editUserAccount(params))
      dispatch(
        showAlertSnackbar({ title: ALERT.MODIFY_SUCCESS, type: 'success' })
      )
    } catch (e) {
      dispatch(showAlertSnackbar({ title: ALERT.MODIFY_FAILED, type: 'error' }))
    } finally {
      setLoading(false)
    }
  }

  const handleSubmitUser = async () => {
    try {
      await (editMode ? handleUpdateUser() : handleCreateUser())
    } catch (e) {
      dispatch(showAlertSnackbar({ title: ALERT.SAVE_FAILED, type: 'error' }))
    } finally {
      setNewEcom(false)
      setNewMerchant(false)
      setNewPartner(false)
      setReloadPage(true)
      handleClose()
    }
  }

  const handleInputChange = field => e => {
    const { value } = e.target

    setCustomer(prev => ({
      ...prev,
      [field]: value
    }))
  }

  const handleSelectChange = field => e => {
    const { value } = e.target
    const isAdd = value === 'add'

    switch (field) {
      case 'merchant':
        setNewMerchant(isAdd)
        break
      case 'ecom_name':
        setNewEcom(isAdd)
        break
      case 'partner_id':
        setNewPartner(isAdd)

        setCustomer(prev => ({
          ...prev,
          partner_id: isAdd ? null : value
        }))
        return
      default:
        break
    }

    if (!isAdd) {
      setCustomer(prev => ({
        ...prev,
        [field]: value
      }))
    }
  }

  function handleClose() {
    setOpen(false)
    closeDialog()
  }

  useEffect(() => {
    setOpen(openDialog)
    setCustomer(customerInfo)
  }, [openDialog, customerInfo])

  useEffect(() => {
    if (customerInfo?.id) {
      setEditMode(true)
      setTitle('Edit Customer')
    } else {
      setEditMode(false)
      setTitle('New Customer')
    }

    if (!open) {
      setCustomer({})
      setNewEcom(false)
      setNewMerchant(false)
      setNewPartner(false)
    }
  }, [customerInfo, open])

  return (
    <Dialog maxWidth='sm' open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={2}>
          {editMode && (
            <Grid size={12}>
              <TextField
                disabled={editMode}
                id='dialog-client-secret'
                label='Client Secret'
                slotProps={{ inputLabel: { shrink: true } }}
                variant='outlined'
                value={customer['client_secret']}
                fullWidth
              />
            </Grid>
          )}

          {editMode && (
            <>
              <Grid size={6}>
                <TextField
                  disabled={editMode}
                  id='dialog-client-id'
                  label='Client ID'
                  slotProps={{ inputLabel: { shrink: true } }}
                  variant='outlined'
                  value={customer['client_id']}
                  fullWidth
                />
              </Grid>
              <Grid size={6}>
                <TextField
                  disabled={editMode}
                  id='dialog-customer-id'
                  label='Customer ID'
                  slotProps={{ inputLabel: { shrink: true } }}
                  variant='outlined'
                  value={customer['id']}
                  fullWidth
                />
              </Grid>
            </>
          )}

          <Grid size={12}>
            <TextField
              disabled={editMode}
              id={'dialog-customer-name'}
              label='Customer Name'
              slotProps={{ inputLabel: { shrink: true } }}
              variant='outlined'
              value={customer['alias']}
              onChange={handleInputChange('alias')}
              fullWidth
            />
          </Grid>

          <Grid size={12}>
            <TextField
              disabled={editMode}
              id={'dialog-contact-email'}
              label='Contact Email'
              slotProps={{ inputLabel: { shrink: true } }}
              variant='outlined'
              value={customer['email']}
              onChange={handleInputChange('email')}
              fullWidth
            />
          </Grid>

          <Grid size={12}>
            <TextField
              id={'dialog-street-address'}
              label='Street Address'
              slotProps={{ inputLabel: { shrink: true } }}
              variant='outlined'
              value={customer['address']}
              onChange={handleInputChange('address')}
              fullWidth
            />
          </Grid>

          <Grid size={6}>
            <TextField
              id={'name-on-invoice'}
              label='Name On Invoice'
              slotProps={{ inputLabel: { shrink: true } }}
              variant='outlined'
              value={customer['receipt_name']}
              onChange={handleInputChange('receipt_name')}
              fullWidth
            />
          </Grid>
          <Grid size={6}>
            <TextField
              id={'dialog-contact-number'}
              label='Contact Number'
              slotProps={{ inputLabel: { shrink: true } }}
              variant='outlined'
              value={customer['phone']}
              onChange={handleInputChange('phone')}
              fullWidth
            />
          </Grid>

          <Grid size={12}>
            <Autocomplete
              value={
                newPartner
                  ? PARTNER_ADD_OPTION
                  : partners.find(
                      partner => partner.id === customer['partner_id']
                    ) || null
              }
              onChange={(e, option) =>
                handleSelectChange('partner_id')({
                  target: { value: option ? option.id : null }
                })
              }
              fullWidth
              options={partners}
              filterOptions={partnerFilterOptions}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={partner =>
                partner.id === PARTNER_ADD_OPTION.id
                  ? PARTNER_ADD_OPTION.name
                  : `${partner.id}: ${partner.name}`
              }
              renderInput={params => (
                <TextField {...params} label='Partner' variant='outlined' />
              )}
            />
          </Grid>

          {newPartner && (
            <Grid size={12}>
              <TextField
                variant='outlined'
                label='New Partner'
                value={customer['partner_name']}
                onChange={handleInputChange('partner_name')}
                slotProps={{ inputLabel: { shrink: true } }}
                fullWidth
              />
            </Grid>
          )}

          <Grid size={12}>
            <FormControl variant='outlined' fullWidth>
              <InputLabel id='dialog-merchant-label'>Sales Channel</InputLabel>
              <Select
                labelId='dialog-merchant-label'
                id='dialog-merchant'
                value={newMerchant ? 'add' : customer['merchant'] || ''}
                onChange={handleSelectChange('merchant')}
                label='Sales Channel'>
                <MenuItem value={'add'}>Add a new Sales Channel</MenuItem>
                {merchant.map((name, index) => (
                  <MenuItem key={index} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {newMerchant && (
            <Grid size={12}>
              <TextField
                variant='outlined'
                label='New sales channel'
                value={customer['merchant']}
                onChange={handleInputChange('merchant')}
                slotProps={{ inputLabel: { shrink: true } }}
                fullWidth
              />
            </Grid>
          )}

          <Grid size={12}>
            <FormControl variant='outlined' fullWidth>
              <InputLabel id='dialog-ecom-label'>
                E-commerce Platform
              </InputLabel>
              <Select
                labelId='dialog-ecom-label'
                id='dialog-ecom'
                value={newEcom ? 'add' : customer['ecom_name'] || ''}
                onChange={handleSelectChange('ecom_name')}
                label='E-commerce Platform'>
                <MenuItem value={'add'}>Add a new E-com platform</MenuItem>
                {ecom.map((name, index) => (
                  <MenuItem key={index} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {newEcom && (
            <Grid size={12}>
              <TextField
                variant='outlined'
                label='New E-com platform'
                value={customer['ecom_name']}
                onChange={handleInputChange('ecom_name')}
                slotProps={{ inputLabel: { shrink: true } }}
                fullWidth
              />
            </Grid>
          )}

          {editMode && (
            <>
              <Grid size={6}>
                <TextField
                  disabled={editMode}
                  id='dialog-updated_at'
                  label='Last Edit Time'
                  slotProps={{ inputLabel: { shrink: true } }}
                  variant='outlined'
                  value={customer['updated_at']}
                  fullWidth
                />
              </Grid>
              <Grid size={6}>
                <TextField
                  disabled={editMode}
                  id='dialog-customer-id'
                  label='Last Operator'
                  slotProps={{ inputLabel: { shrink: true } }}
                  variant='outlined'
                  value={customer['operator']}
                  fullWidth
                />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ padding: 3 }}>
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button variant='contained' onClick={handleSubmitUser}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
