import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  MenuItem,
  Button,
  Grid
} from '@mui/material'
import { createPartnerRateCard } from '../../actions/rateCardsManagementActions'
import { showAlertSnackbar } from '../../reducers/sliceReducer'
import DragDropFileUpload from '../../components/DragDropFileUpload'
import dayjs from 'dayjs'

// const serviceTypes = [
//   'UniUni',
//   'CP',
//   'Fedex',
//   'GV'
// ]

const zoneVersions = [
  { id: 3, description: 'Temu' },
  { id: 4, description: 'GV' },
  { id: 10, description: 'Shein' },
  { id: 16, description: 'GV 海运' },
  { id: 17, description: 'Cainiao' }
]

const defaultFsaVersion = 3

export default function RateCardsManagementUpload({
  partnerId,
  onBack,
  onUpload
}) {
  const dispatch = useDispatch()
  const [zoneVersion, setZoneVersion] = useState('')
  const [fsaVersion, setFsaVersion] = useState(defaultFsaVersion)
  const [billingDate, setBillingDate] = useState('')
  const [effectiveDate, setEffectiveDate] = useState('')
  const [effectiveDateError, setEffectiveDateError] = useState(false)
  const [note, setNote] = useState('')
  const [file, setFile] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    // check if effective date is on a monday
    if (effectiveDate && dayjs(effectiveDate).day() !== 1) {
      setEffectiveDateError(true)
      return
    } else {
      setEffectiveDateError(false)
    }
  }, [effectiveDate])

  async function upload() {
    try {
      setLoading(true)

      // create rate card
      const formData = new FormData()
      formData.append('rate_code_version_id', zoneVersion)
      formData.append('fsa_version_id', fsaVersion)
      formData.append('effective_date', effectiveDate)
      formData.append('billing_date', billingDate)
      formData.append('note', note)
      formData.append('file', file)
      await dispatch(createPartnerRateCard(partnerId, formData))
      dispatch(
        showAlertSnackbar({ message: 'Upload success', type: 'success' })
      )
      onUpload()
    } catch (e) {
      dispatch(showAlertSnackbar({ message: e.message, type: 'error' }))
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <DialogTitle>Upload New Rate Card</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          {/* <Grid item xs={6}>
          <TextField
            value={serviceType}
            onChange={e => setServiceType(e.target.value)}
            label='Service Type*'
            slotProps={{ inputLabel: { shrink: true } }}
            SelectProps={{ displayEmpty: true }}
            fullWidth
            disabled
            select>
            <MenuItem value=''>
              <Typography sx={{ opacity: 0.38 }}>
                Select Service Type
              </Typography>
            </MenuItem>
            {serviceTypes.map((value) => (
              <MenuItem value={value} key={value}>
                {value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6} /> */}
          <Grid item xs={12}>
            <Typography paddingY='4px'>
              Please upload the file for adding new price sheet.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <DragDropFileUpload
              fileType='.xlsx'
              onFileUpload={setFile}
              file={file}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={zoneVersion}
              onChange={e => setZoneVersion(e.target.value)}
              label='Zone Version'
              slotProps={{ inputLabel: { shrink: true } }}
              SelectProps={{ displayEmpty: true }}
              fullWidth
              required
              select>
              <MenuItem value=''>
                <Typography sx={{ opacity: 0.38 }}>
                  Select Zone Version
                </Typography>
              </MenuItem>
              {zoneVersions.map(version => {
                return (
                  <MenuItem value={version.id} key={version.id}>
                    {version.id}
                    {` (${version.description})`}
                  </MenuItem>
                )
              })}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={fsaVersion}
              onChange={e => setFsaVersion(e.target.value)}
              label='FSA Version'
              slotProps={{ inputLabel: { shrink: true } }}
              SelectProps={{ displayEmpty: true }}
              fullWidth
              required
              disabled
              select>
              <MenuItem value=''>
                <Typography sx={{ opacity: 0.38 }}>
                  Select FSA Version
                </Typography>
              </MenuItem>
              <MenuItem value={defaultFsaVersion}>{defaultFsaVersion}</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={effectiveDate}
              onChange={e => setEffectiveDate(e.target.value)}
              type='date'
              label='Effective Date'
              placeholder='Enter Effective Date'
              error={effectiveDateError}
              helperText={
                effectiveDateError
                  ? 'Effective date must be on a Monday'
                  : undefined
              }
              slotProps={{ inputLabel: { shrink: true } }}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={billingDate}
              onChange={e => setBillingDate(e.target.value)}
              type='date'
              label='Billing Date'
              placeholder='Enter Billing Date'
              slotProps={{ inputLabel: { shrink: true } }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={note}
              onChange={e => setNote(e.target.value)}
              label='Note'
              placeholder='Enter Note'
              slotProps={{ inputLabel: { shrink: true } }}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ padding: 3 }}>
        <Button onClick={onBack} color='secondary'>
          Back
        </Button>
        <Button
          onClick={upload}
          disabled={
            loading ||
            !zoneVersion ||
            !file ||
            !effectiveDate ||
            effectiveDateError
          }
          variant='contained'
          color='secondary'>
          {loading ? 'Uploading' : 'Upload'}
        </Button>
      </DialogActions>
    </>
  )
}
