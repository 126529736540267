import { useDispatch } from 'react-redux'
import { TextField, Button, Box, Stack, Card, Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/Add'
import { showAlertSnackbar } from '../../reducers/sliceReducer'
import {
  getCustomers,
  getEcomMerchantNames,
  getPartners
} from '../../actions/storeActions'
import { useAlert } from '../../utilities/hooks'
import EditCustomer from './EditCustomer'
import DataGridContainer from '../../components/DataGridContainer'

const ALERT = {
  SEARCH_SUCCESS: 'FETCH DATA SUCCESS',
  SEARCH_FAILED: 'FETCH DATA FAILED',
  MODIFY_SUCCESS: 'MODIFY CUSTOMER SUCCESS',
  MODIFY_FAILED: 'MODIFY CUSTOMER FAILED',
  CREATE_SUCCESS: 'CREATE CUSTOMER SUCCESS',
  CREATE_FAILED: 'CREATE CUSTOMER FAILED'
}

export default function CustomerManagement() {
  const dispatch = useDispatch()
  const alertDialog = useAlert()

  const [loading, setLoading] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [reloadPage, setReloadPage] = useState(false)

  const [ecom, setEcom] = useState([])
  const [merchant, setMerchant] = useState([])
  const [partners, setPartners] = useState([])

  const [customer, setCustomer] = useState([])
  const [customerID, setCustomerID] = useState('')
  const [customerName, setCustomerName] = useState('')
  const [partnerID, setPartnerID] = useState('')
  const [partnerName, setPartnerName] = useState('')
  const [rows, setRows] = useState([])

  const columns = [
    {
      field: 'client_id',
      headerName: 'Client ID',
      flex: 1
    },
    {
      field: 'partner_name',
      headerName: 'Partner Name',
      flex: 1
    },
    {
      field: 'partner_status',
      headerName: 'Partner Status',
      flex: 1,
      cellClassName: params => {
        if (params.value === 'Pending') {
          return 'red-text'
        }
        return 'green-text'
      }
    },
    {
      field: 'customer_id',
      headerName: 'Customer ID',
      flex: 1
    },
    {
      field: 'customer_name',
      headerName: 'Customer Name',
      flex: 1
    },
    {
      field: 'customer_status',
      headerName: 'Customer Status',
      flex: 1,
      cellClassName: params => {
        if (params.value === 'Pending') {
          return 'red-text'
        }
        return 'green-text'
      }
    },
    {
      field: 'email',
      headerName: 'Contact Email',
      flex: 1
    },
    {
      field: 'need_pickup',
      headerName: 'Need Pickup',
      flex: 1,
      cellClassName: params => {
        if (params.value === 'No') {
          return 'red-text'
        }
        return 'green-text'
      }
    }
  ]

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      handleSearch(customerID, customerName, partnerID, partnerName)
    }
  }

  const handleSetPartnerId = e => {
    let id = e.target.value
    if (!isNaN(id)) {
      setPartnerID(id)
    }
  }

  const handleSetCustomerId = e => {
    let id = e.target.value
    if (!isNaN(id)) {
      setCustomerID(id)
    }
  }

  const handleRowClick = async selectedRow => {
    setLoading(true)
    try {
      const customerInfo = (
        await dispatch(getCustomers(selectedRow.row['customer_id']))
      )[0]

      setCustomer(customerInfo)
      setDialogOpen(true)
    } catch (e) {
      alertDialog.addAlertDialog(ALERT.CREATE_FAILED, e.message)
      alertDialog.showAlert()
    } finally {
      setLoading(false)
    }
  }

  const handleUpdateRow = res => {
    const updatedRows = res.map(row => ({
      id: row.id,
      client_id: row.client_id,
      partner_name: row.partner_name,
      partner_status: row.partner_status ? 'Active' : 'Pending',
      customer_id: row.id,
      customer_name: row.alias,
      customer_status: row.is_active ? 'Active' : 'Pending',
      email: row.email,
      need_pickup: row.need_pickup ? 'Yes' : 'No'
    }))

    setRows(updatedRows)
  }

  const handleFetchPartners = useCallback(async () => {
    setLoading(true)
    try {
      let res = await dispatch(getPartners())
      setPartners(res)
    } catch (e) {
      dispatch(
        showAlertSnackbar({ message: ALERT.SEARCH_FAILED, type: 'error' })
      )
    } finally {
      setLoading(false)
    }
  }, [dispatch])

  const handleFetchEcomMerchantNames = useCallback(async () => {
    setLoading(true)
    try {
      const res = await dispatch(getEcomMerchantNames())

      if (res?.ecom_name && res?.merchant) {
        setEcom(res.ecom_name)
        setMerchant(res.merchant)
      }
    } catch (e) {
      dispatch(
        showAlertSnackbar({ message: ALERT.SEARCH_FAILED, type: 'error' })
      )
    }
  }, [dispatch])

  const handleSearch = useCallback(
    async (
      customerID = '',
      customerName = '',
      partnerID = '',
      partnerName = ''
    ) => {
      setLoading(true)
      try {
        const customers = await dispatch(
          getCustomers(customerID, customerName, partnerID, partnerName)
        )

        handleUpdateRow(customers)

        dispatch(
          showAlertSnackbar({ message: ALERT.SEARCH_SUCCESS, type: 'success' })
        )
      } catch (e) {
        dispatch(
          showAlertSnackbar({ message: ALERT.SEARCH_FAILED, type: 'error' })
        )
      } finally {
        setLoading(false)
        setCustomerID('')
        setCustomerName('')
        setPartnerID('')
        setPartnerName('')
      }
    },
    [dispatch]
  )

  function handleEditClose() {
    setDialogOpen(false)
  }

  useEffect(() => {
    handleSearch()
    handleFetchPartners()
    handleFetchEcomMerchantNames()
  }, [handleFetchEcomMerchantNames, handleFetchPartners, handleSearch])

  useEffect(() => {
    if (reloadPage) {
      handleSearch()
      handleFetchPartners()
      handleFetchEcomMerchantNames()
    }
  }, [
    handleFetchEcomMerchantNames,
    handleFetchPartners,
    handleSearch,
    reloadPage
  ])

  useEffect(() => {
    if (!dialogOpen) {
      setCustomer([])
      setReloadPage(false)
    }
  }, [dialogOpen])

  return (
    <div>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        marginBottom={2}>
        <Typography variant='subtitle1'>Customer Management</Typography>
        <Button
          variant='contained'
          startIcon={<AddIcon />}
          onClick={() => setDialogOpen(true)}>
          New Customer
        </Button>
      </Box>

      <Card variant='outlined' sx={{ marginBottom: 2 }}>
        <Stack
          direction='row'
          alignItems='center'
          spacing={2}
          useFlexGap
          flexWrap='wrap'
          sx={{ padding: 3 }}>
          <TextField
            label={'Customer ID'}
            onChange={handleSetCustomerId}
            onKeyDown={e => handleKeyPress(e)}
            value={customerID}
            slotProps={{ inputLabel: { shrink: true } }}
            variant='outlined'
            size='small'
          />
          <TextField
            label={'Customer Name'}
            onChange={e => setCustomerName(e.target.value)}
            onKeyDown={e => handleKeyPress(e)}
            value={customerName}
            slotProps={{ inputLabel: { shrink: true } }}
            variant='outlined'
            size='small'
          />
          <TextField
            label={'Partner ID'}
            onChange={handleSetPartnerId}
            onKeyDown={e => handleKeyPress(e)}
            value={partnerID}
            slotProps={{ inputLabel: { shrink: true } }}
            variant='outlined'
            size='small'
          />
          <TextField
            label={'Partner Name'}
            onChange={e => setPartnerName(e.target.value)}
            onKeyDown={e => handleKeyPress(e)}
            value={partnerName}
            slotProps={{ inputLabel: { shrink: true } }}
            variant='outlined'
            size='small'
          />
          <IconButton
            color={'secondary'}
            onClick={() =>
              handleSearch(customerID, customerName, partnerID, partnerName)
            }>
            <SearchIcon />
          </IconButton>
        </Stack>
      </Card>

      <Card variant='outlined'>
        <Box display='flex' padding={2}>
          <Typography variant='subtitle2' flex={1}>
            Customer List
          </Typography>
        </Box>
        <DataGridContainer>
          <DataGrid
            rows={rows}
            columns={columns}
            loading={loading}
            disableColumnFilter
            disableColumnMenu
            disableSelectionOnClick
            onRowClick={selectedRow => handleRowClick(selectedRow)}
            pageSizeOptions={[10, 25, { value: -1, label: 'All' }]}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10
                }
              }
            }}
            sx={{
              '& .green-text': {
                color: 'green'
              },
              '& .red-text': {
                color: 'red'
              }
            }}
          />
        </DataGridContainer>
      </Card>

      <EditCustomer
        openDialog={dialogOpen}
        closeDialog={handleEditClose}
        customerInfo={customer}
        setLoading={setLoading}
        partners={partners}
        ecom={ecom}
        merchant={merchant}
        setReloadPage={setReloadPage}
      />
    </div>
  )
}
