import { FAULTY_PACKAGE as type } from './types'
import { shangjiaServerInstance } from '../utilities/instances'
import { execute } from '../utilities/executor'

export function getDropDownData(dropDown) {
  return function (dispatch) {
    dispatch({ type: type.GET_DROPDOWN_DATA })
    return shangjiaServerInstance
      .get('/faultypackages/dropdownvalues/' + dropDown)
      .then(({ data }) => {
        dispatch({
          type: type.GET_DROPDOWN_SUCCESS,
          payload: data.data
        })
        return Promise.resolve(data.data)
      })
      .catch(error => {
        dispatch({
          type: type.GET_DROPDOWN_FAIL,
          payload: error
        })
        return Promise.reject(error)
      })
  }
}

export function searchFPRecords(params) {
  return function (dispatch) {
    dispatch({ type: type.SEARCH_FP_RECORDS })
    return shangjiaServerInstance
      .get('/faultypackages/record', { params: params })
      .then(({ data }) => {
        dispatch({
          type: type.SEARCH_FP_RECORDS_SUCCESS,
          payload: data.data
        })
        return Promise.resolve(data.data)
      })
      .catch(error => {
        dispatch({
          type: type.SEARCH_FP_RECORDS_FAIL,
          payload: error
        })
        return Promise.reject(error)
      })
  }
}

export function getInfoByTrackingNumber(tno) {
  return function (dispatch) {
    dispatch({ type: type.GET_PACKAGE_INFO })
    return shangjiaServerInstance
      .get('/faultypackages/package/' + tno)
      .then(({ data }) => {
        dispatch({
          type: type.GET_PACKAGE_INFO_SUCCESS,
          payload: data.data
        })
        return Promise.resolve(data.data)
      })
      .catch(error => {
        dispatch({
          type: type.GET_PACKAGE_INFO_FAIL,
          payload: error
        })
        return Promise.reject(error)
      })
  }
}

export function createNewFPRecord(params) {
  return function (dispatch) {
    dispatch({ type: type.CREATE_NEW_RECORD })
    return shangjiaServerInstance
      .post('/faultypackages/record', params)
      .then(({ data }) => {
        dispatch({
          type: type.CREATE_NEW_RECORD_SUCCESS,
          payload: data.data
        })
        return Promise.resolve(data.data)
      })
      .catch(error => {
        dispatch({
          type: type.CREATE_NEW_RECORD_FAIL,
          payload: error
        })
        return Promise.reject(error)
      })
  }
}

export function updateRecordInformation(id, params) {
  return function (dispatch) {
    dispatch({ type: type.UPDATE_RECORD_INFORMATION })
    return shangjiaServerInstance
      .post('/faultypackages/record/' + id, params)
      .then(({ data }) => {
        dispatch({
          type: type.UPDATE_RECORD_INFORMATION_SUCCESS,
          payload: data.data
        })
        return Promise.resolve(data.data)
      })
      .catch(error => {
        dispatch({
          type: type.UPDATE_RECORD_INFORMATION_FAIL,
          payload: error
        })
        return Promise.reject(error)
      })
  }
}

export function deleteRecord(id) {
  return function (dispatch) {
    dispatch({ type: type.DELETE_RECORD })
    return shangjiaServerInstance
      .post('/faultypackages/record/delete/' + id)
      .then(({ data }) => {
        dispatch({
          type: type.DELETE_RECORD_SUCCESS,
          payload: data.data
        })
        return Promise.resolve(data.data)
      })
      .catch(error => {
        dispatch({
          type: type.DELETE_RECORD_FAIL,
          payload: error
        })
        return Promise.reject(error)
      })
  }
}

export function getImagesById(id) {
  return function (dispatch) {
    dispatch({ type: type.GET_IMAGES })
    return shangjiaServerInstance
      .get('/faultypackages/record/images/' + id)
      .then(({ data }) => {
        dispatch({
          type: type.GET_IMAGES_SUCCESS,
          payload: data.data
        })
        return Promise.resolve(data.data)
      })
      .catch(error => {
        dispatch({
          type: type.GET_IMAGES_FAIL,
          payload: error
        })
        return Promise.reject(error)
      })
  }
}
export function saveImagesById(id, params) {
  return function (dispatch) {
    dispatch({ type: type.SAVE_IMAGES })
    return shangjiaServerInstance
      .post('/faultypackages/record/images/' + id, params)
      .then(({ data }) => {
        dispatch({
          type: type.SAVE_IMAGES_SUCCESS,
          payload: data.data
        })
        return Promise.resolve(data.data)
      })
      .catch(error => {
        dispatch({
          type: type.SAVE_IMAGES_FAIL,
          payload: error
        })
        return Promise.reject(error)
      })
  }
}

/**
 *
 * @param id
 * @param params: {operator, file}
 * @returns {function(*): Promise<*|undefined>}
 */
export const saveFileById = (id, params) => d =>
  execute(d)(
    shangjiaServerInstance.post(`/faultypackages/record/file/` + id, params),
    {
      type: type.SAVE_FILES,
      success: type.SAVE_FILES_SUCCESS,
      fail: type.SAVE_FILES_FAIL
    }
  )

export const getFileById = id => d =>
  execute(d)(shangjiaServerInstance.get(`/faultypackages/record/file/` + id), {
    type: type.GET_FILES,
    success: type.GET_FILES_SUCCESS,
    fail: type.GET_FILES_FAIL
  })

export function getRecordStatistics() {
  return function (dispatch) {
    dispatch({ type: type.GET_STATUS })
    return shangjiaServerInstance
      .get('/faultypackages/statistics')
      .then(({ data }) => {
        dispatch({
          type: type.GET_STATUS_SUCCESS,
          payload: data.data
        })
        return Promise.resolve(data.data)
      })
      .catch(error => {
        dispatch({
          type: type.GET_STATUS_FAIL,
          payload: error
        })
        return Promise.reject(error)
      })
  }
}
