import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Stack,
  Card,
  Grid,
  MenuItem,
  TextField,
  Button,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '@mui/material'
import { shangjiaServerInstance } from '../../utilities/instances'
import {
  getRecentBatchList,
  getOrdersByCondition
} from '../../actions/storeActions'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import SearchIcon from '@mui/icons-material/Search'
import ReactToPrint from 'react-to-print'
import { showAlertSnackbar } from '../../reducers/sliceReducer'
import { useCustomer } from '../../utilities/hooks'
import LocalDeliveryDisplay from './LocalDeliveryDisplay'
import FullscreenLoading from '../../components/FullscreenLoading'
import dayjs from 'dayjs'

const emptyOrders = { data: [], total: 0 }

const defaultFromDate = dayjs().subtract(2, 'weeks').format('YYYY-MM-DD')
const defaultToDate = dayjs().format('YYYY-MM-DD')
const defaultPage = 1
const defaultRows = 10

export default function LocalDeliverySearch() {
  const { t } = useTranslation('localDelivery')
  const dispatch = useDispatch()
  const componentRef = useRef()
  const { customerData } = useCustomer()
  const [batchList, setBatchList] = useState([])
  const [batch, setBatch] = useState('')
  const [templateDialog, setTemplateOpen] = useState(false)
  const [uploadDialog, setUploadOpen] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const [failOpen, setFailOpen] = useState(false)
  const [batchNo, setBatchNo] = useState('')
  const [trackingNo, setTrackingNo] = useState('')
  const [fromDate, setFromDate] = useState(defaultFromDate)
  const [toDate, setToDate] = useState(defaultToDate)
  const [goodsType, setGoodsType] = useState('')
  const [page, setPage] = useState(defaultPage)
  const [rows, setRows] = useState(defaultRows)
  const [orders, setOrders] = useState(emptyOrders)

  const store = useSelector(state => state.store)

  function handleBatch(event) {
    if (event.target.value === t('create_batch')) {
      setBatch('')
    } else {
      setBatch(event.target.value)
    }
  }

  function handleSearch(rows, page) {
    setLoading(true)
    if (!customerData) {
      setLoading(false)
      dispatch(
        showAlertSnackbar({ message: 'Please Select a store', type: 'error' })
      )
    } else {
      const fetchAll = rows === -1
      dispatch(
        getOrdersByCondition(
          customerData.id,
          '',
          batchNo,
          trackingNo,
          fromDate,
          toDate,
          goodsType,
          fetchAll ? '' : rows,
          page
        )
      ).then(res => {
        setLoading(false)
        setOrders(fetchAll ? { data: res, total: res.length } : res) // returns data directly if not paginated
      })
    }
  }

  function handleUpload() {
    setUploadOpen(false)
    setLoading(true)
    let formData = new FormData()
    if (batch === t('create_batch') || batch === '') {
      formData.append('add_batch', 1)
    } else {
      formData.append('add_batch', 0)
      formData.append('add_batch_no', batch)
    }
    formData.append('local_import', 3)
    formData.append('orders', selectedFile)
    formData.append('is_alert', 0)
    formData.append('customer_id', customerData.id)
    formData.append('partner_id', customerData.partner_id)
    formData.append('partner_shop_id', customerData.id)
    formData.append('partner_shop_id', customerData.id)
    formData.append('partner_shop_name', customerData.alias)
    formData.append('start_phone', customerData.phone)
    formData.append('start_address', customerData.address)
    formData.append('start_consignee', customerData.receipt_name)
    formData.append('pay_method', 8)
    formData.append('type', 2)
    shangjiaServerInstance
      .post(
        `/business/batchbusinessimport`,
        formData,
        { timeout: 60000 * 20 },
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      .then(res => {
        const orders = res.data.data
        const errors = orders
          .map((order, index) => ({
            number: index + 1,
            message: order.ret_msg
          }))
          .filter(order => order.message)
          .map(order => `Package #${order.number} ${order.message}`)
        if (errors.length > 0) {
          setErrorMessage(errors)
          setFailOpen(true)
        }
        setSelectedFile(null)
        setBatchNo('')
        setTrackingNo('')
        setGoodsType('')
        dispatch(
          getOrdersByCondition(
            customerData.id,
            '',
            '',
            '',
            fromDate,
            toDate,
            goodsType,
            rows,
            page
          )
        ).then(res => {
          setOrders(res)
        })
        dispatch(getRecentBatchList('', customerData.id, 7)).catch(() => {
          setBatchList([t('create_batch')])
          setBatch(t('create_batch'))
        })
        setLoading(false)
      })
      .catch(err => {
        setErrorMessage(err.message)
        setLoading(false)
        setFailOpen(true)
      })
  }

  useEffect(() => {
    if (customerData?.id) {
      setLoading(true)
      dispatch(getRecentBatchList('', customerData.id, 7)).catch(() => {
        setBatchList([t('create_batch')])
        setBatch(t('create_batch'))
      })
      dispatch(
        getOrdersByCondition(
          customerData.id,
          '',
          '',
          '',
          defaultFromDate,
          defaultToDate,
          '',
          defaultRows,
          defaultPage
        )
      ).then(res => {
        setLoading(false)
        setOrders(res)
      })
    } else {
      setBatchList([t('create_batch')])
      setBatch(t('create_batch'))
    }
  }, [customerData, dispatch, t])

  useEffect(() => {
    if (store.getRecentBatchList) {
      let batchListCurr = [t('create_batch')]
      store.getRecentBatchList.forEach(batch => {
        batchListCurr.push(batch.master_air_waybill)
      })
      setBatchList(batchListCurr)
    } else {
      setBatchList([t('create_batch')])
      setBatch(t('create_batch'))
    }
  }, [store.getRecentBatchList, t])

  return (
    <div>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        marginBottom={2}>
        <Typography variant='subtitle1'>Local Delivery</Typography>
        <Stack direction='row' spacing={2}>
          <Button
            startIcon={<InsertDriveFileOutlinedIcon />}
            onClick={() => setTemplateOpen(true)}
            variant='outlined'>
            Template
          </Button>
          <Button
            startIcon={<CloudUploadOutlinedIcon />}
            onClick={() => setUploadOpen(true)}
            variant='contained'>
            Batch Upload
          </Button>
        </Stack>

        <Dialog open={templateDialog} onClose={() => setTemplateOpen(false)}>
          <DialogTitle>Template</DialogTitle>
          <DialogContent>
            <Typography>
              The template is for placing batch orders. Please download and fill
              out the excel template. Then Use the BATCH UPLOAD functions to
              upload the excel file and place orders.
            </Typography>
            <br />
            <span
              style={{
                backgroundColor: '#FFEE58',
                padding: '5px 5px 0px 5px'
              }}>
              {t('yellow')}
            </span>{' '}
            {t('yellow_required')}*
            <br />
            <br />
            <span
              style={{
                backgroundColor: '#29B6F6',
                padding: '5px 5px 0px 5px'
              }}>
              {t('blue')}
            </span>{' '}
            {t('blue_optional')}
            <br />
          </DialogContent>
          <DialogActions sx={{ padding: 3 }}>
            <Button autoFocus onClick={() => setTemplateOpen(false)}>
              Cancel
            </Button>
            <Button
              href={
                process.env.REACT_APP_COUNTRY === 'US'
                  ? '/documents/upload_template_US.xlsx'
                  : '/documents/upload_template.xlsx'
              }
              download
              variant='contained'>
              Download
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={uploadDialog} onClose={() => setUploadOpen(false)}>
          <DialogTitle>Batch Upload</DialogTitle>
          <DialogContent dividers sx={{ paddingTop: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  disabled
                  id='batch-upload-stores'
                  label='Store'
                  slotProps={{ inputLabel: { shrink: true } }}
                  variant='outlined'
                  value={customerData.alias}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  select
                  label='Batch Number'
                  slotProps={{ inputLabel: { shrink: true } }}
                  variant='outlined'
                  value={batch ? batch : t('create_batch')}
                  onChange={handleBatch}
                  helperText='Select from the last 7 days of batches'
                  fullWidth>
                  {batchList.map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label='File'
                  type='file'
                  slotProps={{ inputLabel: { shrink: true } }}
                  variant='outlined'
                  onChange={e => setSelectedFile(e.target.files[0])}
                  helperText='Choose an Excel file to upload'
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ padding: 3 }}>
            <Button autoFocus onClick={() => setUploadOpen(false)}>
              Cancel
            </Button>
            <Button
              variant='contained'
              onClick={handleUpload}
              disabled={!customerData}>
              Upload
            </Button>
          </DialogActions>
        </Dialog>
      </Box>

      <Card variant='outlined' sx={{ marginBottom: 2 }}>
        <Stack
          direction='row'
          alignItems='center'
          spacing={2}
          useFlexGap
          flexWrap='wrap'
          sx={{ padding: 3 }}>
          <TextField
            label='Batch Number'
            value={batchNo}
            onChange={e => setBatchNo(e.target.value)}
            slotProps={{ inputLabel: { shrink: true } }}
            sx={{ width: '180px' }}
            variant='outlined'
            size='small'
          />
          <TextField
            label='Tracking Number'
            value={trackingNo}
            onChange={e => setTrackingNo(e.target.value)}
            slotProps={{ inputLabel: { shrink: true } }}
            sx={{ width: '180px' }}
            variant='outlined'
            size='small'
          />
          <TextField
            label='Uploading Time From'
            type='date'
            value={fromDate}
            onChange={e => setFromDate(e.target.value)}
            slotProps={{ inputLabel: { shrink: true } }}
            variant='outlined'
            size='small'
          />
          <TextField
            label='Uploading Time To'
            type='date'
            value={toDate}
            onChange={e => setToDate(e.target.value)}
            slotProps={{ inputLabel: { shrink: true } }}
            variant='outlined'
            size='small'
          />
          <TextField
            label='Parcel Category'
            value={goodsType}
            onChange={e => setGoodsType(e.target.value)}
            slotProps={{ inputLabel: { shrink: true } }}
            sx={{ width: '180px' }}
            variant='outlined'
            size='small'
          />
          <IconButton
            color='secondary'
            onClick={() => handleSearch(rows, page)}>
            <SearchIcon />
          </IconButton>
        </Stack>
      </Card>

      <Dialog
        maxWidth='sm'
        fullWidth={true}
        open={failOpen}
        onClose={() => setFailOpen(false)}>
        <DialogTitle>Error feedback</DialogTitle>
        <DialogContent dividers>
          <Typography ref={componentRef}>
            {Array.isArray(errorMessage) ? (
              errorMessage.map(msg => <p>{msg}</p>)
            ) : (
              <p>{errorMessage}</p>
            )}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <ReactToPrint
            pageStyle='@page { size: 8.3in 11.7in }'
            trigger={() => <Button>Download</Button>}
            content={() => componentRef.current}
          />
          <Button autoFocus onClick={() => setFailOpen(false)}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <LocalDeliveryDisplay
        ordersRes={orders}
        page={page}
        rows={rows}
        setPage={setPage}
        setRows={setRows}
        handleSearch={handleSearch}
      />

      <FullscreenLoading open={loading} />
    </div>
  )
}
