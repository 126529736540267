import {
  shangjiaServerInstanceDev,
  createOrderServerInstanceDev,
  shangjiaServerInstance
} from '../utilities/instances'
import {
  GET_API_DESCRIPTION,
  GET_API_DESCRIPTION_SUCCESS,
  GET_API_DESCRIPTION_FAIL,
  GET_CUSTOMER_TOKEN,
  GET_CUSTOMER_TOKEN_SUCCESS,
  GET_CUSTOMER_TOKEN_FAIL,
  CREATE_BUSINESS_ORDER,
  CREATE_BUSINESS_ORDER_SUCCESS,
  CREATE_BUSINESS_ORDER_FAIL,
  CREATE_PARTNER_ORDER,
  CREATE_PARTNER_ORDER_SUCCESS,
  CREATE_PARTNER_ORDER_FAIL,
  TRACKING_UNIUNI,
  TRACKING_UNIUNI_SUCCESS,
  TRACKING_UNIUNI_FAIL,
  PRINT_LABEL,
  PRINT_LABEL_SUCCESS,
  PRINT_LABEL_FAIL,
  PUSH_TRACKING,
  PUSH_TRACKING_SUCCESS,
  PUSH_TRACKING_FAIL,
  ESTIMATE_SHIPPING,
  ESTIMATE_SHIPPING_SUCCESS,
  ESTIMATE_SHIPPING_FAIL,
  CHECK_SERVICE_AVAILABILITY,
  CHECK_SERVICE_AVAILABILITY_SUCCESS,
  CHECK_SERVICE_AVAILABILITY_FAIL,
  CREATE_BATCH,
  CREATE_BATCH_SUCCESS,
  CREATE_BATCH_FAIL,
  CANCEL_ORDER,
  CANCEL_ORDER_SUCCESS,
  CANCEL_ORDER_FAIL,
  GET_ORDERS,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAIL,
  UPDATE_ORDER_BAG_NO_EXCEL,
  UPDATE_ORDER_BAG_NO_EXCEL_SUCCESS,
  UPDATE_ORDER_BAG_NO_EXCEL_FAIL
} from './types'
import { execute } from '../utilities/executor'

/**
 *
 * @param {file} file - EXCEL file
 * @param {json} - bag no in json
 */
export function updateOrderBagNo(params) {
  return function (dispatch) {
    dispatch({ type: UPDATE_ORDER_BAG_NO_EXCEL })
    return createOrderServerInstanceDev
      .post('/orders/updatebagno', params, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(({ data }) => {
        dispatch({
          type: UPDATE_ORDER_BAG_NO_EXCEL_SUCCESS,
          payload: data.data
        })
        return Promise.resolve(data)
      })
      .catch(error => {
        dispatch({
          type: UPDATE_ORDER_BAG_NO_EXCEL_FAIL,
          payload: error
        })
        return Promise.reject(error)
      })
  }
}

export function getOrders(params) {
  return function (dispatch) {
    dispatch({ type: GET_ORDERS })
    return createOrderServerInstanceDev
      .get(`/orders`, {
        params: params
      })
      .then(({ data }) => {
        dispatch({
          type: GET_ORDERS_SUCCESS,
          payload: data.data
        })
        return Promise.resolve(data)
      })
      .catch(error => {
        dispatch({
          type: GET_ORDERS_FAIL,
          payload: error
        })
        return Promise.reject(error)
      })
  }
}

export function cancelOrder(params) {
  return function (dispatch) {
    dispatch({ type: CANCEL_ORDER })
    return createOrderServerInstanceDev
      .post(`/orders/cancelorder`, { ...params })
      .then(({ data }) => {
        dispatch({
          type: CANCEL_ORDER_SUCCESS,
          payload: data.data
        })
        return Promise.resolve(data)
      })
      .catch(error => {
        dispatch({
          type: CANCEL_ORDER_FAIL,
          payload: error
        })
        return Promise.reject(error)
      })
  }
}

export function createBatch(params) {
  return function (dispatch) {
    dispatch({ type: CREATE_BATCH })
    return createOrderServerInstanceDev
      .post(`/orders/createbatch`, { ...params })
      .then(({ data }) => {
        dispatch({
          type: CREATE_BATCH_SUCCESS,
          payload: data.data
        })
        return Promise.resolve(data)
      })
      .catch(error => {
        dispatch({
          type: CREATE_BATCH_FAIL,
          payload: error
        })
        return Promise.reject(error)
      })
  }
}

export function checkServiceAvailability(params) {
  return function (dispatch) {
    dispatch({ type: CHECK_SERVICE_AVAILABILITY })
    return createOrderServerInstanceDev
      .post(`/orders/checkserviceavailability`, { ...params })
      .then(({ data }) => {
        dispatch({
          type: CHECK_SERVICE_AVAILABILITY_SUCCESS,
          payload: data.data
        })
        return Promise.resolve(data)
      })
      .catch(error => {
        dispatch({
          type: CHECK_SERVICE_AVAILABILITY_FAIL,
          payload: error
        })
        return Promise.reject(error)
      })
  }
}

export function estimateShipping(params) {
  return function (dispatch) {
    dispatch({ type: ESTIMATE_SHIPPING })
    return createOrderServerInstanceDev
      .post(`/orders/estimateshipping`, { ...params })
      .then(({ data }) => {
        dispatch({
          type: ESTIMATE_SHIPPING_SUCCESS,
          payload: data.data
        })
        return Promise.resolve(data)
      })
      .catch(error => {
        dispatch({
          type: ESTIMATE_SHIPPING_FAIL,
          payload: error
        })
        return Promise.reject(error)
      })
  }
}

export function pushTracking(params) {
  return function (dispatch) {
    dispatch({ type: PUSH_TRACKING })
    return createOrderServerInstanceDev
      .post(`/orders/pushTracking`, { ...params })
      .then(({ data }) => {
        dispatch({
          type: PUSH_TRACKING_SUCCESS,
          payload: data.data
        })
        return Promise.resolve(data)
      })
      .catch(error => {
        dispatch({
          type: PUSH_TRACKING_FAIL,
          payload: error
        })
        return Promise.reject(error)
      })
  }
}

export function printLabel(params) {
  return function (dispatch) {
    dispatch({ type: PRINT_LABEL })
    return createOrderServerInstanceDev
      .post(`/orders/printlabel`, { ...params })
      .then(({ data }) => {
        dispatch({
          type: PRINT_LABEL_SUCCESS,
          payload: data.data
        })
        return Promise.resolve(data)
      })
      .catch(error => {
        dispatch({
          type: PRINT_LABEL_FAIL,
          payload: error
        })
        return Promise.reject(error)
      })
  }
}

export function getApiDescription() {
  return function (dispatch) {
    dispatch({ type: GET_API_DESCRIPTION })
    return shangjiaServerInstanceDev
      .get(`/api_desc`)
      .then(({ data }) => {
        dispatch({
          type: GET_API_DESCRIPTION_SUCCESS,
          payload: data.data
        })
        return Promise.resolve(data.data)
      })
      .catch(error => {
        dispatch({
          type: GET_API_DESCRIPTION_FAIL,
          payload: error
        })
        return Promise.reject(error)
      })
  }
}

export function getCustomerToken(params) {
  return function (dispatch) {
    dispatch({ type: GET_CUSTOMER_TOKEN })
    return shangjiaServerInstanceDev
      .post(`/storeauth/customertoken`, {
        grant_type: params[0],
        client_id: params[1],
        client_secret: params[2]
      })
      .then(({ data }) => {
        dispatch({
          type: GET_CUSTOMER_TOKEN_SUCCESS,
          payload: data.data
        })
        return Promise.resolve(data)
      })
      .catch(error => {
        dispatch({
          type: GET_CUSTOMER_TOKEN_FAIL,
          payload: error
        })
        return Promise.reject(error)
      })
  }
}

export function createBusinessOrder(params) {
  return function (dispatch) {
    dispatch({ type: CREATE_BUSINESS_ORDER })
    return createOrderServerInstanceDev
      .post(`/orders/createbusinessorder`, { ...params })
      .then(({ data }) => {
        dispatch({
          type: CREATE_BUSINESS_ORDER_SUCCESS,
          payload: data.data
        })
        return Promise.resolve(data)
      })
      .catch(error => {
        dispatch({
          type: CREATE_BUSINESS_ORDER_FAIL,
          payload: error
        })
        return Promise.reject(error)
      })
  }
}

export const createPartnerOrder = params => d =>
  execute(d)(
    shangjiaServerInstance.post(`/orders/createpartnerorder`, { ...params }),
    {
      type: CREATE_PARTNER_ORDER,
      success: CREATE_PARTNER_ORDER_SUCCESS,
      fail: CREATE_PARTNER_ORDER_FAIL
    }
  )

export function trackingUniuni(key, trackingNumber) {
  return function (dispatch) {
    dispatch({ type: TRACKING_UNIUNI })
    return shangjiaServerInstanceDev
      .get(`/orders/trackinguniuni?key=${key}&id=${trackingNumber}`)
      .then(({ data }) => {
        dispatch({
          type: TRACKING_UNIUNI_SUCCESS,
          payload: data.data
        })
        return Promise.resolve(data)
      })
      .catch(error => {
        dispatch({
          type: TRACKING_UNIUNI_FAIL,
          payload: error
        })
        return Promise.reject(error)
      })
  }
}
