import { useCallback, useState } from 'react'
import { Box, Paper } from '@mui/material'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import DocumentDetailDisplay from './DocumentDetailDisplay'

function DragDropFileUpload({ fileType, onFileUpload, file }) {
  const [dragOver, setDragOver] = useState(false)

  const handleDragOver = useCallback(event => {
    event.preventDefault()
    setDragOver(true)
  }, [])

  const handleDragLeave = useCallback(event => {
    event.preventDefault()
    setDragOver(false)
  }, [])

  const handleDrop = useCallback(
    event => {
      event.preventDefault()
      setDragOver(false)
      if (event.dataTransfer.files && event.dataTransfer.files[0]) {
        onFileUpload(event.dataTransfer.files[0])
      }
    },
    [onFileUpload]
  )

  const handleChange = useCallback(
    event => {
      if (event.target.files && event.target.files[0]) {
        onFileUpload(event.target.files[0])
        event.target.value = null
      }
    },
    [onFileUpload]
  )
  return (
    <div>
      <input
        accept={fileType}
        style={{ display: 'none' }}
        id='upload_file'
        multiple
        type='file'
        onChange={handleChange}
      />

      {file ? (
        <label htmlFor='upload_file'>
          <DocumentDetailDisplay file={file} state={'Ready'} />
        </label>
      ) : (
        <Paper
          variant='outlined'
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          style={{
            border: dragOver ? '2px dashed #000' : '2px dashed #aaa',
            padding: 20,
            textAlign: 'center',
            cursor: 'pointer',
            background: dragOver ? '#eee' : '#fafafa'
          }}>
          <div>
            <Box display='flex' flexDirection='column' alignItems='center'>
              <UploadFileIcon color='secondary' style={{ fontSize: 30 }} />
            </Box>
            <div style={{ paddingTop: 20 }}>
              <label
                style={{ color: '#2196F3', textDecoration: 'underline' }}
                htmlFor='upload_file'>
                {' '}
                <span>Click to upload</span>
              </label>
              <span> or drag or drop</span>
            </div>

            <div style={{ paddingTop: 10, color: 'rgba(0, 0, 0, 0.6)' }}>
              {fileType}
            </div>
          </div>
        </Paper>
      )}
    </div>
  )
}

export default DragDropFileUpload
