import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
  Card,
  Typography,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { showAlertSnackbar } from '../../reducers/sliceReducer'
import {
  getInvoicePreview,
  getInvoice
} from '../../actions/billingManangementActions'
import BillingManagementSettlementDetail from './BillingManagementSettlementDetail'
import FullscreenLoading from '../../components/FullscreenLoading'
import DataGridContainer from '../../components/DataGridContainer'
import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'

export default function BillingManagementSettlementDisplay(props) {
  const { status, operators, settlementList, search } = props
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [dialog, setDialogOpen] = useState(false)
  const [rowData, setRowData] = useState({})
  const [selected, setSelected] = useState([])
  const [previewFailed, setPreviewFailed] = useState(null)
  const [invoicePreview, setInvoicePreview] = useState(null)
  const [invoice, setInvoice] = useState(null)
  const [invoiceSettlemements, setInvoiceSettlements] = useState([])

  const settlementColumns = [
    {
      field: 'billing_num',
      headerName: 'Billing ID',
      width: 200
    },
    {
      field: 'settlement_status_code',
      headerName: 'Billing Status',
      width: 230,
      valueGetter: value => {
        var temp = status.find(obj => {
          return obj.code === value
        })
        return temp.name
      }
    },
    {
      field: 'billing_start',
      headerName: 'Billing Periods',
      width: 260,
      valueGetter: (value, row) => {
        // formated billing start
        dayjs.extend(weekOfYear)
        var start_date = dayjs(value).format('YYYY/MM/DD')
        // formated billing end
        var end_date = dayjs(row.billing_end).format('YYYY/MM/DD')
        // week
        var week = dayjs(value).week()

        return start_date + ' - ' + end_date + ' Wk' + week
      }
    },
    {
      field: 'invoice_ts',
      headerName: 'Invoice Date',
      width: 290,
      valueGetter: value => {
        if (!value) {
          return ''
        } else {
          return dayjs(value).format('YYYY/MM/DD')
        }
      }
    },
    {
      field: 'invoice_number',
      headerName: 'Invoice Number',
      width: 220
    },
    {
      field: 'operator',
      headerName: 'Operator',
      width: 192,
      valueGetter: value => {
        if (value === null) return ''
        const temp = operators?.find(obj => {
          return obj.id === value
        })
        return temp?.username
      }
    }
  ]

  function previewInvoice() {
    var billing_num = []
    settlementList.forEach(row => {
      if (selected.includes(row.id)) {
        billing_num.push(row.billing_num)
      }
    })
    setInvoiceSettlements(billing_num)
    if (!billing_num.length) {
      showAlertSnackbar({
        message: 'No billing statement Selected',
        type: 'error'
      })
    }
    dispatch(getInvoicePreview({ billing_num: billing_num.toString() }))
      .then(res => {
        setInvoicePreview(res)
      })
      .catch(err => {
        setPreviewFailed(err.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  function generateInvoice() {
    setLoading(true)

    dispatch(
      getInvoice({
        file_name: 'Invoice.pdf',
        billing_num: invoiceSettlemements.toString()
      })
    )
      .then(res => {
        setInvoice(res)
      })
      .catch(err => {
        showAlertSnackbar({ message: err.message, type: 'error' })
      })
      .finally(() => {
        search()
        setLoading(false)
      })
  }

  function handleDownloadInvoice() {
    const link = document.createElement('a')
    link.href = invoice
    link.setAttribute(
      'download',
      'Invoice_' + invoiceSettlemements.toString() + '.pdf'
    )
    document.body.appendChild(link)
    link.click()

    window.URL.revokeObjectURL(invoice)
    document.body.removeChild(link)

    // open the dialog of the first billing_num
    var rowData = settlementList.find(
      row => row.billing_num === invoiceSettlemements[0]
    )
    setRowData(rowData)
    setDialogOpen(true)

    // reset data & refresh
    setInvoice(null)
    setInvoicePreview(null)
    setInvoiceSettlements([])
  }

  const reformatDate = dateString => {
    // split the date string into month, day, and year
    const parts = dateString.split('/')
    // rearrange the parts to yyyy-mm-dd format
    const formattedDate = `${parts[2]}-${parts[0].padStart(2, '0')}-${parts[1].padStart(2, '0')}`
    return formattedDate
  }

  return (
    <Card variant='outlined'>
      <Box display='flex' alignItems='center' padding={2}>
        <Typography variant='subtitle2' flex={1}>
          Billing Statements
        </Typography>
        <Button
          onClick={previewInvoice}
          disabled={!selected.length || !settlementList.length}
          variant='contained'
          color='secondary'>
          Generate Invoice
        </Button>
      </Box>

      <DataGridContainer>
        <DataGrid
          loading={loading}
          rows={loading ? [] : settlementList}
          columns={settlementColumns}
          onRowClick={({ row }) => {
            const rowData = {}
            settlementColumns.forEach(column => {
              rowData[column.field] = row[column.field]
            })
            setDialogOpen(true)
            setRowData(rowData)
          }}
          onRowSelectionModelChange={selection => {
            setSelected(selection)
          }}
          selectionModel={selected}
          checkboxSelection
          getCellClassName={params => {
            if (
              params.field === 'settlement_status_code' &&
              params.value === 'Pending for Reconciliation'
            ) {
              return 'red_text_cell'
            }
          }}
          sx={{
            '& .red_text_cell': {
              color: '#D32F2FDE'
            }
          }}
        />
      </DataGridContainer>

      <BillingManagementSettlementDetail
        rowData={rowData}
        setDialogOpen={setDialogOpen}
        dialog={dialog}
        status={status}
        search={search}
      />

      <Dialog maxWidth='sm' fullWidth open={previewFailed !== null}>
        <DialogTitle>Failed to Generate Invoice</DialogTitle>
        <DialogContent dividers>
          <Typography>
            Unable to generate an invoice for the selected bill due to the
            following reasons:
          </Typography>
          <Typography component='ul' color='error' marginY={1}>
            {previewFailed &&
              Array.isArray(previewFailed) &&
              previewFailed.map(err => {
                return <li>{err}</li>
              })}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button
            autoFocus
            onClick={() => {
              setPreviewFailed(null)
            }}>
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog maxWidth='lg' fullWidth open={invoicePreview !== null}>
        <DialogTitle>Generate Invoice</DialogTitle>
        <DialogContent dividers>
          <Typography marginBottom={2}>
            Review the billing information then generate the invoice.
          </Typography>
          <Table>
            <TableHead
              sx={{ '& th': { fontWeight: 600, color: 'text.secondary' } }}>
              <TableRow>
                <TableCell>Selected Billing ID</TableCell>
                <TableCell>Partner Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{invoicePreview?.billing_id}</TableCell>
                <TableCell>{invoicePreview?.partner}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table>
            <TableHead sx={{ '& th': { fontWeight: 600 } }}>
              <TableRow>
                <TableCell>Cost Category</TableCell>
                <TableCell>Airport Code</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Net Price</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Currency</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoicePreview?.fees &&
                invoicePreview.fees.map(fee => (
                  <TableRow>
                    <TableCell>{fee.description}</TableCell>
                    <TableCell>{fee.airport_code}</TableCell>
                    <TableCell>
                      {reformatDate(fee.service_period.split('-')[0])}
                    </TableCell>
                    <TableCell>
                      {reformatDate(fee.service_period.split('-')[1])}
                    </TableCell>
                    <TableCell>{fee.quantity}</TableCell>
                    <TableCell>{fee.net_price}</TableCell>
                    <TableCell>{fee.total}</TableCell>
                    <TableCell>{invoicePreview?.currency}</TableCell>
                  </TableRow>
                ))}
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell sx={{ backgroundColor: 'rgba(25, 118, 210, 0.10)' }}>
                  Total Amount:
                </TableCell>
                <TableCell sx={{ backgroundColor: 'rgba(25, 118, 210, 0.10)' }}>
                  {invoicePreview?.total.toFixed(2)}
                </TableCell>
                <TableCell sx={{ backgroundColor: 'rgba(25, 118, 210, 0.10)' }}>
                  {invoicePreview?.currency}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button
            autoFocus
            onClick={() => {
              setInvoicePreview(null)
            }}>
            Cancel
          </Button>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => {
              setInvoicePreview(null)
              generateInvoice()
            }}>
            Generate Invoice
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog maxWidth='md' fullWidth open={invoice !== null}>
        <DialogTitle>Invoice</DialogTitle>
        <DialogContent dividers sx={{ padding: 0 }}>
          <object
            width='100%'
            height='500'
            type='application/pdf'
            data={invoice}>
            <p>Cannot Render Invoice, Please click print to view Invoice</p>
          </object>
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button
            autoFocus
            onClick={() => {
              setInvoice(null)
            }}>
            CLOSE
          </Button>
          <Button
            variant='contained'
            color='secondary'
            onClick={e => {
              handleDownloadInvoice()
            }}>
            DOWNLOAD INVOICE
          </Button>
        </DialogActions>
      </Dialog>

      <FullscreenLoading open={loading} />
    </Card>
  )
}
