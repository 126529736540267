import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Typography } from '@mui/material'
import {
  searchSettlementProcedures,
  getSettlementCustomers,
  getSettlementStatus
} from '../../actions/billingManangementActions'
import { getUserList } from '../../actions/userManagementActions'
import BillingManagementSettlementSearch from './BillingManagementSettlementSearch'
import BillingManagementSettlementDisplay from './BillingManagementSettlementDisplay'
import { showAlertSnackbar } from '../../reducers/sliceReducer'
import FullscreenLoading from '../../components/FullscreenLoading'
import dayjs from 'dayjs'

function BillingManagement() {
  const dispatch = useDispatch()
  const [settlementList, setSettlementList] = useState([])
  const [customers, setCustomersList] = useState([])
  const [status, setSettlementStatus] = useState([])
  const [loading, setLoading] = useState(false)
  const [operators, setOperatorList] = useState([])
  const [setttlmentSearchParams, setSettlementSearchParams] = useState({
    partner: '',
    customer: '',
    billingStatus: '',
    billingPeriodFrom: dayjs().day(-7).format('YYYY-MM-DD'),
    billingPeriodTo: dayjs().day(0).format('YYYY-MM-DD'),
    invoiceConfFrom: '',
    invoiceConfTo: '',
    // invoiceConfFrom: dayjs().day(0).format("YYYY-MM-DD"),
    // invoiceConfTo: dayjs().day(7).format("YYYY-MM-DD"),
    invoiceNumber: '',
    billingNumber: '',
    operator: ''
  })

  function fetchSettlementProcedures() {
    setLoading(true)
    const params = {
      partner: setttlmentSearchParams.partner,
      customer: setttlmentSearchParams.customer,
      billing_status: setttlmentSearchParams.billingStatus,
      billing_period_from: setttlmentSearchParams.billingPeriodFrom,
      billing_period_to: setttlmentSearchParams.billingPeriodTo,
      invoice_conf_date_from: setttlmentSearchParams.invoiceConfFrom,
      invoice_conf_date_to: setttlmentSearchParams.invoiceConfTo,
      invoice_num: setttlmentSearchParams.invoiceNumber,
      billing_num: setttlmentSearchParams.billingNumber,
      operator: setttlmentSearchParams.operator
    }
    params.partner = params.partner ? params.partner.partner_id : ''
    params.customer = params.customer ? params.customer.customer_id : ''
    params.operator = params.operator ? params.operator.id : ''

    dispatch(searchSettlementProcedures(params))
      .then(res => {
        setSettlementList(res)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        setSettlementList([])
        dispatch(showAlertSnackbar({ message: err.message, type: 'error' }))
      })
  }

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      try {
        const [customerRes, statusRes, userRes] = await Promise.all([
          dispatch(getSettlementCustomers()),
          dispatch(getSettlementStatus()),
          dispatch(getUserList())
        ])
        setOperatorList(
          Object.entries(userRes).map(user => ({
            id: user[1].id,
            username: user[1].username
          }))
        )
        setCustomersList(customerRes)
        setSettlementStatus(statusRes)
      } catch (error) {
        dispatch(showAlertSnackbar({ message: error.message, type: 'error' }))
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [dispatch, setLoading])

  const handleInputChange = (field, value) => {
    if (field === 'partner') {
      setSettlementSearchParams({
        ...setttlmentSearchParams,
        [field]: value,
        customer: ''
      })
    } else {
      setSettlementSearchParams({
        ...setttlmentSearchParams,
        [field]: value
      })
    }
  }

  return (
    <div>
      <Typography variant='subtitle1' marginBottom={2}>
        Billing Management
      </Typography>
      <BillingManagementSettlementSearch
        status={status}
        customers={customers}
        operators={operators}
        search={fetchSettlementProcedures}
        params={setttlmentSearchParams}
        setParams={handleInputChange}
      />
      <BillingManagementSettlementDisplay
        status={status}
        customers={customers}
        operators={operators}
        settlementList={settlementList}
        search={fetchSettlementProcedures}
      />
      <FullscreenLoading open={loading} />
    </div>
  )
}

export default BillingManagement
