import { Box } from '@mui/material'

// https://mui.com/x/react-data-grid/layout/#flex-parent-container

export default function DataGridContainer(props) {
  return (
    <Box
      display='flex'
      flexDirection='column'
      minHeight='50vh'
      maxHeight='calc(100vh - 90px)'>
      {props.children}
    </Box>
  )
}
