import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Box,
  Card,
  Stack,
  MenuItem,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  InputAdornment,
  Typography,
  Grid2 as Grid
} from '@mui/material'
import {
  getInfoByTrackingNumber,
  createNewFPRecord
} from '../../actions/faultyPackageActions'
import { showAlertSnackbar } from '../../reducers/sliceReducer'
import {
  FaultyPackageIssueType,
  FaultyPackageRecordStatus,
  FaultyPackagePackageStatus
} from '../../utilities/constants'
import dayjs from 'dayjs'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import ImageIcon from '@mui/icons-material/Image'
import CancelIcon from '@mui/icons-material/Cancel'
import SearchIcon from '@mui/icons-material/Search'

const RECORD_INFO = {
  record_date: dayjs().format('YYYY-MM-DD'),
  warehouse: '',
  tracking_number: '',
  sender_reference: '',
  item_number: '',
  issue_type: '',
  uni_notes: '',
  package_status: ''
}

export default function FaultyPackageSearch(props) {
  const { refresh, role, warehouseDropDown } = props
  const dispatch = useDispatch()

  const [newRecordDialog, setCreateNewOpen] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState(null)
  const [warehouse, setWarehouse] = useState('')
  const [trackingNo, setTrackingNo] = useState('')
  const [senderRef, setSenderRef] = useState('')
  const [issueType, setIssueType] = useState('')
  const [packageStatus, setPackageStatus] = useState('')
  const [recordStatus, setRecordStatus] = useState('')
  const [fromDate, setFromDate] = useState(
    dayjs().subtract(14, 'days').format('YYYY-MM-DD')
  )
  const [toDate, setToDate] = useState(dayjs().format('YYYY-MM-DD'))
  const [newRecord, setNewRecordInfo] = useState(RECORD_INFO)

  function handleView() {
    let params = {
      startTime: fromDate,
      endTime: toDate,
      warehouse: warehouse,
      tracking_number: trackingNo,
      sender_reference: senderRef,
      issue_type: issueType,
      package_status: packageStatus,
      record_status: recordStatus
    }
    refresh(params)
  }

  async function createNewRecord() {
    try {
      let formData = new FormData()

      Object.keys(newRecord).forEach(key =>
        formData.append(key, newRecord[key])
      )
      selectedFiles?.forEach(img => {
        formData.append('images[]', img)
      })
      await dispatch(createNewFPRecord(formData))
        .then(res => {
          handleView()
          dispatch(
            showAlertSnackbar({ message: 'Record Created', type: 'success' })
          )
        })
        .catch(err => {
          dispatch(showAlertSnackbar({ message: err.message, type: 'error' }))
        })
    } catch (error) {
      dispatch(
        showAlertSnackbar({
          message: 'Failed to create new record: ' + error,
          type: 'error'
        })
      )
    } finally {
      setCreateNewOpen(false)
      resetnewRecord()
    }
  }

  function getPackageInfo() {
    if (newRecord.tracking_number) {
      dispatch(getInfoByTrackingNumber(newRecord.tracking_number))
        .then(res => {
          setNewRecordInfo({
            ...newRecord,
            package_status: res.state,
            warehouse: res.warehouse
          })
        })
        .catch(err => {
          dispatch(showAlertSnackbar({ message: err.message, type: 'error' }))
          setNewRecordInfo({
            ...newRecord,
            tracking_number: ''
          })
        })
    }
  }

  function resetnewRecord() {
    setNewRecordInfo({
      record_date: dayjs().format('YYYY-MM-DD'),
      warehouse: '',
      tracking_number: '',
      sender_reference: '',
      item_number: '',
      issue_type: '',
      uni_notes: '',
      package_status: ''
    })
    setSelectedFiles(null)
  }

  const handleNewRecordChange = e => {
    const { name, value } = e.target
    setNewRecordInfo({
      ...newRecord,
      [name]: value
    })
  }

  const handleUploadImages = e => {
    if (e.target.files.length <= 5) {
      setSelectedFiles([...e.target.files])
    } else {
      dispatch(
        showAlertSnackbar({ type: 'error', message: 'Max 5 images allowed' })
      )
    }
  }

  function removeImage(index) {
    let temp = [...selectedFiles]
    temp.splice(index, 1)
    setSelectedFiles(temp)
  }

  return (
    <div>
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        marginBottom={2}>
        <Typography variant='subtitle1'>Faulty Package</Typography>
        {role === 'uni' && (
          <Button
            startIcon={<AddIcon />}
            onClick={() => setCreateNewOpen(true)}
            variant='contained'>
            New Record
          </Button>
        )}
      </Box>

      <Card variant='outlined' sx={{ marginBottom: 2 }}>
        <Stack
          direction='row'
          alignItems='center'
          spacing={2}
          useFlexGap
          flexWrap='wrap'
          sx={{ padding: 3 }}>
          <TextField
            label='Record Time From'
            type='date'
            value={fromDate}
            onChange={e => setFromDate(e.target.value)}
            slotProps={{ inputLabel: { shrink: true } }}
            variant='outlined'
            size='small'
          />
          <TextField
            label='Record Time To'
            type='date'
            value={toDate}
            onChange={e => setToDate(e.target.value)}
            slotProps={{ inputLabel: { shrink: true } }}
            variant='outlined'
            size='small'
          />
          <TextField
            label='Warehouse'
            slotProps={{ inputLabel: { shrink: true } }}
            value={warehouse ? warehouse : 'Select'}
            onChange={e =>
              e.target.value === 'Select'
                ? setWarehouse('')
                : setWarehouse(e.target.value)
            }
            sx={{ width: '180px' }}
            variant='outlined'
            size='small'
            select>
            <MenuItem key='' value='Select'>
              {'Select'}
            </MenuItem>
            {Object.entries(warehouseDropDown).map(([k, v]) => (
              <MenuItem key={k} value={k}>
                {v}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label='Tracking Number'
            value={trackingNo}
            onChange={e => setTrackingNo(e.target.value)}
            slotProps={{ inputLabel: { shrink: true } }}
            sx={{ width: '180px' }}
            variant='outlined'
            size='small'
          />
          <TextField
            label='Sender Ref.'
            value={senderRef}
            onChange={e => setSenderRef(e.target.value)}
            slotProps={{ inputLabel: { shrink: true } }}
            sx={{ width: '180px' }}
            variant='outlined'
            size='small'
          />
          <TextField
            label='Issue Type'
            value={issueType ? issueType : 'Select'}
            onChange={e =>
              e.target.value === 'Select'
                ? setIssueType('')
                : setIssueType(e.target.value)
            }
            slotProps={{ inputLabel: { shrink: true } }}
            sx={{ width: '180px' }}
            variant='outlined'
            size='small'
            select>
            <MenuItem key='' value='Select'>
              {'Select'}
            </MenuItem>
            {Object.entries(FaultyPackageIssueType).map(([k, v]) => (
              <MenuItem key={k} value={k}>
                {v}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label='Package Status'
            value={packageStatus ? packageStatus : 'Select'}
            onChange={e =>
              e.target.value === 'Select'
                ? setPackageStatus('')
                : setPackageStatus(e.target.value)
            }
            slotProps={{ inputLabel: { shrink: true } }}
            sx={{ width: '180px' }}
            variant='outlined'
            size='small'
            select>
            <MenuItem key='' value='Select'>
              {'Select'}
            </MenuItem>
            {Object.entries(FaultyPackagePackageStatus).map(([k, v]) => (
              <MenuItem key={k} value={k}>
                {v}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label='Record Status'
            value={recordStatus ? recordStatus : 'Select'}
            onChange={e =>
              e.target.value === 'Select'
                ? setRecordStatus('')
                : setRecordStatus(e.target.value)
            }
            slotProps={{ inputLabel: { shrink: true } }}
            sx={{ width: '180px' }}
            variant='outlined'
            size='small'
            select>
            <MenuItem key='' value='Select'>
              {'Select'}
            </MenuItem>
            {Object.entries(FaultyPackageRecordStatus).map(([k, v]) => (
              <MenuItem key={k} value={k}>
                {v}
              </MenuItem>
            ))}
          </TextField>
          <IconButton color={'secondary'} onClick={handleView}>
            <SearchIcon />
          </IconButton>
        </Stack>
      </Card>

      <Dialog
        fullWidth
        maxWidth='sm'
        open={newRecordDialog}
        onClose={() => setCreateNewOpen(false)}>
        <DialogTitle>Faulty Package Record</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid size={6}>
              <TextField
                label='Record Date'
                type='date'
                slotProps={{ inputLabel: { shrink: true } }}
                variant='outlined'
                name='record_date'
                value={newRecord.record_date}
                onChange={handleNewRecordChange}
                fullWidth
                required
              />
            </Grid>
            <Grid size={6}>
              <TextField
                select
                name='warehouse'
                label='Warehouse'
                slotProps={{ inputLabel: { shrink: true } }}
                variant='outlined'
                value={newRecord.warehouse}
                onChange={handleNewRecordChange}
                fullWidth
                required>
                {Object.entries(warehouseDropDown).map(([k, v]) => (
                  <MenuItem key={k} value={k}>
                    {v}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid size={12}>
              <TextField
                label='Tracking No.'
                name='tracking_number'
                variant='outlined'
                value={newRecord.tracking_number}
                onChange={handleNewRecordChange}
                slotProps={{ inputLabel: { shrink: true } }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={getPackageInfo} edge='end'>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                fullWidth
              />
            </Grid>
            <Grid size={12}>
              <TextField
                label='Sender Ref'
                name='sender_reference'
                slotProps={{ inputLabel: { shrink: true } }}
                variant='outlined'
                value={newRecord.sender_reference}
                onChange={handleNewRecordChange}
                fullWidth
              />
            </Grid>
            <Grid size={12}>
              <TextField
                label='Item No.'
                name='item_number'
                slotProps={{ inputLabel: { shrink: true } }}
                variant='outlined'
                value={newRecord.item_number}
                onChange={handleNewRecordChange}
                fullWidth
              />
            </Grid>
            <Grid size={12}>
              <TextField
                select
                label='Issue Type'
                name='issue_type'
                slotProps={{ inputLabel: { shrink: true } }}
                variant='outlined'
                value={newRecord.issue_type}
                onChange={handleNewRecordChange}
                fullWidth
                required>
                {Object.entries(FaultyPackageIssueType).map(([k, v]) => (
                  <MenuItem key={k} value={k}>
                    {v}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid size={12}>
              <Button
                component='label'
                for='uploadImages'
                variant='outlined'
                color='secondary'>
                UPLOAD IMAGE
              </Button>
              <Typography variant='caption' color='secondary' marginLeft={1}>
                *Accept only .png .jpeg. Max 5 images
              </Typography>
              <input
                type='file'
                multiple
                accept='image/*'
                hidden
                onChange={handleUploadImages}
                id='uploadImages'
              />
              {selectedFiles?.map((img, index) => (
                <Button
                  onClick={() => removeImage(index)}
                  startIcon={<ImageIcon />}
                  endIcon={<CancelIcon color='error' />}
                  sx={{ marginTop: 0.5 }}>
                  {img.name}
                </Button>
              ))}
            </Grid>
            <Grid size={12}>
              <TextField
                label='Notes'
                name='uni_notes'
                multiline
                minRows={4}
                slotProps={{ inputLabel: { shrink: true } }}
                variant='outlined'
                value={newRecord.uni_notes}
                onChange={handleNewRecordChange}
                fullWidth
              />
              {FaultyPackagePackageStatus[newRecord.package_status] && (
                <Typography marginTop={2}>
                  {'Current Package Status: ' +
                    FaultyPackagePackageStatus[newRecord.package_status]}
                </Typography>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button
            autoFocus
            onClick={() => {
              resetnewRecord()
              setCreateNewOpen(false)
            }}>
            Cancel
          </Button>
          <Button
            onClick={createNewRecord}
            disabled={
              !newRecord.record_date ||
              !newRecord.warehouse ||
              !newRecord.issue_type
            }
            variant='contained'>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
