import { useEffect, useState, useCallback } from 'react'
import FullscreenLoading from '../../components/FullscreenLoading'
import FaultyPackageSearch from '../FaultyPackage/FaultyPackageSearch'
import FaultyPackageDisplayGV from './GVFaultyPackageDisplay'
import { getBranchList } from '../../actions/commonActions'
import {
  searchFPRecords,
  getRecordStatistics
} from '../../actions/faultyPackageActions'
import { showAlertSnackbar } from '../../reducers/sliceReducer.js'
import { useDispatch } from 'react-redux'

function GVFaultyPackage() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [records, setRecords] = useState([])
  const [warehouseDropDown, setWarehouseDropDown] = useState({})
  const [statistics, setStats] = useState([])
  const role = 'gv'

  const getAllDropDowns = useCallback(async () => {
    try {
      setLoading(true)
      const res = await dispatch(getBranchList())
      const warehouseMap = res.reduce((map, warehouse) => {
        if (
          warehouse.id !== 12 &&
          warehouse.id !== 13 &&
          warehouse.id !== 27 &&
          warehouse.id !== 28
        ) {
          map[warehouse.id] = warehouse.id + '. ' + warehouse.name
        }
        return map
      }, {})
      setWarehouseDropDown(warehouseMap)
    } catch (error) {
      dispatch(
        showAlertSnackbar({
          type: 'error',
          message: 'Error fetching warehouse data'
        })
      )
    } finally {
      setLoading(false)
    }
  }, [dispatch])

  const fetchFaultyPackages = useCallback(
    async params => {
      try {
        setLoading(true)
        const stats = await dispatch(getRecordStatistics())
        const res = await dispatch(searchFPRecords(params))
        setRecords(res)
        setStats(stats)
      } catch (err) {
        dispatch(
          showAlertSnackbar({
            type: 'error',
            message: 'Error fetching package data'
          })
        )
        setRecords([])
        setStats([])
      } finally {
        setLoading(false)
      }
    },
    [dispatch]
  )

  useEffect(() => {
    getAllDropDowns()
    fetchFaultyPackages(null)
  }, [getAllDropDowns, fetchFaultyPackages])

  return (
    <>
      <FaultyPackageSearch
        warehouseDropDown={warehouseDropDown}
        refresh={fetchFaultyPackages}
        role={role}
      />
      <FaultyPackageDisplayGV
        records={records}
        stats={statistics}
        role={role}
        warehouseDropDown={warehouseDropDown}
        refresh={fetchFaultyPackages}
        setloading={setLoading}
      />
      <FullscreenLoading open={loading} />
    </>
  )
}

export default GVFaultyPackage
