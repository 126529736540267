import { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { addServicePoint } from '../../actions/servicePointActions'
import { showAlertSnackbar } from '../../reducers/sliceReducer'
import ServicePointWarehouse from './ServicePointWarehouse'
import ServicePointForm from './ServicePointForm'

export default function ServicePointAdd() {
  const [warehouse, setWarehouse] = useState(
    window.localStorage.getItem('warehouse')
  )
  const dispatch = useDispatch

  function add(serialNo, params) {
    dispatch(addServicePoint(serialNo, params))
      .then(() => {
        dispatch(showAlertSnackbar({ message: 'ADDED', type: 'success' }))
      })
      .catch(() => {
        dispatch(showAlertSnackbar({ message: 'ADD FAILED', type: 'error' }))
      })
  }

  return (
    <>
      <Box display='flex' justifyContent='space-between' marginBottom={2}>
        <Typography variant='subtitle1'>Add Service Point</Typography>
        <ServicePointWarehouse onChangeWarehouse={setWarehouse} />
      </Box>
      <ServicePointForm warehouse={warehouse} onSubmit={add} />
    </>
  )
}
